import AddEmployerScreen from "screen/SlipPage/AddEmployerScreen";
import EditEmployerScreen from "screen/SlipPage/EditEmployerScreen";
import EmployerListScreen from "screen/SlipPage/EmployerList";
import SlipListScreen from "screen/SlipPage/SlipList";
import SlipListByDate from "screen/SlipPage/SlipListByDate";
import CreateSlipsScreen from "screen/SlipPage/CreateSlip";
import StockScreen from "screen/SlipPage/StockScreen";

const defaultAdminRole = ["admin"];

export const routes = [
  {
    path: "employer",
    title: "Employer",
    menu: "Employer",
    icon: "dashboard",
    role: defaultAdminRole,
    children: [
      {
        path: "",
        menu: "พนักงานทั้งหมด",
        icon: "bars",
        component: EmployerListScreen,
        role: ["admin"],
      },
      {
        path: "create",
        menu: "เพิ่มพนักงานใหม่",
        title: "เพิ่มพนักงานใหม่",
        icon: "plus",
        component: AddEmployerScreen,
        role: ["admin"],
      },
      {
        path: ":id",
        title: "ข้อมูลพนักงาน",
        component: EditEmployerScreen,
        role: ["admin"],
      },
    ],
  },
  {
    path: "payment",
    title: "Slip",
    menu: "Slip",
    icon: "dashboard",
    role: defaultAdminRole,
    children: [
      {
        path: "",
        menu: "Slip ทั้งหมด",
        icon: "bars",
        component: SlipListScreen,
        role: ["admin"],
      },
      {
        path: "create",
        menu: "สร้าง slip ใหม่",
        title: "ลูกค้าที่รอการตรวจสอบ",
        icon: "plus",
        component: CreateSlipsScreen,
        role: ["admin"],
      },
      {
        path: ":date",
        title: "สลิปรายเดือน",
        component: SlipListByDate,
        role: ["admin"],
      },
    ],
  },
  {
    path: "stock",
    title: "Stock",
    menu: "Stock",
    icon: "dashboard",
    role: defaultAdminRole,
    component: StockScreen
  }
];
