/**
*
* AdminProductListScreen
*
*/

import React, { Component } from "react";
import Barcode from "react-barcode";
import styled from "styled-components";
// import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Table, Tag, Pagination, message, Button } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import qs from "query-string";
import noImage from "image/noimage.png";
import dataProvider from "utils/dataProvider";
import NavSearch from "../../../components/NavSearch";
import withSearch from "../../../hoc/withSearch";

const Container = styled.div`

`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 2px;
`;


class AdminProductListScreen extends Component { // eslint-disable-line
  provider = dataProvider()

  componentDidMount () {
    const { name, categories, factory, limit = 30, skip = 0, status = "all" } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    this.props.setValue(name, "name");
    this.props.setValue(categories, "categories");
    this.props.setValue(factory, "factory");
    this.props.setValue(status, "status");
    this.props.onSearch({ name, categories, factory, limit, skip, status, isDeleted: true });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const { name, categories, factory, limit = 30, skip = 0, status = "all" } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      this.props.onSearch({ name, categories, factory, limit, skip, status, isDeleted: true });
    }
  }

  unDeleteProduct = async (id) => {
    try {
      await this.provider.fetch.post("/products/undeleted", { id }, { noCache: true });
      window.location.reload();
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  render () {
    const { limit = 30, skip = 0 } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const columns = [
      {
        title: "รหัส",
        dataIndex: "promotions",
        key: "code",
        align: "center",
        width: 100,
        render: (data, record) => {
          if (_isEmpty(data)) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Dot style={{ background: _get(record, "status") ? "green" : "red" }} />
                <div style={{ fontSize: 14 }}>{_get(record, "code")}</div>
              </div>
            );
          }
          return (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Dot style={{ background: _get(record, "status") ? "green" : "red" }} />
                <div style={{ fontSize: 14 }}>{_get(record, "code")}</div>
              </div>
              <Tag color="red">Promotion</Tag>
            </div>
          );
        }
      },
      {
        title: "barcode",
        dataIndex: "barcode",
        key: "barcode",
        align: "center",
        width: 160,
        render: (data, record) => {
          const barcode = _get(record, "barcode");
          if (barcode) {
            return (

              <div>
                <Barcode value={barcode} height={50} fontSize={12} margin={0} width={1.5} />
              </div>

            );
          }
          return null;
        }
      },
      {
        title: "รูปภาพ",
        dataIndex: "promotions",
        key: "product_image",
        render: (data, record) => {
          if (_isEmpty(data)) {
            return (
              <div style={{ width: "60px", height: "60px" }}>
                <img
                  style={{ objectFit: "cover" }}
                  width="100%"
                  height="100%"
                  src={_get(record, "product_image") || noImage}
                  alt="product"
                  onError={(evt) => {
                    evt.target.src = noImage;
                  }}
                />
              </div>
            );
          }
          return (
            <div style={{ width: "60px", height: "60px" }}>
              <img
                style={{ objectFit: "cover" }}
                width={60}
                height={60}
                src={_get(data, "[0].product_image") || _get(record, "product_image") || noImage}
                alt="product"
                onError={(evt) => {
                  evt.target.src = noImage;
                }}
              />
            </div>
          );
        }
      },
      {
        title: "ชื่อสินค้า",
        dataIndex: "promotions",
        key: "name",
        render: (data, record) => {
          if (_isEmpty(data)) {
            return _get(record, "name");
          }
          return _get(data, "[0].name");
        }
      },
      {
        title: "บริษัท",
        dataIndex: "factory",
        key: "factory"
      },
      {
        title: "หมวดหมู่",
        dataIndex: "categories",
        key: "categories",
        render: (data) => data.map(item => <Tag>{item.name}</Tag>)
      },
      {
        title: "ราคา",
        dataIndex: "promotions",
        key: "price",
        align: "center",
        width: 200,
        render: (data, record) => {
          if (_isEmpty(data)) {
            return (
              <>
                {
                  !_isEmpty(record.productDetail) && record.productDetail.map((item) => (
                    <div>
                      {_get(item, "unit_price")}
                      {": "}
                      {_get(item, "price")}
                    </div>
                  ))
                }
              </>
            );
          }
          return (
            <>
              {
                data[0].productDetail.map((item) => (
                  <div>
                    {_get(item, "unit_price")}
                    {": "}
                    {_get(item, "price")}
                  </div>
                ))
              }
            </>
          );
        }
      },
      {
        title: "สถานะการซ่อน",
        dataIndex: "isDeleted",
        key: "isDeleted",
        render: (data) => data ? <div style={{ color: "red" }}>ซ่อน</div> : <div style={{ color: "green" }}>ปกติ</div>
      },
      {
        title: "ดูรายละเอียด",
        key: "action",
        render: (text, record) => {
          if (record.isDeleted) {
            return (
              <span>
                <Button onClick={() => this.unDeleteProduct(record.id)}>
                  ยกเลิกการซ่อน
                </Button>
              </span>
            );
          }
          return (
            <span>
              <Link to={`/admin/products/${record.id}`}>
                ดูข้อมูล
              </Link>
            </span>
          );
        }
      }

    ];
    return (
      <Container>
        <NavSearch
          name={this.props.name}
          factory={this.props.factory}
          category={this.props.categories}
          search={this.props.search}
          setValue={this.props.setValue}
          status={this.props.status}
          isShowStatusFilter
        />
        <Table
          pagination={false}
          columns={columns}
          dataSource={this.props.productList}
          rowKey={record => record.id}
        />
        <div>
          <Pagination
            total={this.props.countProduct}
            defaultCurrent={(Number(skip) + Number(limit)) / Number(limit)}
            defaultPageSize={30}
            onChange={(page) => {
              this.props.search((page - 1) * limit, "skip");
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }}
          />
        </div>
      </Container>
    );
  }
}

AdminProductListScreen.propTypes = {

};

AdminProductListScreen.defaultProps = {

};
export default withSearch(AdminProductListScreen);
