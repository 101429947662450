/* eslint-disable max-len */
/**
 *
 * PromoteScreen
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import bg from "image/bg1.jpeg";
import styled from "styled-components";

import { Layout, Row, Col } from "antd";
import { withRouter, Link } from "react-router-dom";
import logo from "image/logo.png";
import dataProvider from "utils/dataProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SlickBanner from "components/SlickBanner";
import PromoteList from "components/PromoteList";

const { Header } = Layout;
const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 16px 0;
  @media (min-width: 768px) {
    padding: 16px;
  }
`;
const Logo = styled("div")`
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (min-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const Title = styled.div`
  font-size: 32px;
  color: #666666;
  margin: 0;
  margin-top: 30px;
  line-height: 40px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 60px;
  }
`;

// const { Text } = Typography;
const { Content, Footer } = Layout;
// const SubMenu = Menu.SubMenu;

const CustomFooter = styled(Footer)`
  @media (max-width: 767px) {
    padding: 8px !important;
  }
`;

const CustomHeader = styled(Header)`
  height: auto !important;
  padding: 8px !important;
  @media (min-width: 767px) {
    padding: 0 50px !important;
  }
`;

export class PromoteScreen extends React.Component {
  state = {
    productList: [],
    // countProduct: 0,
    limit: 30,
    bannersTop: [],
    bannerBottom: [],
    isLoaded: false,
  };

  provider = dataProvider();

  componentDidMount() {
    this.onFetchPromotion({});
    this.onFetchBanner();
  }

  onFetchBanner = async () => {
    const res = await this.provider.fetch.get(
      "/banners",
      { filter: { where: { page: "promotion" }, limit: 100 } },
      { noCache: true }
    );
    if (res && res.length > 0) {
      const top = res.filter((r) => r.position === "top");
      const bottom = res.filter((r) => r.position === "bottom");
      this.setState({ bannersTop: top, bannerBottom: bottom });
    }
  };

  onFetchPromotion = async ({ skip = 0 }) => {
    const res = await this.provider.fetch.get(
      "/promotions",
      {
        filter: {
          include: [
            "productDetail",
            { products: ["categories", "productDetail"] },
          ],
          where: { end_date: { gt: new Date() }, promote_image: { neq: null } },
          limit: this.state.limit,
          skip,
        },
      },
      { noCache: true }
    );
    // const resCount = await this.provider.fetch.get(
    //   "/promotions/count",
    //   {
    //     where: { end_date: { gt: new Date() }, promote_image: { neq: null } },
    //   },
    //   {
    //     noCache: true,
    //   }
    // );

    return this.setState({
      productList: res,
      isLoaded: true,
    });
  };

  render() {
    // const currentPath = this.props.location.pathname;
    return (
      <Layout
        style={{
          height: "100vh",
          overflowY: "auto",
          backgroundImage: `url("${bg}")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <CustomHeader>
          <Row type="flex" justify="space-between" align="center">
            <Col
              style={{ display: "flex", alignItems: "center" }}
              xs={{
                span: 12,
                order: 1,
              }}
              sm={{
                span: 12,
                order: 1,
              }}
              md={{
                span: 8,
                order: 1,
              }}
              lg={{
                span: 3,
                order: 1,
              }}
            >
              <Link to="/">
                <Logo>
                  <img src={logo} alt="logo" width={140} />
                </Logo>
              </Link>
            </Col>
          </Row>
        </CustomHeader>
        <Layout style={{ background: "transparent" }}>
          <Layout style={{ background: "transparent" }} id="userLayoutContent">
            <Content style={{ padding: "0 16px 24px 16px", overflow: "auto" }}>
              <Container>
                <SlickBanner items={this.state.bannersTop} />
                <Title>โปรโมชั่น</Title>
                <PromoteList
                  promotions={this.state.productList}
                  isLoaded={this.state.isLoaded}
                />
                <SlickBanner items={this.state.bannerBottom} />
              </Container>
            </Content>
            <CustomFooter style={{ textAlign: "center" }}>
              the1drugstore.com ©2019
            </CustomFooter>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

PromoteScreen.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default compose(withRouter)(PromoteScreen);
