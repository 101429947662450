/**
*
* RegisterScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Form, Input, Button, Card, Typography, Modal } from "antd";
import dataProvider from "utils/dataProvider";
import Swal from "sweetalert2";
import RegisterLayout from "components/RegisterLayout";
import Upload from "components/Upload";
import bg from "image/register-bg.svg";
import bg1 from "image/register01.png";
import imageBtn from "image/register02.png";
import lineLogo from "image/line-logo.svg";
// import PropTypes from 'prop-types'

const { Text, Title } = Typography;
const Container = styled.div`

`;
const CardContainer = styled.div`
  display: flex;
  justify-content: center;
`;


class RegisterScreen extends Component { // eslint-disable-line
  state = {
    isCorrectRefCode: true,
    refCode: "",
    refCodeError: "",
    visible: false,
  }

  provider = dataProvider()

  confirmPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("กรุณากรอกพาสเวิสให้ตรงกัน!");
    } else {
      callback();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const response = await this.provider.fetch.post("/myusers", values, { noCache: true });
        const { statusCode } = response;
        if (!statusCode) {
          Swal.fire({
            title: "Success!",
            text: "สร้างไอดีเรียบร้อย รอการยืนยันจากทางระบบ",
            type: "success",
            confirmButtonText: "OK",
            onAfterClose: () => this.props.history.push("/login"),
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: response.message.toString(),
            type: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  }

  onUploadImage = (url, type) => {
    if (type === "proLicense") {
      this.props.form.setFieldsValue({
        professional_license_image: url
      });
    } else if (type === "license") {
      this.props.form.setFieldsValue({
        license_image: url
      });
    }
  }

  checkRefCode = () => {
    if (this.state.refCode === "Baanya1") {
      this.setState({ isCorrectRefCode: true });
    }
    this.setState({ refCodeError: "รหัสในการสมัครไม่ถูกต้อง" });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.props.isAuth === null) {
      return null;
    }
    if (this.props.isAuth) {
      return <Redirect to="/" />;
    }

    return (
      <RegisterLayout>
        <Container>
          {
          this.state.isCorrectRefCode ? (
            <CardContainer>
              <Card style={{ width: "100%", maxWidth: 800 }}>
                <Text>ลงทะเบียนผู้ใช้ใหม่</Text>

                <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onSubmit={this.handleSubmit}>
                  <Form.Item label="Email" wrapperCol={{ span: 18 }}>
                    {getFieldDecorator("email", {
                      rules: [
                        { required: true, message: "กรุณากรอกอีเมล" },
                        { type: "email", message: "กรุณากรอกอีเมลที่ถูกต้อง" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Password" wrapperCol={{ span: 18 }}>
                    {getFieldDecorator("password", {
                      rules: [{ required: true, message: "กรุณากรอกพาสเวิส!" }],
                    })(<Input type="password" />)}
                  </Form.Item>
                  <Form.Item label="Confirm password" wrapperCol={{ span: 18 }}>
                    {getFieldDecorator("confirm_password", {
                      rules: [
                        { validator: this.confirmPassword },
                      ],
                    })(<Input type="password" />)}
                  </Form.Item>

                  <Form.Item label="ใบประกอบวิชาชีพ" labelCol={{ span: 24 }} wrapperCol={{ span: 10 }}>
                    {getFieldDecorator("professional_license_image", {
                      rules: [
                        {/* { required: true, message: 'กรุณาอัพโหลดรูปใบประกอบวิชาชีพ!' }, */},
                      ],
                    })(<Input style={{ display: "none" }} />)}
                    <Upload onUpload={(url) => this.onUploadImage(url, "proLicense")} />
                  </Form.Item>
                  <Form.Item label="ใบอนุญาตขายยา/ใบอนุญาตสถานพยาบาล" labelCol={{ span: 24 }} wrapperCol={{ span: 10 }}>
                    {getFieldDecorator("license_image", {
                      rules: [
                        {/* { required: true, message: 'กรุณาอัพโหลดรูปใบอนุญาตขายยา' }, */},
                      ],
                    })(<Input style={{ display: "none" }} />)}
                    <Upload onUpload={(url) => this.onUploadImage(url, "license")} />
                  </Form.Item>
                  <Form.Item label="ชื่อร้านค้า" wrapperCol={{ span: 18 }}>
                    {getFieldDecorator("shop_name", {
                      rules: [{ required: true, message: "กรุณากรอกชื่อร้านค้าของท่าน!" }],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="เลขใบอนุญาตร้านค้า" wrapperCol={{ span: 12 }}>
                    {getFieldDecorator("license_number", {
                      rules: [{ required: true, message: "กรุณากรอกเลขใบอนุญาตร้านค้าของท่าน!" }],
                    })(<Input />)}
                  </Form.Item>

                  <Form.Item label="ชื่อ นามกุล" wrapperCol={{ span: 18 }}>
                    {getFieldDecorator("full_name", {
                      rules: [{ required: true, message: "กรุณากรอกชื่อ - นามสกุล!" }],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="ที่อยู่จัดส่ง">
                    {getFieldDecorator("address", {
                      rules: [{ required: true, message: "กรุณากรอกที่อยู่เพื่อใช้ในการจัดส่งสินค้า!" }],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item
                    label="เบอร์มือถือ"
                    wrapperCol={{ span: 12 }}
                  >
                    {getFieldDecorator("mobile", {
                      rules: [{ required: true, message: "กรอกเบอร์โทรศัพท์มือถือของท่าน!" }],
                    })(<Input />)}

                  </Form.Item>
                  <span style={{ display: "inline-block", width: "24px", textAlign: "center" }} />
                  <Form.Item
                    label="เบอร์ร้าน"
                    wrapperCol={{ span: 12 }}
                  >
                    {getFieldDecorator("phone", {
                    })(<Input />)}

                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: 140, height: 48, fontSize: 24, borderRadius: 20 }}
                    >
                      ยืนยัน
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </CardContainer>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "50vh" }}>
              <div style={{ maxWidth: 680 }}>
                <Title level={5}>
                  กรุณากรอก Register code
                </Title>
                <Form.Item
                  label="register code"
                  validateStatus={this.state.refCodeError ? "error" : "validating"}
                  help={this.state.refCodeError}
                >
                  <Input onChange={(e) => this.setState({ refCode: e.target.value })} />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <Button
                      htmlType="button"
                      onClick={() => this.setState({ visible: true })}
                      style={{ height: 48, fontSize: 24, borderRadius: 20 }}
                    >
                      วิธีการขอรหัส
                    </Button>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={this.checkRefCode}
                      style={{ width: 140, height: 48, fontSize: 24, borderRadius: 20 }}
                    >
                      ตรวจสอบ
                    </Button>
                  </div>
                </Form.Item>

              </div>
            </div>
          )
        }
          <Modal
            visible={this.state.visible}
            footer={null}
            destroyOnClose
            width="90%"
            style={{ maxWidth: "1200px" }}
            centered
            onCancel={() => this.setState({ visible: false })}
          >
            <div style={{
              backgroundImage: `url(${bg})`,
              position: "relative",
              backgroundSize: "cover",
              width: "100%",
              height: "80vh",
              maxWidth: 1200,
              display: "flex",
            }}

            >
              <img
                alt="example"
                style={{ width: "40%", bottom: 0, right: 0, position: "absolute" }}
                src={bg1}
              />
              <div
                style={{ width: "60%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: 50 }}
              >
                <div>
                  <h3
                    style={{
                      fontSize: 82,
                      textAlign: "center",
                      color: "#06c755",
                      fontWeight: "bold",
                      textShadow: "4px 4px black",
                      WebkitTextStroke: "2px white" }}
                  >เพิ่มเพื่อน <img src={lineLogo} width="64px" alt="line-logo" />
                  </h3>
                  <h3
                    style={{
                      fontSize: 48,
                      textAlign: "center",
                      color: "white",
                      textShadow: "2px 2px black",
                      WebkitTextStroke: "2px #1c3369" }}
                  >เพื่อรับของรางวัลลูกค้าใหม่
                  </h3>
                  <h3
                    style={{
                      fontSize: 38,
                      textAlign: "center",
                      color: "white",
                      textShadow: "2px 2px black",
                      WebkitTextStroke: "2px #1c3369" }}
                  >และติดตามข่าวสารโปรโมชั่นทุกเดือน
                  </h3>
                  <h3
                    style={{
                      fontSize: 36,
                      color: "#d55d38",
                      textAlign: "center",
                      textShadow: "2px 2px black",
                      WebkitTextStroke: "1px white" }}
                  >@baanya-online หรือคลิกด้านล่าง
                  </h3>
                </div>
                <a href="https://lin.ee/Gd0KQM1"><img src={imageBtn} alt="เพิ่มเพื่อน" height="100" border="0" /></a>
              </div>
            </div>
          </Modal>
        </Container>
      </RegisterLayout>
    );
  }
}

RegisterScreen.propTypes = {

};

RegisterScreen.defaultProps = {

};

const mapStateToProps = ({ auth }) => ({
  isAuth: auth.isAuth
});


export default compose(
  connect(mapStateToProps),
  Form.create(),
  withRouter,
)(RegisterScreen);
