/**
 *
 * PromotionScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import { Table, Tag, Pagination, Button, Row, Col } from "antd";
import { connect } from "react-redux";
import _get from "lodash/get";
import qs from "query-string";
import { FaShoppingBasket } from "react-icons/fa";
// import _isEmpty from 'lodash/isEmpty'
import dataProvider from "utils/dataProvider";
import noImage from "image/noimage.png";

// import withSearch from '../../../hoc/withSearch'
import withProductModal from "../../../hoc/withProductModal";
import ProductList from "../../../components/ProductList";

const Container = styled.div``;
class PromotionScreen extends Component {
  // eslint-disable-line
  state = {
    productList: [],
    countProduct: 0,
    limit: 30,
    // skip: 0
  };

  provider = dataProvider();

  componentDidMount() {
    this.onFetchPromotion({});
  }

  setValue = (value, type) => {
    this.setState({
      [type]: value,
    });
  };

  addToWishList = async (productsId) => {
    await this.provider.fetch.post(
      "/myusers/me/wishlists",
      { productsId, status: true },
      { noCache: true }
    );
    await this.props.getUserInfoAsync();
  };

  onFetchPromotion = async ({ skip = 0 }) => {
    const res = await this.provider.fetch.get(
      "/promotions",
      {
        filter: {
          include: ["productDetail", { products: ["categories"] }],
          where: { end_date: { gt: new Date() } },
          limit: this.state.limit,
          skip,
        },
      },
      { noCache: true }
    );
    const resCount = await this.provider.fetch.get(
      "/promotions/count",
      {
        where: { end_date: { gt: new Date() } },
      },
      {
        noCache: true,
      }
    );
    const a = res.map((item) => {
      const temp = {
        ...item.products,
        ...item,
        promotionsId: item.id,
        id: item.productsId,
      };
      return temp;
    });
    return this.setState({
      productList: a,
      countProduct: resCount.count,
    });
  };

  render() {
    const { skip = 0 } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { limit } = this.state;

    const columns = [
      {
        title: "รหัส",
        dataIndex: "code",
        key: "code",
        align: "center",
        render: (data) => (
          <div>
            <div>{data}</div>
            <Tag color="red">Promotion</Tag>
          </div>
        ),
      },
      {
        title: "รูปภาพ",
        dataIndex: "product_image",
        key: "product_image",
        render: (data) => (
          <div style={{ width: "60px", height: "60px" }}>
            <img
              style={{ objectFit: "cover" }}
              width={60}
              height={60}
              src={data || noImage}
              alt="product"
              onError={(evt) => {
                evt.target.src = noImage;
              }}
            />
          </div>
        ),
      },
      {
        title: "ชื่อสินค้า",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "บริษัท",
        dataIndex: "factory",
        key: "factory",
      },
      {
        title: "หมวดหมู่",
        dataIndex: "categories",
        key: "categories",
        render: (data) => data.map((item) => <Tag>{item.name}</Tag>),
      },
      {
        title: "ราคา",
        dataIndex: "productDetail",
        key: "price",
        align: "center",
        width: 200,
        render: (data) => (
          <>
            {data.map((item) => (
              <div>
                {_get(item, "unit_price")}
                {": "}
                {_get(item, "price")}
              </div>
            ))}
          </>
        ),
      },
      {
        title: "",
        key: "action",
        render: (text, record) => {
          const alreadyWish =
            _get(this, "props.wishlist", []).find(
              (item) => item.productsId === record.id && item.status
            ) || false;
          const { status } = record;
          return status ? (
            <Button
              disabled={!status}
              onClick={() => {
                this.props.productModal.addProductDetail(record);
                this.props.productModal.setOpenModal(true);
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                สั่งซื้อ
                <FaShoppingBasket style={{ marginLeft: 10 }} />
              </div>
            </Button>
          ) : (
            <Button
              style={
                alreadyWish
                  ? {}
                  : { backgroundColor: "#ffbd00", color: "white" }
              }
              onClick={() => this.addToWishList(record.id)}
              disabled={alreadyWish}
            >
              {alreadyWish ? "รับการแจ้งเตือนแล้ว" : "แจ้งเตือนสินค้าเข้า"}
            </Button>
          );
        },
      },
    ];
    return (
      <Container>
        <Row>
          <Col xs={0} md={24}>
            <Table
              pagination={false}
              columns={columns}
              dataSource={this.state.productList}
              rowKey={(record) => record.id}
            />
          </Col>
          <Col xs={24} md={0}>
            <ProductList
              isPromotionScreen
              productList={this.state.productList}
              addProductDetail={this.props.productModal.addProductDetail}
              setOpenModal={this.props.productModal.setOpenModal}
              wishlist={_get(this, "props.wishlist", [])}
              addToWishList={this.addToWishList}
            />
          </Col>
        </Row>
        <div>
          <Pagination
            total={this.state.countProduct}
            defaultCurrent={(Number(skip) + Number(limit)) / Number(limit)}
            defaultPageSize={limit}
            onChange={(page) =>
              this.onFetchPromotion({ skip: (page - 1) * limit })
            }
          />
        </div>
      </Container>
    );
  }
}

PromotionScreen.propTypes = {};

PromotionScreen.defaultProps = {};

const mapDispatch = ({ cart, auth }) => ({
  addItem: cart.addItem,
  deleteItem: cart.deleteItem,
  getUserInfoAsync: auth.getUserInfoAsync,
});
const mapState = ({ auth }) => ({
  wishlist: auth.userInfo.wishlists,
});
// eslint-disable-next-line
PromotionScreen = withProductModal(PromotionScreen);

export default connect(mapState, mapDispatch)(PromotionScreen);
