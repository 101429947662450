// import Home from '../Home/Home'
// import MembersPage from '../../containers/MembersPage'
// import AdminForm from '../../components/AdminForm'
// import MusicForm from '../../components/MusicForm'
// import AddMusicPage from '../../containers/AddMusicPage'
import {
  FaHome,
  FaGifts,
  FaBell,
  FaClipboard,
  FaEnvelope,
  FaShoppingCart,
} from "react-icons/fa";
import Dashboard from "screen/UserPage/Dashboard";
import NewsListScreen from "screen/UserPage/NewsListScreen";
import NewsScreen from "screen/UserPage/NewsScreen";
import ProductScreen from "screen/UserPage/ProductScreen";
import CartScreen from "screen/UserPage/CartScreen";
import PromotionScreen from "screen/UserPage/PromotionScreen";
import MyOrderListScreen from "screen/UserPage/MyOrderListScreen";
import MyOrderDetailScreen from "screen/UserPage/MyOrderDetailScreen";
import WishlistScreen from "screen/UserPage/WishlistScreen";

export const routes = [
  {
    path: "dashboard",
    title: "ข้อมูลของฉัน",
    menu: "ข้อมูลของฉัน",
    icon: FaHome,
    component: Dashboard,
  },
  {
    path: "product",
    title: "Product",
    menu: "สินค้า",
    icon: FaShoppingCart,
    component: ProductScreen,
  },
  {
    path: "promotion",
    title: "Promotion",
    menu: "โปรโมชั่น",
    icon: FaGifts,
    component: PromotionScreen,
  },
  {
    path: "wishlist",
    title: "แจ้งเตือนสินค้าเข้า",
    menu: "แจ้งเตือนสินค้าเข้า",
    icon: FaBell,
    component: WishlistScreen,
  },
  {
    path: "my-order",
    title: "ประวัติการสั่งซื้อ",
    menu: "ประวัติการสั่งซื้อ",
    icon: FaClipboard,
    component: MyOrderListScreen,
  },
  {
    path: "my-order/:orderCode",
    title: "รายการสั่งซื้อ",
    component: MyOrderDetailScreen,
  },
  {
    path: "cart",
    title: "ตะกร้าของฉัน",
    component: CartScreen,
  },
  {
    path: "news",
    title: "News",
    menu: "ข่าวสาร",
    icon: FaEnvelope,
    component: NewsListScreen,
  },
  {
    path: "news/:id",
    icon: FaEnvelope,
    component: NewsScreen,
  },
];
