import React from "react";
import { compose } from "recompose";
import { Input, Switch, Form, Button, Select, InputNumber, Popconfirm } from "antd";
import _get from "lodash/get";
import dayjs from "dayjs";
import _isEmpty from "lodash/isEmpty";
import isBetween from "dayjs/plugin/isBetween";
import Upload from "components/Upload";

dayjs.extend(isBetween);

const { Option } = Select;

class ProductForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const { product } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          price_main,
          price_secondary,
          price_third,
          unit_price_main,
          unit_price_secondary,
          unit_price_third,
          weight_main,
          weight_secondary,
          weight_third,
          fixed_delivery_cost_main,
          fixed_delivery_cost_secondary,
          fixed_delivery_cost_third,
        } = values;
        const [mainDetail, secondaryDetail, thirdDetail] = product?.productDetail || [];
        const productDetails = [];
        if (values.price_main) {
          const isDeleted = !!(mainDetail?.price && !price_main);
          delete values.price_main;
          delete values.unit_price_main;
          delete values.weight_main;
          delete values.fixed_delivery_cost_main;
          productDetails.push({
            id: _get(product, "productDetail[0].id"),
            price: isDeleted ? mainDetail?.price : price_main,
            unit_price: isDeleted ? mainDetail?.unit_price : unit_price_main,
            weight: weight_main,
            fixed_delivery_cost: fixed_delivery_cost_main,
            isDeleted
          });
        }
        if (secondaryDetail?.price || values.price_secondary) {
          delete values.price_secondary;
          delete values.unit_price_secondary;
          delete values.weight_secondary;
          delete values.fixed_delivery_cost_secondary;
          const isDeleted = !!(secondaryDetail?.price && !price_secondary);
          productDetails.push({
            id: _get(product, "productDetail[1].id"),
            price: isDeleted ? secondaryDetail?.price : price_secondary,
            unit_price: isDeleted ? secondaryDetail?.unit_price : unit_price_secondary,
            weight: weight_secondary,
            fixed_delivery_cost: fixed_delivery_cost_secondary,
            isDeleted
          });
        }
        if (thirdDetail?.price || values.price_third) {
          delete values.price_third;
          delete values.unit_price_third;
          delete values.weight_third;
          delete values.fixed_delivery_cost_third;
          const isDeleted = !!(thirdDetail?.price && !price_third);
          productDetails.push({
            id: _get(product, "productDetail[2].id"),
            price: isDeleted ? thirdDetail?.price : price_third,
            unit_price: isDeleted ? thirdDetail?.unit_price : unit_price_third,
            weight: weight_third,
            fixed_delivery_cost: fixed_delivery_cost_third,
            isDeleted
          });
        }
        this.props.onSubmit({ productDetails, ...values });
      }
    });
  };

  onUploadImage = (url, type) => {
    if (type === "product_image") {
      this.props.form.setFieldsValue({
        product_image: url
      });
    }
  };

  render() {
    const {
      form: { getFieldDecorator },
      categories,
      product = {},
      onDelete
    } = this.props;
    const {
      id,
      code,
      barcode,
      drug_type,
      factory,
      name,
      description,
      shape,
      price_main,
      price_secondary,
      price_third,
      weight_main,
      weight_secondary,
      weight_third,
      product_image,
      status,
      stock_place,
      unit_price_main,
      unit_price_secondary,
      unit_price_third,
      fixed_delivery_cost_main,
      fixed_delivery_cost_secondary,
      fixed_delivery_cost_third,
      promotions
    } = product;

    const category = _get(product, "categories", []).map((item) => item.id);
    return (
      <>
        <div style={{ position: "absolute", right: "20px", zIndex: 10 }}>
          {id && _isEmpty(promotions) && (
            <Button
              type="primary"
              onClick={() => this.props.history.push(`${id}/promotion/create`)}
            >
              เพิ่มโปรโมชั่น
            </Button>
          )}
          {!_isEmpty(promotions) && (
            <Button
              style={{ backgroundColor: "#5cc749", color: "white" }}
              onClick={() => this.props.history.push(`${id}/promotion/${promotions[0].id}`)}
            >
              แก้ไขโปรโมชั่น
            </Button>
          )}
        </div>
        <Form
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          onSubmit={this.handleSubmit}
          labelAlign="left"
        >
          <Form.Item label="รูปสินค้า">
            {getFieldDecorator("product_image", {
              rules: [{ required: true, message: "กรุณาอัพโหลดรูปสินค้า" }],
              initialValue: product_image
            })(<Input style={{ display: "none" }} />)}
            <Upload
              onUpload={(url) => this.onUploadImage(url, "product_image")}
              defaultImage={product_image}
            />
          </Form.Item>
          <Form.Item label="ชื่อสินค้า">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "กรุณากรอกชื่อสินค้า" }],
              initialValue: name
            })(<Input />)}
          </Form.Item>
          <Form.Item label="คำอธิบายสินค้า">
            {getFieldDecorator("description", {
              rules: [{ required: true, message: "กรุณากรอกคำอธิบายของสินค้า" }],
              initialValue: description
            })(<Input />)}
          </Form.Item>
          <Form.Item label="บริษัทที่ผลิต">
            {getFieldDecorator("factory", {
              rules: [{ required: true, message: "กรุณากรอกชื่อบริษัท" }],
              initialValue: factory
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ชื่อตัวยา">
            {getFieldDecorator("drug_type", {
              rules: [{ required: true, message: "กรุณากรอกชื่อตัวยา" }],
              initialValue: drug_type
            })(<Input />)}
          </Form.Item>
          <Form.Item label="code">
            {getFieldDecorator("code", {
              rules: [{ required: true, message: "กรุณากรอกรหัสสินค้า" }],
              initialValue: code
            })(<Input />)}
          </Form.Item>
          <Form.Item label="barcode">
            {getFieldDecorator("barcode", {
              initialValue: barcode
            })(<Input />)}
          </Form.Item>
          <Form.Item label="หมวดหมู่">
            {getFieldDecorator("category", {
              rules: [{ required: true, message: "กรุณาเลือกหมวดหมู่" }],
              initialValue: category
            })(
              <Select mode="multiple" showArrow>
                {categories
                  && categories.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="ที่ตั้งของสินค้า">
            {getFieldDecorator("stock_place", {
              rules: [{ required: true, message: "กรุณากรอกที่ตั้งของสินค้า" }],
              initialValue: stock_place
            })(<Input />)}
          </Form.Item>
          <Form.Item label="รูปร่างสินค้า">
            {getFieldDecorator("shape", {
              initialValue: shape
            })(<Input />)}
          </Form.Item>
          <div>
            <Form.Item label="ราคาหลัก">
              {getFieldDecorator("price_main", {
                rules: [{ required: true, message: "กรุณากรอกราคาหลัก" }],
                initialValue: price_main
              })(<InputNumber style={{ width: "100%" }} min={0} />)}
            </Form.Item>
            <Form.Item label="หน่วยของราคาหลัก">
              {getFieldDecorator("unit_price_main", {
                rules: [{ required: true, message: "กรุณากรอกชืหน่วยของราคาหลัก" }],
                initialValue: unit_price_main
              })(<Input />)}
            </Form.Item>
            <Form.Item label="น้ำหนักต่อหน่วย(Kg)">
              {getFieldDecorator("weight_main", {
                initialValue: weight_main
              })(<Input />)}
            </Form.Item>
            <Form.Item label="ราคาค่าส่งแบบตายตัวต่อหนึ่งหน่วย(บาท)">
              {getFieldDecorator("fixed_delivery_cost_main", {
                initialValue: fixed_delivery_cost_main
              })(<Input />)}
            </Form.Item>
          </div>
          <Form.Item label="ราคาที่สอง">
            {getFieldDecorator("price_secondary", { initialValue: price_secondary })(
              <InputNumber style={{ width: "100%" }} min={0} />
            )}
          </Form.Item>
          <Form.Item label="หน่วยของราคาที่สอง">
            {getFieldDecorator("unit_price_secondary", { initialValue: unit_price_secondary })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="น้ำหนักต่อหน่วย(Kg)">
            {getFieldDecorator("weight_secondary", {
              initialValue: weight_secondary
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ราคาค่าส่งแบบตายตัวต่อหนึ่งหน่วย(บาท)">
            {getFieldDecorator("fixed_delivery_cost_secondary", {
              initialValue: fixed_delivery_cost_secondary
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ราคาที่สาม">
            {getFieldDecorator("price_third", { initialValue: price_third })(
              <InputNumber style={{ width: "100%" }} min={0} />
            )}
          </Form.Item>
          <Form.Item label="หน่วยของราคาที่สาม">
            {getFieldDecorator("unit_price_third", { initialValue: unit_price_third })(<Input />)}
          </Form.Item>
          <Form.Item label="น้ำหนักต่อหน่วย(Kg)">
            {getFieldDecorator("weight_third", {
              initialValue: weight_third
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ราคาค่าส่งแบบตายตัวต่อหนึ่งหน่วย(บาท)">
            {getFieldDecorator("fixed_delivery_cost_third", {
              initialValue: fixed_delivery_cost_third
            })(<Input />)}
          </Form.Item>
          <Form.Item label="status">
            {getFieldDecorator("status", {
              initialValue: status === true,
              valuePropName: "checked"
            })(<Switch />)}
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
            <Button type="primary" htmlType="submit" style={{ marginRight: "20px" }}>
              บันทึก
            </Button>
            {onDelete && (
              <Popconfirm
                title="ต้องการซ่อนสินค้านี้้ใช่หรอไม่?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">ซ่อนสินค้าจากหน้าเว็ป</Button>
              </Popconfirm>
            )}
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default compose(Form.create())(ProductForm);
