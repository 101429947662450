/**
*
* AuthRoute
*
*/

import React from "react";
import { Route, Redirect } from "react-router-dom";
// import styles from './AuthRoute.module.css'
import PropTypes from "prop-types";
import { connect } from "react-redux";

function AuthRoute({ component: Component, isAuth, isFetchProfile = [], ...rest }) {
  if (!isFetchProfile) {
    return null;
  }
  if (!isAuth) {
    localStorage.accessToken = undefined;
  }
  return (
    <Route
      {...rest}
      render={props => isAuth ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
      }
    />
  );
}

AuthRoute.propTypes = {
  component: PropTypes.any.isRequired, // eslint-disable-line
  location: PropTypes.shape({
  }).isRequired,
};

const mapState = ({ auth }) => ({
  isAuth: auth.isAuth,
  isFetchProfile: auth.isFetchProfile,
});

export default connect(mapState)(AuthRoute);
