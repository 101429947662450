const ENV = {
  dev: {
    API_URL: "http://localhost:3000/api",
  },
  staging: {
    API_URL: "https://baanya-online.com/api",
  },
  prod: {
    API_URL: "http://localhost:3000",
  },
};

function getEnvVars(env = "") {
  if (env === null || env === undefined || env === "") return ENV.dev;

  if (env.indexOf("dev") !== -1) return ENV.dev;
  if (env.indexOf("staging") !== -1) return ENV.staging;
  if (env.indexOf("prod") !== -1) return ENV.prod;
  return ENV.dev;
}

export default getEnvVars(process.env.REACT_APP_STAGE);
