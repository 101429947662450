/**
*
* AdminCreateProductScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Card, message } from "antd";
import dataProvider from "utils/dataProvider";
import EmployerForm from "../../../components/EmployerForm";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class AddEmployerScreen extends Component { // eslint-disable-line
  provider = dataProvider()

  createEmployer = async (values) => {
    try {
      await this.provider.fetch.post("/employers", {
        ...values
      }, { noCache: true });
      message.success("create employer success");
      this.props.history.push("/slip");
    } catch (ex) {
      const resErr = _.get(ex, "response");
      const msg = _.get(resErr, "data.error.message");
      if (resErr.status === 422) {
        message.error(msg);
      } else {
        message.error("มีข้อผิดพลาดเกิดขึ้น");
      }
    }
  }

  render() {
    return (
      <Container>
        <Card style={{ width: 600, textAlign: "center" }}>
          <EmployerForm
            onSubmit={this.createEmployer}
          />
        </Card>
      </Container>
    );
  }
}

AddEmployerScreen.propTypes = {

};

AddEmployerScreen.defaultProps = {

};
export default compose(
  withRouter,
)(AddEmployerScreen);
