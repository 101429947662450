import React from "react";
import _isEmpty from "lodash/isEmpty";
import ProductCard from "../ProductCard";

const ProductList = ({
  productList,
  findProduct,
  setOpenModal,
  addProductDetail,
  isPromotionScreen,
  wishlist,
  addToWishList,
}) => {
  if (_isEmpty(productList)) return <div />;
  return productList.map((item, index) => (
    <ProductCard
      data={item}
      key={index}
      findProduct={findProduct}
      setOpenModal={setOpenModal}
      addProductDetail={addProductDetail}
      isPromotionScreen={isPromotionScreen}
      wishlist={wishlist}
      addToWishList={addToWishList}
    />
  ));
};

export default ProductList;
