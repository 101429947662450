/**
 *
 * WishlistScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import { Table, Tag, Pagination, Button, Row, Col } from "antd";
import { connect } from "react-redux";
import _get from "lodash/get";
import { FaShoppingBasket } from "react-icons/fa";
import qs from "query-string";

import _isEmpty from "lodash/isEmpty";
import dataProvider from "utils/dataProvider";
import noImage from "image/noimage.png";
import withProductModal from "../../../hoc/withProductModal";
import ProductList from "../../../components/ProductList";

const Container = styled.div``;
class WishlistScreen extends Component {
  // eslint-disable-line
  state = {
    // quantity: [],
    limit: 30,
    productList: [],
    countProduct: 0,
  };

  provider = dataProvider();

  async componentDidMount() {
    const result = await this.provider.fetch.get(
      "/myusers/me/wishlists",
      {
        filter: {
          include: [
            {
              relation: "products",
              scope: {
                include: [
                  "productDetail",
                  { promotions: "productDetail" },
                  "categories",
                ],
                isDeleted: true,
              },
            },
          ],
        },
      },
      { noCache: true }
    );

    const resCount = await this.provider.fetch.get(
      "/myusers/me/wishlists/count",
      null,
      { noCache: true }
    );
    const productList = result.map((item) => ({
      wishlistStatus: item.status,
      ...item.products,
    }));
    this.setState({
      productList,
      countProduct: resCount.count,
    });
  }

  render() {
    const { skip = 0 } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { limit } = this.state;
    const columns = [
      {
        title: "รหัส",
        dataIndex: "promotions",
        key: "code",
        align: "center",
        render: (data, record) => {
          if (_isEmpty(data)) {
            return _get(record, "code");
          }
          return (
            <div>
              <div>{_get(record, "code")}</div>
              <Tag color="red">โปรโมชั่น</Tag>
            </div>
          );
        },
      },
      {
        title: "รูปภาพ",
        dataIndex: "promotions",
        key: "product_image",
        render: (data, record) => {
          if (_isEmpty(data)) {
            return (
              <div style={{ width: "60px", height: "60px" }}>
                <img
                  style={{ objectFit: "cover" }}
                  width="100%"
                  height="100%"
                  src={_get(record, "product_image") || noImage}
                  alt="product"
                  onError={(evt) => {
                    evt.target.src = noImage;
                  }}
                />
              </div>
            );
          }
          return (
            <div style={{ width: "60px", height: "60px" }}>
              <img
                style={{ objectFit: "cover" }}
                width={60}
                height={60}
                src={
                  _get(data, "[0].product_image") ||
                  _get(record, "product_image") ||
                  noImage
                }
                alt="product"
                onError={(evt) => {
                  evt.target.src = noImage;
                }}
              />
            </div>
          );
        },
      },
      {
        title: "ชื่อสินค้า",
        dataIndex: "promotions",
        key: "name",
        render: (data, record) => {
          if (_isEmpty(data)) {
            return _get(record, "name");
          }
          return _get(data, "[0].name");
        },
      },
      {
        title: "บริษัท",
        dataIndex: "factory",
        key: "factory",
      },
      {
        title: "หมวดหมู่",
        dataIndex: "categories",
        key: "categories",
        render: (data) => data?.map((item) => <Tag>{item.name}</Tag>),
      },
      {
        title: "ราคา",
        dataIndex: "promotions",
        key: "price",
        align: "center",
        width: 200,
        render: (data, record) => {
          if (_isEmpty(data)) {
            return (
              <>
                {!_isEmpty(record.productDetail) &&
                  record.productDetail.map((item) => (
                    <div>
                      {_get(item, "unit_price")}
                      {": "}
                      {_get(item, "price")}
                    </div>
                  ))}
              </>
            );
          }
          return (
            <>
              {data[0].productDetail.map((item) => (
                <div>
                  {_get(item, "unit_price")}
                  {": "}
                  {_get(item, "price")}
                </div>
              ))}
            </>
          );
        },
      },
      {
        title: "",
        key: "action",
        render: (text, record) => {
          const { status } = record;
          const alreadyWish =
            _get(this, "props.wishlist", []).find(
              (item) => item.productsId === record.id && item.status
            ) || false;
          return status ? (
            <Button
              type="primary"
              disabled={!status}
              onClick={() => {
                this.props.productModal.findProduct(record.id);
                this.props.productModal.setOpenModal(true);
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                สั่งซื้อ
                <FaShoppingBasket style={{ marginLeft: 10 }} />
              </div>
            </Button>
          ) : (
            <Button
              style={
                alreadyWish
                  ? {}
                  : { backgroundColor: "#ffbd00", color: "white" }
              }
              onClick={() => this.addToWishList(record.id)}
              disabled={alreadyWish}
            >
              {alreadyWish ? "รอสินค้า" : "แจ้งเตือนสินค้าเข้า"}
            </Button>
          );
        },
      },
    ];

    return (
      <Container>
        <Row>
          <Col xs={0} md={24}>
            <Table
              pagination={false}
              columns={columns}
              dataSource={this.state.productList}
              rowKey={(record) => record.id}
            />
          </Col>
          <Col xs={24} md={0}>
            <ProductList
              productList={this.state?.productList}
              findProduct={this.props.productModal.findProduct}
              setOpenModal={this.props.productModal.setOpenModal}
              wishlist={_get(this, "props.wishlist", [])}
              addToWishList={this.addToWishList}
            />
          </Col>
        </Row>
        <div>
          <Pagination
            total={this.state.countProduct}
            defaultCurrent={(Number(skip) + Number(limit)) / Number(limit)}
            defaultPageSize={limit}
            onChange={(page) => {
              this.props.search((page - 1) * limit, "skip");
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }}
          />
        </div>
      </Container>
    );
  }
}

WishlistScreen.propTypes = {};

WishlistScreen.defaultProps = {};

const mapDispatch = ({ cart, auth }) => ({
  addItem: cart.addItem,
  deleteItem: cart.deleteItem,
  getUserInfoAsync: auth.getUserInfoAsync,
});

const mapState = ({ auth }) => ({
  wishlist: auth.userInfo.wishlists,
});
// eslint-disable-next-line
WishlistScreen = withProductModal(WishlistScreen);

export default connect(mapState, mapDispatch)(WishlistScreen);
