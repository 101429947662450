// import { createSelector } from 'reselect';
import dataProvider from "../utils/dataProvider";

export const auth = {
  state: {
    accessToken: true,
    userInfo: {},
    isFetchProfile: false,
    isAuth: null,
    error: false
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setToken (state, payload) {
      return {
        ...state,
        accessToken: payload
      };
    },
    setError (state, payload) {
      return {
        ...state,
        error: payload
      };
    },
    setUserInfo (state, payload) {
      return {
        ...state,
        userInfo: payload
      };
    },
    isFetchProfile (state, payload) {
      return {
        ...state,
        isFetchProfile: payload
      };
    },
    setIsAuth (state, payload) {
      return {
        ...state,
        isAuth: payload
      };
    },
    logout (state) {
      localStorage.accessToken = undefined;
      return {
        ...state,
        userInfo: {},
        accessToken: null,
        isAuth: false
      };
    }
  },
  effects: () => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async loginAsync (payload) {
      // const token = await new Promise(resolve => setTimeout(() => resolve('Some token'), 1000))
      try {
        const provider = dataProvider();
        const result = await provider.request({
          method: "post",
          url: "/myusers/login",
          data: payload
        });
        const { id: jwtToken } = result;
        if (!jwtToken) {
          return Promise.reject(new Error("Token not found"));
        }
        localStorage.accessToken = jwtToken;
        this.setToken(jwtToken);
        this.setIsAuth(true);
        const userProfile = await this.getUserInfoAsync();
        return Promise.resolve(userProfile);
      } catch (e) {
        this.setError(e.message);
        return Promise.reject(e);
      }
      // dispatch.Auth.setToken(token)
    },
    async getUserInfoAsync () {
      try {
        const provider = dataProvider();
        const result = await provider.request({
          method: "get",
          url: "/myusers/me",
          params: {
            filter: {
              include: ["role", "wishlists"]
            }
          }
        });
        this.setUserInfo(result);
        this.setIsAuth(true);
        return Promise.resolve(result);
      } catch (e) {
        this.setIsAuth(false);
        return Promise.reject(e);
      } finally {
        this.isFetchProfile(true);
      }
    },
    async logoutAsync () {
      try {
        const provider = dataProvider();
        provider.fetch.post("/myusers/logout", null, { params: { access_token: localStorage.accessToken } });
        this.logout();
      } catch (error) {
        throw error;
      }
    }
  }),
  selectors: (slice, createSelector) => ({
    isAuthenticated: () => createSelector(slice, state => !!state.accessToken)
  })
};
