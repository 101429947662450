/**
*
* AdminStaffCreateScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Form, Input, Button, Select } from "antd";
import dataProvider from "utils/dataProvider";
import Swal from "sweetalert2";
// import PropTypes from 'prop-types'

const { Option } = Select;

const Container = styled.div`

`;


class AdminStaffCreateScreen extends Component { // eslint-disable-line
  state = {
    roleList: [],
  }

  provider = dataProvider()


  componentDidMount() {
    this.getRole();
  }

  getRole = async () => {
    const roleList = await this.provider.fetch.get("/roles");
    this.setState({ roleList });
  }

  handleSelectChange = value => {
    this.props.form.setFieldsValue({
      roleId: value,
    });
  }

  confirmPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("pass word not match!");
    } else {
      callback();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const response = await this.provider.fetch.post("/myusers/createStaff", { data: values }, { noCache: true });
        const { statusCode } = response;
        if (!statusCode) {
          Swal.fire({
            title: "Success!",
            text: "สร้างไอดีเรียบร้อย",
            type: "success",
            confirmButtonText: "OK",
            onAfterClose: () => this.props.history.push("/admin/staffs"),
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: response.message.toString(),
            type: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Container>
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
          <Form.Item label="email">
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Please input your username!" },
                { type: "email", message: "wrong email format" },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="password">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please input your password!" }],
            })(<Input type="password" />)}
          </Form.Item>
          <Form.Item label="confirm password">
            {getFieldDecorator("confirm_password", {
              rules: [
                { required: true, message: "Please input your password!" },
                { validator: this.confirmPassword },
              ],
            })(<Input type="password" />)}
          </Form.Item>
          <Form.Item label="ตำแหน่ง">
            {getFieldDecorator("roleId", {
              rules: [{ required: true, message: "Please select your gender!" }],
            })(
              <Select
                onChange={this.handleSelectChange}
              >
                {
                  this.state.roleList.map(role => (
                    <Option value={role.id} key={role.id}>{role.name}</Option>
                  ))
                }
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="ชื่อ นามกุล">
            {getFieldDecorator("full_name", {
              rules: [{ required: true, message: "Please input your username!" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="เบอร์ติดต่อ">
            {getFieldDecorator("mobile", {
              rules: [{ required: true, message: "Please input your username!" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="บัตรประชาขน">
            {getFieldDecorator("persornal_id", {
              rules: [],
            })(<Input />)}
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}

AdminStaffCreateScreen.propTypes = {

};

AdminStaffCreateScreen.defaultProps = {

};

export default compose(
  Form.create(),
  withRouter,
)(AdminStaffCreateScreen);
