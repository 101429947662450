/**
 *
 * PromoteList
 *
 */

import { Avatar, Card, Col, Icon, Row } from "antd";
import Text from "antd/lib/typography/Text";
import dayjs from "dayjs";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import styles from './PromoteList.module.css'
// import PropTypes from 'prop-types'
const { Meta } = Card;

const Container = styled.div``;
const TextCustom = styled.div`
  font-size: 20px;
  color: #666666;
  margin: 0;
  line-height: 32px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 48px;
  }
`;

class PromoteList extends Component {
  // eslint-disable-line
  render() {
    const { promotions, isLoaded = false } = this.props;
    if (!isLoaded) {
      return null;
    }
    if (!promotions || promotions.length < 1) {
      return (
        <div
          style={{
            background: "white",
            height: 300,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "30px 0",
            padding: 16,
          }}
        >
          <TextCustom>รอติดตามโปรโมชั่นดีๆกับทางเราเร็วๆนี้</TextCustom>
        </div>
      );
    }
    return (
      <Container>
        <Row gutter={16} style={{ flexWrap: "wrap", margin: "30px 0" }}>
          {this.props.promotions?.map((p) => (
            <Col sm={24} md={12} lg={8}>
              <Link to="/login?redirect=promotion">
                <Card
                  style={{ width: "100%" }}
                  bodyStyle={{ padding: "16px 8px" }}
                  cover={
                    <img
                      alt=""
                      src={p.promote_image}
                      width="100%"
                      height={300}
                      style={{
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: 8,
                      }}
                    />
                  }
                  actions={[
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          type="calendar"
                          style={{ marginRight: 8, flex: 0, marginTop: 2 }}
                        />
                        {dayjs(p.active_date).format("DD MMM YYYY")} -{" "}
                        {dayjs(p.end_date).format("DD MMM YYYY")}
                      </div>
                      <Text style={{ width: "100%" }} type="danger">
                        ซื้อตอนนี้
                      </Text>
                    </>,
                  ]}
                >
                  <Meta
                    avatar={<Avatar src={p.products.product_image} size={64} />}
                    title={
                      <Text strong ellipsis style={{ width: "100%" }}>
                        {p.name || p.products.name}
                      </Text>
                    }
                    description={
                      <Text style={{ width: "100%" }}>{p.promote_desc}</Text>
                    }
                  />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

PromoteList.propTypes = {};

PromoteList.defaultProps = {};
export default PromoteList;
