import React from "react";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import "antd/lib/breadcrumb/style/index.less";
import { Icon, Menu } from "antd";

import AuthRoute from "components/AuthRoute";
import { routes } from "./routes";
// import ErrorPage from 'containers/ErrorPage/Loadable'
// import { ERR_NOT_FOUND } from 'containers/ErrorPage/constants'
/* eslint-disable object-property-newline */


// const NotFoundPage = (props) => (
//   <ErrorPage error={ERR_NOT_FOUND} {...props} />
// )

function joinPath(base, path) {
  let result = `${base}/${path}`;
  if (!(path && path.length > 0)) {
    result = `${base}`;
  }
  if (base.slice(-1) === "/") {
    result = `${base}${path}`;
  }
  return result;
}

function UserBreadcrumbRecursive(basePath, r = routes) {
  return (
    <Switch>
      {r.map((route) => (
        <Route
          strict
          exact={!(route.children && route.children.length > 0)}
          key={joinPath(basePath, route.path)}
          path={joinPath(basePath, route.path)}
        >
          <span>
            {route.title && <span className="ant-breadcrumb-separator">/</span>}
            <span
              className="ant-breadcrumb-link"
            >
              {route.title}
            </span>
            {route.children && route.children.length > 0
              && UserBreadcrumbRecursive(joinPath(basePath, route.path), route.children)}
          </span>
        </Route>
      ))}
    </Switch>
  );
}
function UserMenuRecursive(basePath, r = routes) {
  return r.map((route) => {
    const IconElement = route.icon;
    let view = null;
    if (route.menu) {
      view = (
        <Menu.Item key={joinPath(basePath, route.path)}>
          <Link to={joinPath(basePath, route.path)}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <IconElement style={{ fontSize: 22, marginRight: 10 }} />
              <span>{route.menu}</span>
            </span>
          </Link>
        </Menu.Item>
      );
    }
    if (route.children && route.children.length > 0) {
      view = (
        <Menu.SubMenu
          title={(
            <span style={{ display: "flex", alignItems: "center" }}>
              <IconElement style={{ fontSize: 22, marginRight: 10 }} />
              <span>{route.menu}</span>
            </span>
          )}
          key={joinPath(basePath, route.path)}
        >
          {UserMenuRecursive(joinPath(basePath, route.path), route.children)}
        </Menu.SubMenu>
      );
    }
    return view;
  });
}

export function UserBreadcrumb(basePath, r = routes) {
  return (
    <div style={{ margin: "16px 0" }}>
      <Link className="ant-breadcrumb-link" to={`${basePath}`}>
        <Icon type="home" style={{ fontSize: 22 }} />
      </Link>
      {UserBreadcrumbRecursive(basePath, r)}
    </div>
  );
}
export function UserRouter(basePath, r = routes) {
  return (
    <Switch>
      <Route
        exact
        path="/"
      >
        <Redirect to={{ pathname: "/dashboard" }} />
      </Route>
      {r.map((route) => (
        <AuthRoute
          strict
          exact={!(route.children && route.children.length > 0)}
          key={joinPath(basePath, route.path)}
          path={joinPath(basePath, route.path)}
          component={route.component}
        >
          {route.children && route.children.length > 0
            && UserRouter(joinPath(basePath, route.path), route.children)}
        </AuthRoute>
      ))}
      <Route
        render={() => (
          <Redirect
            to={{
              pathname: "/404"
            }}
          />
        )
      }
      />
    </Switch>
  );
}
export function UserMenu(basePath, r = routes) {
  return UserMenuRecursive(basePath, r);
}
export default routes;
