/**
*
* AdminValidateUserScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Button, Descriptions, Card, Modal, Popconfirm } from "antd";
import dataProvider from "utils/dataProvider";
// import PropTypes from 'prop-types'


// const { Text } = Typography

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between
`;
class AdminValidateUserScreen extends Component { // eslint-disable-line
  state = {
    user: {},
    visible: false,
    image: ""
  }

  provider = dataProvider()

  componentDidMount() {
    this.getUser();
  }

  getUser = async () => {
    try {
      const { id } = this.props.match.params;
      if (!id) {
        this.props.history.push("/404");
      }
      const body = {
        filter: {
          include: "role",
        },
      };
      const user = await this.provider.fetch.get(`/myusers/${id}`, body, { noCache: true });
      if (user.emailVerified) {
        this.props.history.push(`/admin/users/${user.id}`);
      }
      this.setState({ user });
    } catch (error) {
      this.props.history.push("/404");
    }
  }

  validateUser = async () => {
    try {
      const body = {
        emailVerified: true,
      };
      await this.provider.fetch.patch(`/myusers/${this.state.user.id}`, body, { noCache: true });
      this.props.history.push("/admin/users/validate");
    } catch (error) {
      console.log(error);
    }
  }

  deleteUser = async () => {
    try {
      await this.provider.fetch.delete(`/myusers/${this.state.user.id}`, null, { noCache: true });
      this.props.history.push("/admin/users/validate");
    } catch (error) {
      console.log(error);
    }
  }

  handleCancel = () => this.setState({ visible: false, image: "" })

  showModal = (image) => {
    this.setState({ visible: true, image });
  }

  render() {
    const {
      license_image,
      professional_license_image,
      email,
      shop_name,
      license_number,
      address,
      full_name,
      mobile, phone,
    } = this.state.user;
    return (
      <Container>
        <Card style={{ width: 1200, textAlign: "center" }}>
          <Descriptions title="User Info" bordered>
            <Descriptions.Item label="ใบอนุญาตขายยา" span={3}>
              <div onClick={() => this.showModal(license_image)} style={{ cursor: "pointer" }}>
                <img src={license_image} height={200} alt="" />
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="ใบประกอบวิชาชีพ" span={3}>
              <div onClick={() => this.showModal(professional_license_image)} style={{ cursor: "pointer" }}>
                <img src={professional_license_image} height={200} alt="" />
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="อีเมล" span={3}>{email}</Descriptions.Item>
            <Descriptions.Item label="รา้น" span={3}>{shop_name}</Descriptions.Item>
            <Descriptions.Item label="เลขใบอนุญาตขายยา" span={3}>{license_number}</Descriptions.Item>
            <Descriptions.Item label="ที่อยู่" span={3}>{address}</Descriptions.Item>
            <Descriptions.Item label="ชื่อลูกค้า" span={3}>{full_name}</Descriptions.Item>
            <Descriptions.Item label="เบอร์มือถือ" span={3}>{mobile}</Descriptions.Item>
            <Descriptions.Item label="เบอร์ร้าน" span={3}>{phone}</Descriptions.Item>

          </Descriptions>
          <ButtonContainer>
            <Button onClick={this.props.history.goBack}>Back</Button>
            <Popconfirm
              title="ต้องการลบยูสเซอร์คนนี้ ?"
              onConfirm={this.deleteUser}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">
                Remove
              </Button>
            </Popconfirm>
            <Button type="primary" onClick={this.validateUser}>อนุญาตให้ยูสเซอร์นี้เข้าใช้งานระบบ</Button>
          </ButtonContainer>
        </Card>
        <Modal
          visible={this.state.visible}
          footer={null}
          onCancel={this.handleCancel}
          destroyOnClose
          width={1000}
        >
          <img
            alt="example"
            style={{ width: "100%", margin: 10 }}
            src={this.state.image}
          />
        </Modal>
      </Container>
    );
  }
}

AdminValidateUserScreen.propTypes = {

};

AdminValidateUserScreen.defaultProps = {

};
export default compose(
  withRouter,
)(AdminValidateUserScreen);
