/**
*
* NewsScreen
*
*/

import React, { Component } from "react";
import dataProvider from "utils/dataProvider";
import styled from "styled-components";
import { Typography, Card } from "antd";
import TextEditor from "components/TextEditor";

// import PropTypes from 'prop-types'

const Container = styled.div`

`;
const { Title } = Typography;

class NewsScreen extends Component { // eslint-disable-line
  state = {
    news: {}
  }

  provider = dataProvider()

  componentDidMount () {
    this.getnews();
  }

  getnews = async () => {
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/404");
    }
    const news = await this.provider.fetch.get(`/news/${id}`);
    this.setState({ news });
  }

  render() {
    return (
      <Container>
        <Title
          style={{
            background: "#fafafa",
            border: "1px solid #eaeaea",
            padding: 10,
            margin: 0
          }}
        >{this.state.news.title}
        </Title>
        <Card>
          {
            this.state.news.full_text && (
              <TextEditor readOnly defaultValue={this.state.news.full_text} />
            )
          }
        </Card>
      </Container>
    );
  }
}

NewsScreen.propTypes = {

};

NewsScreen.defaultProps = {

};
export default NewsScreen;
