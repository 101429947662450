/**
 *
 * CartScreen
 *
 */

import React, { Component } from "react";
import { isEqual } from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Typography,
  Icon,
  InputNumber,
  Row,
  Col,
  Button,
  Popconfirm,
} from "antd";
import Swal from "sweetalert2";
import { CustomTable } from "components/CustomTable";
// import PropTypes from 'prop-types'

const Container = styled.div``;
const SumPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c2c2c2;
  padding: 10px 10px 0 10px;
`;
const Amount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #8b8b8b;
  background-color: #f3ffde;
  padding: 10px 10px 0 10px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;
const { Text, Paragraph, Title } = Typography;

class CartScreen extends Component {
  // eslint-disable-line
  async componentDidMount() {
    const expire = await this.props.checkExpireOrder(this.props.items);
    if (expire.length > 0) {
      const list = expire.map((i) => `<li>${i.name}</li>`).join();
      Swal.fire({
        title: "<strong>โปรโมชั่นหมดอายุ</strong>",
        type: "info",
        html: `<h3>โปรโมชั่นของสินค้าต่อไปนี้ได้หมดอายุลงแล้ว</h3>
        <ol>${list}</ol>
        <p>รายการเหล่านี้จะถูกนำออกจากตะกร้าโดยอัตโนมัติ</p>`,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: "รับทราบ",
      });
    }
    this.props.getDeliveryCost(this.props.items);
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (!isEqual(this.props.items !== prevProps.items)) {
      await this.props.getDeliveryCost(this.props.items);
    }
  }

  changeQuantity = (e, record) => {
    if (Number(e)) {
      this.props.addItem({ ...record, quantity: Number(e) });
    }
  };

  onSubmit = async (payload) => {
    try {
      const { value: mobile } = await Swal.fire({
        title:
          "กรุณากรอกเบอร์โทรศัพท์ ที่ติดต่อได้ของท่านเพื่อใช้ในการจัดส่งสินค้า",
        input: "text",
        inputValue: this.props.userInfo.mobile,
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "กรุณากรอกเบอร์โทรของท่าน!";
          }
          return null;
        },
      });
      if (mobile) {
        await this.props.createOrderAsync({ ...payload, mobile });
        await Swal.fire({
          type: "success",
          text: "ทำการสั่งซื้อเรียบร้อย รอการยืนยันจากเจ้าหน้าที่สักครู่...",
        });
        this.props.history.push("/product");
      }
    } catch (error) {
      Swal.fire({
        type: "error",
        title: "Oops...!",
        text: "บางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง!",
      });
    }
  };

  render() {
    const columns = [
      {
        title: "ลำดับ",
        key: "id",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "รายละเอียดสินค้า",
        key: "product",
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: 20 }}>
              <img src={record.product_image} alt="" width={80} />
            </div>
            <div>
              <Paragraph>
                <Text>รหัสสินค้า: {record.code}</Text>
              </Paragraph>
              <Paragraph>
                <Text>{record.name}</Text>
              </Paragraph>
            </div>
          </div>
        ),
      },
      {
        title: "หน่วย",
        dataIndex: "productDetail.unit_price",
        key: "productDetail.unit_price",
      },
      {
        title: "ราคาต่อหน่วย(บาท)",
        dataIndex: "productDetail.price",
        key: "productDetail.price",
      },
      {
        title: "จำนวน",
        dataIndex: "quantity",
        key: "quantity",
        render: (text, record) => (
          <InputNumber
            style={{ width: 80 }}
            value={record.quantity}
            onChange={(e) => this.changeQuantity(e, record)}
          />
        ),
      },
      {
        title: "มูลค้าสินค้า",
        key: "amount",
        render: (text, record) => (
          <span>{record.quantity * Number(record.productDetail.price)}</span>
        ),
      },
      {
        title: "",
        key: "action",
        render: (text, record) => (
          <Popconfirm
            title="ต้องการนำสินค้าชิ้นนี้ออกจากตะกร้า ใช่หรือไม่?"
            onConfirm={() => this.props.deleteItem(record.productDetail.id)}
            onCancel={() => {}}
            okText="ใช่"
            cancelText="ยกเลิก"
            icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
          >
            <span>
              <Icon type="delete" style={{ fontSize: 24, cursor: "pointer" }} />
            </span>
          </Popconfirm>
        ),
      },
    ];
    const { items } = this.props;
    const itemList = Object.keys(items).map((key) => items[key]);
    const sumPrice = itemList.reduce((acc, cur) => {
      acc += cur.quantity * Number(cur.productDetail.price);
      return acc;
    }, 0);
    return (
      <Container>
        <CustomTable
          columns={columns}
          dataSource={itemList}
          rowKey={(record) => record.productDetail.id}
          pagination={false}
          scroll={{ x: 800 }}
        />
        <Row>
          <Col
            xs={24}
            lg={{
              span: 16,
              offset: 8,
            }}
            style={{
              padding: 20,
              background: "white",
              marginTop: 20,
              borderRadius: 20,
            }}
          >
            <SumPrice>
              <Title level={4}>ราคาสินค้า</Title>
              <Title level={4}>
                {`${sumPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Title>
            </SumPrice>
            <SumPrice>
              <Title level={4}>ค่าจัดส่ง</Title>
              <Title level={4}>
                {this.props.deliveryCost === null
                  ? "รอประเมินราคา"
                  : this.props.deliveryCost}
              </Title>
            </SumPrice>
            <Amount>
              <Title level={4} style={{ color: "#9AC949" }}>
                รวมทั้งหมด
              </Title>
              <Title level={4} style={{ color: "#9AC949" }}>
                {`${sumPrice + this.props.deliveryCost}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}{" "}
                บาท
              </Title>
            </Amount>
            <ButtonContainer>
              <Link to="/product">
                <Button type="primary" icon="plus">
                  เลือกสินค้าเพิ่ม
                </Button>
              </Link>
              <Button
                type="primary"
                onClick={() => this.onSubmit({ amount: sumPrice, itemList })}
              >
                สั่งสินค้า
                <Icon type="shopping" />
              </Button>
            </ButtonContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

CartScreen.propTypes = {};

CartScreen.defaultProps = {
  items: {},
};

const mapState = (state) => ({
  items: state.cart.items,
  userInfo: state.auth.userInfo,
  deliveryCost: state.cart.deliveryCost,
});
const mapDispatch = ({ cart }) => ({
  deleteItem: cart.deleteItem,
  addItem: cart.addItem,
  createOrderAsync: cart.createOrderAsync,
  checkExpireOrder: cart.checkExpireOrder,
  getDeliveryCost: cart.getDeliveryCost,
});
export default connect(mapState, mapDispatch)(CartScreen);
