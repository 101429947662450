/**
 *
 * AdminInComeOrderScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import dataProvider from "utils/dataProvider";
import { Table } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

// import PropTypes from 'prop-types'

const Container = styled.div``;

class AdminInComeOrderScreen extends Component {
  // eslint-disable-line
  state = {
    orders: [],
  };

  provider = dataProvider();

  componentDidMount() {
    this.fetchOrder();
  }

  fetchOrder = async (option = { noCache: true }) => {
    const filter = {
      include: ["orderItems", "myusers"],
      where: {
        status: "ordered",
      },
    };
    const orders = await this.provider.fetch.get("/orders", { filter }, option);
    this.setState({ orders });
  };

  render() {
    console.log(this.state.orders);
    const columns = [
      {
        title: "ลำดับ",
        key: "id",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "เลข PO",
        dataIndex: "orderCode",
        key: "orderCode",
      },
      {
        title: "ร้าน",
        dataIndex: "myusers.shop_name",
        key: "myusers.shop_name",
      },
      // orderCode

      {
        title: "จำนวนรายการ",
        key: "orderItemsLength",
        render: (text, record) => <span>{record.orderItems.length}</span>,
      },
      {
        title: "เวลาสั่ง",
        dataIndex: "createdAt",
        key: "create",
        render: (create) => (
          <span>
            {`${dayjs(create).format("YYYY-MM-DD")}(${dayjs(
              create
            ).fromNow()})`}
          </span>
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link to={`/admin/orders/incoming/${record.id}`}>ดูข้อมูล</Link>
          </span>
        ),
      },
    ];
    return (
      <Container>
        <Table
          columns={columns}
          dataSource={this.state.orders}
          rowKey={(record) => record.id}
          pagination={false}
        />
      </Container>
    );
  }
}

AdminInComeOrderScreen.propTypes = {};

AdminInComeOrderScreen.defaultProps = {};
export default AdminInComeOrderScreen;
