/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/**
 *
 * DashboardUnPaid
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import { Card, Icon, Button, Modal } from "antd";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { withRouter, Link } from "react-router-dom";
import dataProvider from "utils/dataProvider";
import { STATUS_NAME, PAYMENT_NAME } from "utils/constant";
import UploadMultiple from "components/UploadMultiple";
// import styles from './DashboardUnPaid.module.css'
// import PropTypes from 'prop-types'
import "dayjs/locale/th";
import { CustomTable } from "components/CustomTable";

const Container = styled.div``;

class DashboardUnPaid extends Component {
  // eslint-disable-line
  state = {
    myOrders: [],
    sumPrice: 0,
    currentRecord: {},
    showModal: false,
    slipImage: [],
  };

  provider = dataProvider();

  componentDidMount() {
    this.getOrders();
  }

  getOrders = async () => {
    const body = {
      filter: {
        where: {
          payment_status: "unpaid",
          status: {
            inq: ["ordered", "confirm", "pre_delivery", "sended"],
          },
        },
      },
    };
    const myOrders = await this.provider.fetch.get("/myusers/me/orders", body, {
      noCache: true,
    });
    const sumPrice = myOrders.reduce((acc, cur) => {
      acc += Number(cur.amount || 0);
      return acc;
    }, 0);
    this.setState({ myOrders, sumPrice });
  };

  handleOk = async () => {
    if (this.state.slipImage.length > 0) {
      try {
        const { currentRecord } = this.state;
        const data = {
          slipImage: this.state.slipImage.map((i) => i.url),
        };
        await this.provider.fetch.post(
          `/orders/${currentRecord.id}/payOrder`,
          data,
          { noCache: true }
        );
        await this.getOrders();
        this.setState({
          showModal: false,
          slipImage: [],
        });
      } catch (error) {
        Swal.fire({
          type: "error",
          title: "Oops...Server Error !",
          text: "ลองใหม่ภายหลัง",
        });
      }
    }
  };

  handleCancel = () => {
    this.setState({
      showModal: false,
      currentRecord: {},
      slipImage: [],
    });
  };

  render() {
    const columns = [
      {
        title: "ลำดับ",
        key: "id",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "เลขที่ใบสั่งซื้อ",
        dataIndex: "orderCode",
        key: "orderCode",
        render: (text, record) => (
          <Link to={`/my-order/${record.orderCode}`}>{record.orderCode}</Link>
        ),
      },
      {
        title: "เวลาสั่ง",
        dataIndex: "createdAt",
        key: "create",
        render: (create) => (
          <span>
            {`${dayjs(create).locale("th").format("DD-MMM-YYYY HH:mm")}`}
          </span>
        ),
      },
      {
        title: "เลขใบกำกับภาษี",
        key: "orderItemsLength",
        render: (text, record) => <span>{record.orderItems}</span>,
        width: 126,
      },
      {
        title: "ยอดสุทธิ",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "ใบส่งของ",
        dataIndex: "billing_image",
        key: "billing_image",
        width: 90,
        render: (text, record) =>
          record.billing_image.map((i, index) => (
            <a
              href={i}
              target="_blank"
              rel="noopener noreferrer"
              title={`ใบเสร็จ 0${index + 1}`}
              style={{ margin: 3 }}
              key={i}
            >
              <Icon type="file-text" />
            </a>
          )),
      },
      {
        title: "สถานะคำสั่งซื้อ",
        dataIndex: "status",
        key: "status",
        render: (text, record) => <span>{STATUS_NAME[record.status]}</span>,
      },
      {
        title: "สถานะชำระเงิน",
        dataIndex: "payment_status",
        key: "payment_status",
        render: (text, record) => {
          if (record.status === "ordered") {
            return <div>กำลังตรวจสอบใบสั่งซื้อ</div>;
          }
          return (
            <div style={{ textAlign: "center" }}>
              <div>{PAYMENT_NAME[record.payment_status]}</div>
              <div>
                <Button
                  onClick={() =>
                    this.setState({ currentRecord: record, showModal: true })
                  }
                >
                  <Icon type="upload" /> อัพโหลดสลิปโอนเงิน
                </Button>
              </div>
            </div>
          );
        },
      },
    ];
    return (
      <Card
        title={`รายการค้างชำระ ${this.state.sumPrice} บาท`}
        bodyStyle={{ padding: "0 8px" }}
      >
        <Modal
          title={
            <div>
              <div>ใบสั่งซื้อ: {this.state.currentRecord.orderCode}</div>
              <div>ยอดสุทธิ: {this.state.currentRecord.amount} บาท</div>
            </div>
          }
          visible={this.state.showModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          bodyStyle={{ width: "100%", maxWidth: 1200 }}
          destroyOnClose
          okText="ส่งสลิปโอนเงิน"
          cancelText="ยกเลิก"
          okButtonProps={{ disabled: !this.state.slipImage }}
        >
          <div style={{ textAlign: "center" }}>
            <UploadMultiple
              onUpload={(url) => this.setState({ slipImage: url })}
              maxFiles={2}
            />
          </div>
        </Modal>
        <Container>
          <CustomTable
            pagination={{
              pageSize: 5,
            }}
            columns={columns}
            dataSource={this.state.myOrders}
            rowKey={(record) => record.id}
            scroll={{ x: 800 }}
          />
        </Container>
      </Card>
    );
  }
}

DashboardUnPaid.propTypes = {};

DashboardUnPaid.defaultProps = {};
export default withRouter(DashboardUnPaid);
