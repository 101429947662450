/**
*
* AdminCategoryListScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
// import PropTypes from 'prop-types'
import dataProvider from "utils/dataProvider";
import { Link } from "react-router-dom";
import { Table } from "antd";

const Container = styled.div`

`;
class AdminCategoryListScreen extends Component { // eslint-disable-line
  state = {
    categories: [],
  }

  provider = dataProvider()

  componentDidMount () {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    const categories = await this.provider.fetch.get("/categories", null, { noCache: true });
    this.setState({ categories });
  }

  render () {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id"
      },
      {
        title: "ชื่อ",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "คิดCoin",
        dataIndex: "is_calculate_coin",
        key: "is_calculate_coin",
        render: (value) => {
          if (value) {
            return "คิด";
          }
          return "ไม่คิด";
        }
      },
      {
        title: "ดูรายละเอียด",
        key: "action",
        render: ((text, record) => (
          <span>
            <Link to={`/admin/categories/${record.id}`}>
              ดูข้อมูล
            </Link>
          </span>
        )),
      },

    ];
    return (
      <Container>
        <Table
          columns={columns}
          dataSource={this.state.categories}
          rowKey={record => record.id}
          pagination={{
            pageSize: 200,
            size: "small"
          }}
        />
      </Container>
    );
  }
}

AdminCategoryListScreen.propTypes = {

};

AdminCategoryListScreen.defaultProps = {

};
export default AdminCategoryListScreen;
