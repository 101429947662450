/**
*
* AdminCategoryListScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
// import PropTypes from 'prop-types'
import dataProvider from "utils/dataProvider";
import { Link } from "react-router-dom";
import { Table, Button } from "antd";

const Container = styled.div`

`;
class EmployerListScreen extends Component { // eslint-disable-line
  state = {
    employers: [],
  }

  provider = dataProvider()

  componentDidMount () {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    const employers = await this.provider.fetch.get("/employers", null, { noCache: true });
    this.setState({ employers });
  }

  render () {
    const columns = [
      {
        title: "ชื่อ",
        dataIndex: "first_name",
        key: "first_name",
      },
      {
        title: "นามสกุล",
        dataIndex: "last_name",
        key: "first_name",
      },
      {
        title: "รหัสพนักงาน",
        dataIndex: "employer_code",
        key: "employer_code",
      },
      {
        title: "แผนก",
        dataIndex: "department",
        key: "department",
      },
      {
        title: "เลขบัญชี",
        dataIndex: "bank_account",
        key: "bank_account",
      },
      {
        title: "ดูรายละเอียด",
        key: "action",
        render: ((text, record) => (
          <span>
            <Link to={`/slip/employer/${record.id}`}>
              ดูข้อมูล
            </Link>
          </span>
        )),
      },

    ];
    return (
      <Container>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link to="/slip/employer/create">
            <Button>เพิ่มพนักงานใหม่</Button>
          </Link>
        </div>
        <Table
          columns={columns}
          dataSource={this.state.employers}
          rowKey={record => record.id}
          pagination={{
            pageSize: 200,
            size: "small"
          }}
        />
      </Container>
    );
  }
}

EmployerListScreen.propTypes = {

};

EmployerListScreen.defaultProps = {

};
export default EmployerListScreen;
