/**
 *
 * AdminOrderDetailScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import { Typography, Card, Modal, Row, Col, Table, Steps, Button } from "antd";
import { FaShippingFast, FaBox } from "react-icons/fa";
import dataProvider from "utils/dataProvider";
import _get from "lodash/get";
import { TRANSPOT_NAME, PAYMENT_NAME, PAYMENT_COLOR } from "utils/constant";
import UploadMultiple from "components/UploadMultiple";
import Swal from "sweetalert2";

// import PropTypes from 'prop-types'
const { Text, Title, Paragraph } = Typography;
const { Step } = Steps;
const Container = styled.div`
  background: white;
  border-radius: 20px;
  padding: 0 20px;
`;
const AddressTitile = styled(Title)`
  margin-top: 10px !important ;
`;
const SumPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #999;
  padding: 10px 10px 0 10px;
`;
const Amount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #999;
  background-color: #f3ffde;
  color: #9ac949 !important;
  padding: 10px 10px 0 10px;
`;

class AdminUnpaidpaymentScreen extends Component {
  // eslint-disable-line
  state = {
    order: {},
    previewBill: false,
    previewSlip: false,
    showUploadModal: false,
    slipImage: [],
  };

  provider = dataProvider();

  componentDidMount() {
    this.getOrder();
  }

  getOrder = async () => {
    try {
      const { id } = this.props.match.params;
      if (!id) {
        this.props.history.push("/404");
      }
      const body = {
        filter: {
          include: [
            "myusers",
            {
              relation: "orderItems",
              scope: {
                include: [
                  "promotions",
                  {
                    relation: "products",
                    scope: {
                      isDeleted: true,
                    },
                  },
                ],
              },
            },
          ],
        },
      };
      const order = await this.provider.fetch.get(`/orders/${id}`, body, {
        noCache: true,
      });
      this.setState({ order });
    } catch (error) {
      this.props.history.push("/404");
    }
  };

  handleCancel = () => this.setState({ previewBill: false });

  handleCancelSlip = () => this.setState({ previewSlip: false });

  handleOk = async () => {
    if (this.state.slipImage.length > 0) {
      try {
        const { order } = this.state;
        const data = {
          slipImage: this.state.slipImage.map((i) => i.url),
        };
        await this.provider.fetch.post(`/orders/${order.id}/payOrder`, data, {
          noCache: true,
        });
        await this.getOrder();
        this.setState({
          showUploadModal: false,
          slipImage: [],
        });
      } catch (error) {
        Swal.fire({
          type: "error",
          title: "Oops...Server Error !",
          text: "ลองใหม่ภายหลัง",
        });
      }
    }
  };

  render() {
    const { order, previewBill, previewSlip } = this.state;
    const hasBill = _get(order, "billing_image", []).length > 0;
    const hasSlip = _get(order, "slip_image", []).length > 0;
    const currentStep =
      1 +
      ["ordered", "confirm", "pre_delivery", "sended"].findIndex(
        (i) => i === order.status
      );
    const user = order.myusers || {};
    const sumPrice = order.amount;
    const columns = [
      {
        title: "ลำดับ",
        key: "id",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "รายละเอียดสินค้า",
        key: "product",
        dataIndex: "product_name",
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {_get(record, "promotions.product_image") ? (
              <div style={{ marginRight: 20 }}>
                <img src={record.promotions.product_image} alt="" width={80} />
              </div>
            ) : (
              <div style={{ marginRight: 20 }}>
                <img src={record.products.product_image} alt="" width={80} />
              </div>
            )}
            <Paragraph>
              <Text>{record.product_name}</Text>
            </Paragraph>
          </div>
        ),
      },
      {
        title: "หน่วย",
        dataIndex: "product_unit",
        key: "product_unit",
      },
      {
        title: "ราคาต่อหน่วย(บาท)",
        dataIndex: "product_price",
        key: "productDetail.price",
      },
      {
        title: "จำนวน",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "มูลค่ารวม",
        key: "amount",
        render: (text, record) => (
          <span>{record.quantity * Number(record.product_price)}</span>
        ),
      },
    ];

    return (
      <Container>
        <Title
          style={{
            textAlign: "center",
            background: "white",
            padding: 10,
            margin: 0,
          }}
          level={2}
        >
          รายการสั่งซื้อ
        </Title>
        <Title
          level={2}
          style={{
            padding: 10,
            margin: 0,
            background: "white",
            border: "1px solid #dadada",
          }}
        >
          เลขใบสั่งซื้อ: {order.orderCode}
        </Title>
        <Card style={{ width: "100%" }}>
          <div>
            <Row>
              <Col xs={24} md={18}>
                <AddressTitile level={4}>
                  ชื่อร้านค้า : {user.shop_name}
                </AddressTitile>
                <AddressTitile level={4}>
                  ชื่อลูกค้า : {user.full_name}
                </AddressTitile>
                <AddressTitile level={4}>
                  เบอร์โทรศัพท์ : {user.phone} เบอร์มือถือ : {user.mobile}
                </AddressTitile>
                <AddressTitile level={4}>
                  ที่อยู่ : {user.address}
                </AddressTitile>
              </Col>
              <Col xs={24} md={6} style={{ margin: "auto" }}>
                <Title level={4} style={{ color: "#888" }}>
                  สถานะชำระเงิน
                </Title>
                <p style={{ color: PAYMENT_COLOR[order.payment_status] }}>
                  {PAYMENT_NAME[order.payment_status]}
                </p>
                {order.payment_status === "unpaid" ? (
                  <Button
                    onClick={() => this.setState({ showUploadModal: true })}
                  >
                    อัพโหลดสลิปโอนเงิน
                  </Button>
                ) : (
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ previewSlip: true })}
                  >
                    สำเนาการโอนเงิน
                  </u>
                )}
              </Col>
            </Row>

            <Card style={{ width: "100%", padding: "10px 0" }}>
              <Steps size="small" current={currentStep}>
                <Step title="สั่งซื้อแล้ว" />
                <Step title="ยืนยันออเดอร์" />
                <Step title="เตรียมพร้อมจัดส่ง" />
                <Step title="จัดส่งเรียบร้อย" />
              </Steps>
            </Card>

            <Card
              style={{ width: "100%", padding: "5px 0", background: "#eaeaea" }}
            >
              <Row type="flex" style={{ alignItems: "center" }}>
                <Col
                  style={{ textAlign: "center", margin: "10px 0" }}
                  md={8}
                  xs={24}
                >
                  <Title level={4} style={{ color: "#888" }}>
                    สถานะชำระเงิน
                  </Title>
                  <p style={{ color: PAYMENT_COLOR[order.payment_status] }}>
                    {PAYMENT_NAME[order.payment_status]}
                  </p>
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ previewSlip: true })}
                  >
                    สำเนาการโอนเงิน
                  </u>
                </Col>
                <Col
                  style={{ textAlign: "center", margin: "10px 0" }}
                  md={8}
                  xs={24}
                >
                  <Title level={4} style={{ color: "#888" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaShippingFast
                        style={{ marginRight: 10, fontSize: 20 }}
                      />
                      ขนส่ง: {TRANSPOT_NAME[order.transpot] || order.transpot}
                    </div>
                  </Title>
                  <Title level={4} style={{ color: "#888" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaBox style={{ marginRight: 10, fontSize: 20 }} />
                      Tracking No.: {order.tracking_number}
                    </div>
                  </Title>
                </Col>
                {hasBill && (
                  <Col
                    style={{ textAlign: "center", margin: "10px 0" }}
                    md={8}
                    xs={24}
                  >
                    <u
                      // level={4}
                      style={{ fontSize: 18, cursor: "pointer", color: "#888" }}
                      onClick={() => this.setState({ previewBill: true })}
                    >
                      คลิกที่นี่เพื่อดูใบส่งสินค้า
                    </u>
                  </Col>
                )}
              </Row>
            </Card>

            <Table
              columns={columns}
              dataSource={_get(order, "orderItems", []).sort(
                (a, b) => a.productId - b.productId
              )}
              rowKey={(record) => record.id}
              pagination={false}
              scroll={{ x: 800 }}
            />
            <Row>
              <Col
                xs={24}
                lg={{
                  span: 16,
                  offset: 8,
                }}
                style={{ padding: 20 }}
              >
                <SumPrice>
                  <Title level={4}>ราคาสินค้า</Title>
                  <Text level={4}>
                    {`${sumPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </SumPrice>
                <SumPrice>
                  <Title level={4}>ค่าจัดส่ง</Title>
                  <Text level={4}>
                    {`${order.delivery_cost || 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}
                  </Text>
                </SumPrice>
                <Amount>
                  <Title level={4}>รวมทั้งหมด</Title>
                  <Text level={4}>
                    {`${order.total_cost || 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}{" "}
                    บาท
                  </Text>
                </Amount>
              </Col>
            </Row>
          </div>
        </Card>
        <Modal
          visible={previewBill}
          footer={null}
          onCancel={this.handleCancel}
          destroyOnClose
        >
          {hasBill &&
            order.billing_image.map((i, index) => (
              <img
                alt={`example${index}`}
                style={{ width: "100%", margin: 10 }}
                src={i}
                key={i}
              />
            ))}
        </Modal>
        <Modal
          visible={previewSlip}
          footer={null}
          onCancel={this.handleCancelSlip}
          destroyOnClose
        >
          {hasSlip &&
            order.slip_image.map((i, index) => (
              <img
                alt={`example${index}`}
                style={{ width: "100%", margin: 10 }}
                src={i}
                key={i}
              />
            ))}
        </Modal>
        <Modal
          title={
            <div>
              <div>ใบสั่งซื้อ: {this.state.order.orderCode}</div>
              <div>ยอดสุทธิ: {this.state.order.amount} บาท</div>
            </div>
          }
          visible={this.state.showUploadModal}
          onOk={this.handleOk}
          onCancel={() => this.setState({ showUploadModal: false })}
          bodyStyle={{ width: "100%", maxWidth: 1200 }}
          destroyOnClose
          okText="ส่งสลิปโอนเงิน"
          cancelText="ยกเลิก"
          okButtonProps={{ disabled: !this.state.slipImage }}
        >
          <div style={{ textAlign: "center" }}>
            <UploadMultiple
              onUpload={(url) => this.setState({ slipImage: url })}
              maxFiles={2}
            />
          </div>
        </Modal>
      </Container>
    );
  }
}

AdminUnpaidpaymentScreen.propTypes = {};

AdminUnpaidpaymentScreen.defaultProps = {};
export default AdminUnpaidpaymentScreen;
