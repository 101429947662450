/**
*
* TextEditor
*
*/

import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6
import "react-quill/dist/quill.bubble.css";

const formats = [
  "header", "font", "size",
  "bold", "italic", "underline", "strike", "blockquote",
  "list", "bullet", "indent",
  "link", "image", "video"
];
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" },
      { indent: "-1" }, { indent: "+1" }],
    ["link", "image", "video"],
    ["clean"]
  ],
  clipboard: {
    matchVisual: false
  }
};

function Editor (props) {
  const [editorHtml, setHtml] = useState("");
  useEffect(() => {
    setHtml(props.defaultValue || "");
  }, []);
  const onChange = (html) => {
    setHtml(html);
    props.onChange(html);
  };
  return (
    <div>
      <ReactQuill
        theme={props.readOnly ? "bubble" : "snow"}
        onChange={onChange}
        value={editorHtml}
        modules={modules}
        formats={formats}
        bounds=".app"
        placeholder={props.placeholder || ""}
        readOnly={props.readOnly}
      />
    </div>
  );
}
Editor.defaultProps = {
  onChange: () => {},
  placeholder: "",
  readOnly: false
};

export default Editor;
