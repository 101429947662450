/**
 *
 * AdminCreateProductScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Card, message } from "antd";
import dataProvider from "utils/dataProvider";

import BannerForm from "components/BannerForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class AdminBannerCreateScreen extends Component {
  // eslint-disable-line

  provider = dataProvider();

  createBanner = async (values) => {
    try {
      await this.provider.fetch.post(
        "/banners",
        {
          ...values,
        },
        { noCache: true }
      );
      message.success("create banner success");
      this.props.history.push("/admin/banners");
    } catch (ex) {
      const resErr = _.get(ex, "response");
      const msg = _.get(resErr, "data.error.message");
      if (resErr.status === 422) {
        message.error(msg);
      } else {
        message.error("มีข้อผิดพลาดเกิดขึ้น");
      }
    }
  };

  render() {
    return (
      <Container>
        <Card style={{ width: 600, textAlign: "center" }}>
          <BannerForm onSubmit={this.createBanner} />
        </Card>
      </Container>
    );
  }
}

AdminBannerCreateScreen.propTypes = {};

AdminBannerCreateScreen.defaultProps = {};
export default compose(withRouter)(AdminBannerCreateScreen);
