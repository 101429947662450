/**
*
* DashboardNews
*
*/

import React, { Component } from "react";
import dataProvider from "utils/dataProvider";
import styled from "styled-components";
import { List, Typography, Card } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
// import styles from './DashboardNews.module.css'
// import PropTypes from 'prop-types'
import "dayjs/locale/th";

const Container = styled.div`

`;
const { Title, Paragraph } = Typography;
class DashboardNews extends Component { // eslint-disable-line
  state = {
    newsList: []
  }

  provider = dataProvider()

  componentDidMount () {
    this.getnews();
  }

  getnews = async () => {
    const news = await this.provider.fetch.get("/news", null, { noCache: true });
    this.setState({ newsList: news });
  }

  render() {
    return (
      <Card
        title="ข่าวสาร"
      >
        <Container>
          <List
            pagination={{
              pageSize: 5,
              size: "small"
            }}
            itemLayout="horizontal"
            dataSource={this.state.newsList}
            renderItem={item => (
              <Link to={`/news/${item.id}`}>
                <List.Item>
                  <List.Item.Meta
                    title={<Paragraph ellipsis><Title level={4} style={{ marginBottom: 0 }}>{item.title}</Title></Paragraph>}
                  />
                  <div style={{ color: "#999" }}>
                    {`${dayjs(item.createdAt).locale("th").format("DD MMM YYYY HH:mm น.")}`}
                  </div>
                </List.Item>
              </Link>
            )}
          />
        </Container>
      </Card>
    );
  }
}

DashboardNews.propTypes = {

};

DashboardNews.defaultProps = {

};
export default DashboardNews;
