/**
*
* AdminUserListScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
// import PropTypes from 'prop-types'
import dayjs from "dayjs";
import _get from "lodash/get";
import relativeTime from "dayjs/plugin/relativeTime";
import dataProvider from "utils/dataProvider";
import { Link } from "react-router-dom";
import { Table, Input, Button, Icon } from "antd";
import Highlighter from "react-highlight-words";

dayjs.extend(relativeTime);

const Container = styled.div`

`;
const RefreshContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Refresh = styled.button`
  margin-right: 10px
`;

class AdminUserListScreen extends Component { // eslint-disable-line
  state = {
    userList: [],
    searchText: "",
  }

  provider = dataProvider()


  componentDidMount() {
    this.fetchUser();
  }

  fetchUser = async () => {
    const filter = {
      where: {
        name: "user"
      },
      include: {
        relation: "myusers",
        scope: {
          where: {
            emailVerified: true,
          },
        },
      },
    };
    const userList = await this.provider.fetch.get("/roles", { filter }, { noCache: true });
    this.setState({ userList: userList[0].myusers });
  }

  getColumnSearchProps = (dataIndex, render) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => _get(record, dataIndex, "")?.toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: typeof render === "function" ? render : create => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={`${create}`}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  }

  render() {
    const columns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...this.getColumnSearchProps("email"),
      },
      {
        title: "ชื่อลูกค้า",
        dataIndex: "full_name",
        key: "full_name",
        ...this.getColumnSearchProps("full_name"),
      },
      {
        title: "ร้าน",
        dataIndex: "shop_name",
        key: "shop_name",
        ...this.getColumnSearchProps("shop_name"),
      },
      {
        title: "ที่อยู่",
        dataIndex: "address",
        key: "address",
        ...this.getColumnSearchProps("address"),
      },
      {
        title: "เบอร์โทร",
        dataIndex: "mobile",
        key: "mobile",
        ...this.getColumnSearchProps("mobile"),
      },
      {
        title: "เหรียญ",
        dataIndex: "coin",
        key: "coin",
        sorter: (a, b) => a.coin - b.coin,
      },
      {
        title: "สมัครวันที่",
        dataIndex: "createdAt",
        key: "create",
        sorter: (a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateA.getTime() - dateB.getTime();
        },
        ...this.getColumnSearchProps("createdAt", create => (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={`${dayjs(create).format("YYYY-MM-DD")}(${dayjs(create).fromNow()})`}
          />
        )),
      },
      {
        title: "Action",
        key: "action",
        render: ((text, record) => (
          <span>
            <Link to={`/admin/users/${record.id}`}>
              ดูข้อมูล
            </Link>
          </span>
        )),
      },

    ];
    return (
      <Container>
        <RefreshContainer>
          <Refresh onClick={() => this.fetchUser()}>Refresh</Refresh>
        </RefreshContainer>
        <Table columns={columns} dataSource={this.state.userList} rowKey={record => record.id} />
      </Container>
    );
  }
}

AdminUserListScreen.propTypes = {

};

AdminUserListScreen.defaultProps = {

};
export default AdminUserListScreen;
