/**
*
* AdminEditProductScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Card, message } from "antd";
import dataProvider from "utils/dataProvider";
// import _isEmpty from 'lodash/isEmpty'
// import _get from 'lodash/get'
import EmployerForm from "../../../components/EmployerForm";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class EditEmployerScreen extends Component { // eslint-disable-line
  state = {
    employer: {},
  }

  provider = dataProvider()


  componentDidMount() {
    this.getEmployer();
  }

  getEmployer = async () => {
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/404");
    }
    const employer = await this.provider.fetch.get(`/employers/${id}`, null, { noCache: true });
    this.setState({ employer });
  }

  updateEmployer = async (values) => {
    try {
      const { id } = this.props.match.params;
      await this.provider.fetch.patch(`/employers/${id}`, values, { noCache: true });
      message.success("update product success");
      this.props.history.goBack();
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  deleteEmployer = async () => {
    try {
      const { id } = this.props.match.params;
      await this.provider.fetch.delete(`/employers/${id}`, null, { noCache: true });
      message.success("delete product success");
      this.props.history.goBack();
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  render() {
    return (
      <Container>
        <Card style={{ width: 600, textAlign: "center" }}>
          <EmployerForm
            onSubmit={this.updateEmployer}
            employer={this.state.employer}
            onDelete={this.deleteEmployer}
          />
        </Card>
      </Container>
    );
  }
}

EditEmployerScreen.propTypes = {

};

EditEmployerScreen.defaultProps = {

};
export default compose(
  withRouter,
)(EditEmployerScreen);
