import React from "react";
import { compose } from "recompose";
import { Input, Form, Button, DatePicker, Popconfirm } from "antd";
import moment from "moment";
import _get from "lodash/get";
import Upload from "components/Upload";
import UploadImage from "components/UploadWithoutWatermark";

const { RangePicker } = DatePicker;

class PromotionForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const { promotion } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          price_main,
          price_secondary,
          price_third,
          unit_price_main,
          unit_price_secondary,
          unit_price_third,
        } = values;
        const [active_date, end_date] = values["range-picker"];
        const productDetails = [];
        if (values.price_main) {
          delete values.price_main;
          delete values.unit_price_main;
          productDetails.push({
            id: _get(promotion, "productDetail[0].id"),
            price: price_main,
            unit_price: unit_price_main,
          });
        }
        if (values.price_secondary) {
          delete values.price_secondary;
          delete values.unit_price_secondary;
          productDetails.push({
            id: _get(promotion, "productDetail[1].id"),
            price: price_secondary,
            unit_price: unit_price_secondary,
          });
        }
        if (values.price_third) {
          delete values.price_third;
          delete values.unit_price_third;
          productDetails.push({
            id: _get(promotion, "productDetail[2].id"),
            price: price_third,
            unit_price: unit_price_third,
          });
        }
        this.props.onSubmit({
          active_date,
          end_date,
          productDetails,
          ...values,
        });
      }
    });
  };

  onUploadImage = (url, type) => {
    if (type === "product_image") {
      this.props.form.setFieldsValue({
        product_image: url,
      });
    }
    if (type === "promote_image") {
      this.props.form.setFieldsValue({
        promote_image: url,
      });
    }
  };

  render() {
    const {
      form: { getFieldDecorator },
      promotion = {},
      deletePromotion,
    } = this.props;
    const {
      name,
      productDetail,
      active_date,
      end_date,
      product_image,
      promote_image,
      promote_desc,
    } = promotion;
    const rangeConfig = {
      rules: [{ type: "array", required: true, message: "กรุณาเลือกช่วงเวลา" }],
      initialValue: [moment(active_date), moment(end_date)],
    };
    return (
      <>
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12 }}
          onSubmit={this.handleSubmit}
        >
          <Form.Item label="รูปสินค้า">
            {getFieldDecorator("product_image", {
              rules: [],
            })(<Input style={{ display: "none" }} />)}
            <Upload
              onUpload={(url) => this.onUploadImage(url, "product_image")}
              defaultImage={product_image}
            />
          </Form.Item>
          <Form.Item label="ชื่อสินค้า">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "กรุณากรอกชื่อสินค้า" }],
              initialValue: name,
            })(<Input />)}
          </Form.Item>
          <div>
            <Form.Item label="ราคาหลัก">
              {getFieldDecorator("price_main", {
                rules: [{ required: true, message: "กรุณากรอกราคาหลัก" }],
                initialValue: _get(productDetail, "[0].price"),
              })(<Input />)}
            </Form.Item>
            <Form.Item label="หน่วยของราคาหลัก">
              {getFieldDecorator("unit_price_main", {
                rules: [
                  { required: true, message: "กรุณากรอกหน่วยของราคาหลัก" },
                ],
                initialValue: _get(productDetail, "[0].unit_price"),
              })(<Input />)}
            </Form.Item>
          </div>
          <Form.Item label="ราคาที่สอง">
            {getFieldDecorator("price_secondary", {
              initialValue: _get(productDetail, "[1].price"),
            })(<Input />)}
          </Form.Item>
          <Form.Item label="หน่วย">
            {getFieldDecorator("unit_price_secondary", {
              initialValue: _get(productDetail, "[1].unit_price"),
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ราคาที่สาม">
            {getFieldDecorator("price_third", {
              initialValue: _get(productDetail, "[2].price"),
            })(<Input />)}
          </Form.Item>
          <Form.Item label="หน่วย">
            {getFieldDecorator("unit_price_third", {
              initialValue: _get(productDetail, "[2].unit_price"),
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ช่วงเวลา">
            {getFieldDecorator(
              "range-picker",
              rangeConfig
            )(
              <RangePicker
                showTime={{ format: "HH:mm" }}
                format="DD/MM/YYYY HH:mm"
              />
            )}
          </Form.Item>
          <Form.Item label="รูปโปรโมท">
            {getFieldDecorator("promote_image", {
              rules: [],
            })(<Input style={{ display: "none" }} />)}
            <UploadImage
              onUpload={(url) => this.onUploadImage(url, "promote_image")}
              defaultImage={promote_image}
            />
          </Form.Item>
          <Form.Item label="คำฮธิบายโปรโมท">
            {getFieldDecorator("promote_desc", {
              initialValue: promote_desc,
            })(<Input />)}
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "20px" }}
            >
              บันทึก
            </Button>
            {deletePromotion && (
              <Popconfirm
                title="ต้องการลบโปรโมชั่นนี้ใช่หรอไม่?"
                onConfirm={deletePromotion}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">ลบ</Button>
              </Popconfirm>
            )}
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default compose(Form.create())(PromotionForm);
