/* eslint-disable no-console */
/**
 *
 * AdminConfirmOrderDetailScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import _get from "lodash/get";
import Swal from "sweetalert2";
import { Table, Typography, Row, Col, Button, Card, Modal } from "antd";
import UploadMultiple from "components/UploadMultiple";
import dataProvider from "utils/dataProvider";
import { PAYMENT_COLOR, PAYMENT_NAME } from "utils/constant";

// import PropTypes from 'prop-types'
const { Text, Title, Paragraph } = Typography;

const Container = styled.div``;

const AddressTitile = styled(Title)`
  margin-top: 10px !important ;
`;
const SumPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #999;
  padding: 10px 10px 0 10px;
`;
const Amount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #999;
  background-color: #3e3735;
  padding: 10px 10px 0 10px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
`;
const ActionContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
`;

class AdminConfirmOrderDetailScreen extends Component {
  // eslint-disable-line
  state = {
    items: [],
    order: {},
    billImage: [],
    previewSlip: false,
  };

  provider = dataProvider();

  componentDidMount() {
    this.fetchOrder();
  }

  fetchOrder = async () => {
    const orderId = this.props.match.params.id;
    const body = {
      filter: {
        include: [
          "myusers",
          {
            relation: "orderItems",
            scope: {
              include: [
                "promotions",
                {
                  relation: "products",
                  scope: {
                    isDeleted: true,
                  },
                },
              ],
            },
          },
        ],
      },
    };
    const order = await this.provider.fetch.get(`/orders/${orderId}`, body, {
      noCache: true,
    });
    this.setState({
      items: order.orderItems,
      order,
      billImage: order.slip_image,
    });
  };

  billingOrder = async (id) => {
    try {
      const billImage = this.state.billImage.map((bill) => bill.url);
      await this.provider.fetch.post(
        `/orders/${id}/BillingOrder`,
        { billImage },
        { noCache: true }
      );
      this.props.history.push("/admin/orders/confirm");
    } catch (error) {
      if (
        _get(error, "response.data.error.message") ===
        "billImage is a required argument"
      ) {
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "ต้องอัพโหลดใบเสร็จก่อน",
        });
      }
      console.error(error);
    }
  };

  render() {
    const { order, items: itemList } = this.state;
    const hasSlip = _get(order, "slip_image", []).length > 0;
    const sumPrice = itemList.reduce((acc, cur) => {
      acc += cur.quantity * Number(cur.product_price);
      return acc;
    }, 0);
    const user = this.state.order.myusers || {};
    const columns = [
      {
        title: "ลำดับ",
        key: "id",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "รายละเอียดสินค้า",
        key: "product",
        dataIndex: "product_name",
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {_get(record, "promotions.product_image") ? (
              <div style={{ marginRight: 20 }}>
                <img src={record.promotions.product_image} alt="" width={80} />
              </div>
            ) : (
              <div style={{ marginRight: 20 }}>
                <img src={record.products.product_image} alt="" width={80} />
              </div>
            )}
            <Paragraph>
              <Text>{record.product_name}</Text>
            </Paragraph>
          </div>
        ),
      },
      {
        title: "หน่วย",
        dataIndex: "product_unit",
        key: "product_unit",
      },
      {
        title: "ราคาต่อหน่วย(บาท)",
        dataIndex: "product_price",
        key: "productDetail.price",
      },
      {
        title: "จำนวน",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "มูลค้าสินค้า",
        key: "amount",
        render: (text, record) => (
          <span>{record.quantity * Number(record.product_price)}</span>
        ),
      },
    ];

    return (
      <Container>
        <div>
          <Card style={{ marginBottom: 10 }}>
            <Row>
              <Col xs={24} md={18}>
                <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
                  <AddressTitile level={4}>
                    ชื่อร้านค้า : {user.shop_name}
                  </AddressTitile>
                </Paragraph>
                <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
                  <AddressTitile level={4}>
                    ชื่อลูกค้า : {user.full_name}
                  </AddressTitile>
                </Paragraph>
                <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
                  <AddressTitile level={4}>
                    เบอร์โทรศัพท์ : {user.phone} เบอร์มือถือ : {user.mobile}
                  </AddressTitile>
                </Paragraph>
                <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
                  <AddressTitile level={4}>
                    ที่อยู่ : {user.address}
                  </AddressTitile>
                </Paragraph>
              </Col>
            </Row>
          </Card>
          <Card
            style={{ width: "100%", padding: "5px 0", background: "#eaeaea" }}
          >
            <Row type="flex" style={{ alignItems: "center" }}>
              <Col
                style={{ textAlign: "center", margin: "10px 0" }}
                md={8}
                xs={24}
              >
                <Title level={4} style={{ color: "#888" }}>
                  สถานะชำระเงิน
                </Title>
                <p style={{ color: PAYMENT_COLOR[order.payment_status] }}>
                  {PAYMENT_NAME[order.payment_status]}
                </p>
                <u
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ previewSlip: true })}
                >
                  สำเนาการโอนเงิน
                </u>
              </Col>
            </Row>
          </Card>
          <ButtonContainer>
            <div>
              <div>อัพโหลดใบเสร็จ</div>
              <UploadMultiple
                onUpload={(url) => this.setState({ billImage: url })}
              />
            </div>
          </ButtonContainer>
          <Table
            columns={columns}
            dataSource={itemList}
            rowKey={(record) => record.id}
            pagination={false}
          />
          <Row>
            <Col
              xs={24}
              lg={{
                span: 16,
                offset: 8,
              }}
              style={{ padding: 20 }}
            >
              <SumPrice>
                <Title level={4}>ราคาสินค้า</Title>
                <Text level={4}>
                  {`${sumPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
              </SumPrice>
              <SumPrice>
                <Title level={4}>ค่าจัดส่ง</Title>
                <Text level={4}>
                  {`${order.delivery_cost || 0}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )}
                </Text>
              </SumPrice>
              <Amount>
                <Title level={4} style={{ color: "white" }}>
                  รวมทั้งหมด
                </Title>
                <Text level={4} style={{ color: "white" }}>
                  {`${order.total_cost || 0}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )}{" "}
                  บาท
                </Text>
              </Amount>
            </Col>
          </Row>
        </div>
        <ActionContainer>
          <div>
            <Button>BACK</Button>
          </div>
          <div>
            <Button type="primary" onClick={() => this.billingOrder(order.id)}>
              CONFIRM
            </Button>
          </div>
        </ActionContainer>
        <Modal
          visible={this.state.previewSlip}
          footer={null}
          onCancel={() => this.setState({ previewSlip: false })}
          destroyOnClose
        >
          {hasSlip &&
            order.slip_image.map((i, index) => (
              <img
                alt={`example${index}`}
                style={{ width: "100%", margin: 10 }}
                src={i}
                key={i}
              />
            ))}
        </Modal>
      </Container>
    );
  }
}

AdminConfirmOrderDetailScreen.propTypes = {};

AdminConfirmOrderDetailScreen.defaultProps = {};
export default AdminConfirmOrderDetailScreen;
