/**
*
* PrivateRoute
*
*/

import React from "react";
import { Route, Redirect } from "react-router-dom";
// import styles from './PrivateRoute.module.css'
import PropTypes from "prop-types";
import _get from "lodash/get";
import { connect } from "react-redux";

function PrivateRoute({ component: Component, userInfo, isFetchProfile, isAuth, role = [], ...rest }) {
  if (!isFetchProfile) {
    return null;
  }
  if (!isAuth) {
    localStorage.accessToken = undefined;
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
  const userRole = _get(userInfo, "role.name");
  let isPermission;
  if (userRole === "admin") {
    isPermission = true;
  } else if (role.length === 0) {
    isPermission = true;
  } else {
    isPermission = role.includes(userRole);
  }

  return (
    <Route
      {...rest}
      render={props => isPermission ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any, // eslint-disable-line
  location: PropTypes.shape({

  }).isRequired,
};

const mapState = ({ auth }) => ({
  userInfo: auth.userInfo,
  isFetchProfile: auth.isFetchProfile,
  isAuth: auth.isAuth,
});

export default connect(mapState)(PrivateRoute);
