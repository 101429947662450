/* eslint-disable max-len */
/**
 *
 * AdminLayout
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import bg from "image/bg1.jpeg";
import styled from "styled-components";
import { FaShoppingCart, FaSignOutAlt } from "react-icons/fa";
import { Layout, Menu, Row, Col, Typography, Badge } from "antd";
import { withRouter, Link } from "react-router-dom";
import logo from "image/logo.png";

import { UserRouter, UserBreadcrumb, UserMenu } from "./Helper";

const { Header } = Layout;
const Logo = styled("div")`
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (min-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const { Text } = Typography;
const { Content, Footer, Sider } = Layout;
// const SubMenu = Menu.SubMenu;

const CustomFooter = styled(Footer)`
  @media (max-width: 767px) {
    padding: 8px !important;
  }
`;

const CustomHeader = styled(Header)`
  height: auto !important;
  padding: 8px !important;
  @media (min-width: 767px) {
    padding: 0 50px !important;
  }
`;

export class AdminLayout extends React.Component {
  state = {
    // collapsed: false,
  };

  handleLogout = () => {
    // this.props.dispatch(logout())
  };

  // handleCollapse = (collapsed) => {
  //   this.setState({ collapsed });
  // };

  render() {
    const currentPath = this.props.location.pathname;
    return (
      <Layout
        style={{
          height: "100vh",
          overflowY: "auto",
          backgroundImage: `url("${bg}")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <CustomHeader>
          <Row type="flex" justify="space-between" align="center">
            <Col
              style={{ display: "flex", alignItems: "center" }}
              xs={{
                span: 12,
                order: 1,
              }}
              sm={{
                span: 12,
                order: 1,
              }}
              md={{
                span: 8,
                order: 1,
              }}
              lg={{
                span: 3,
                order: 1,
              }}
            >
              <Link to="/">
                <Logo>
                  <img src={logo} alt="logo" width={140} />
                </Logo>
              </Link>
            </Col>
            <Col
              style={{ display: "flex", alignItems: "center" }}
              xs={{
                span: 24,
                order: 3,
              }}
              sm={{
                span: 24,
                order: 3,
              }}
              md={{
                span: 8,
                order: 2,
              }}
              lg={{
                span: 9,
                order: 2,
              }}
            />
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              xs={{
                span: 12,
                order: 2,
              }}
              sm={{
                span: 12,
                order: 2,
              }}
              md={{
                span: 8,
                order: 3,
              }}
              lg={{
                span: 12,
                order: 3,
              }}
            >
              <Logo>
                <Text style={{ fontSize: 20, color: "white", marginRight: 30 }}>
                  {this.props.userInfo.full_name}
                </Text>
              </Logo>
              <Link to="/cart">
                <Logo>
                  <Badge count={this.props.cartItem} offset={[-10, 10]}>
                    <FaShoppingCart
                      type="shopping-cart"
                      style={{ fontSize: 28, margin: 10, color: "#998675" }}
                    />
                  </Badge>
                </Logo>
              </Link>
              <Logo>
                <FaSignOutAlt
                  type="logout"
                  style={{ fontSize: 24, margin: 10, color: "#998675" }}
                  onClick={this.props.logoutAsync}
                />
              </Logo>
            </Col>
          </Row>
        </CustomHeader>
        <Layout style={{ background: "transparent" }}>
          <Sider
            breakpoint="md"
            collapsedWidth="0"
            // collapsible
            // collapsed={this.state.collapsed}
            // onCollapse={this.handleCollapse}
            // style={{ background: "#fff" }}
            style={{
              // overflow: "auto",
              // height: "100vh",
              // position: "sticky",
              // top: 0,
              // left: 0,
              background: "#fff",
            }}
            // trigger={null}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={[currentPath]}
              defaultOpenKeys={[currentPath]}
            >
              {UserMenu(this.props.match.url)}
              {/* <Menu.Item>
                <button onClick={this.handleLogout}>
                  <Icon type="logout" />
                  <span>Logout</span>
                </button>
              </Menu.Item> */}
            </Menu>
          </Sider>
          <Layout style={{ background: "transparent" }} id="userLayoutContent">
            <Content style={{ padding: "0 16px 24px 16px", overflow: "auto" }}>
              {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{
                  margin: 10,
                  height: 150,
                  flex: 1,
                  background: 'url("https://images.immediate.co.uk/production/volatile/sites/4/2010/07/GettyImages-491712724-1cb3e72.jpg?quality=90&resize=960%2C408")',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover' }}
                />
                <div style={{ margin: 10, height: 150, flex: 1, background: 'green' }} />
                <div style={{ margin: 10, height: 150, flex: 1, background: 'blue' }} />
              </div> */}
              {UserBreadcrumb(this.props.match.url)}
              {UserRouter(this.props.match.url)}
            </Content>
            <CustomFooter style={{ textAlign: "center" }}>
              the1drugstore.com ©2019
            </CustomFooter>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

AdminLayout.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  // eslint-disable-line
  userRole: state.auth.userInfo.role,
  userInfo: state.auth.userInfo,
  cartItem: Object.keys(state.cart.items).length,
});

const mapDispatchToProps = ({ auth }) => ({
  // eslint-disable-line
  logoutAsync: auth.logoutAsync,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(AdminLayout);
