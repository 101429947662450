export const TRANSPOT_NAME = {
  nim: "NIM Express",
  kerry: "Kerry Express"
};
export const STATUS_NAME = {
  ordered: "สั่งซื้อแล้ว",
  confirm: "ยืนยันออเดอร์",
  pre_delivery: "เตรียมพร้อมจัดส่ง",
  sended: "จัดส่งเรียบร้อย",
  cancel: "ยกเลิกรายการ"
};
export const STATUS_COLOR = {
  ordered: "black",
  confirm: "black",
  pre_delivery: "black",
  sended: "green",
  cancel: "red"
};
export const PAYMENT_NAME = {
  unpaid: "ค้างชำระ",
  pending: "รอการตรวจสอบ",
  paid: "ชำระเงินแล้ว",
};

export const PAYMENT_COLOR = {
  unpaid: "red",
  pending: "blue",
  paid: "green",
};
