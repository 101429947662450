/**
*
* AdminCategoryCreateScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Card, message } from "antd";
import dataProvider from "utils/dataProvider";
import CategoryForm from "../../../components/CategoryForm";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class AdminCategoryCreateScreen extends Component { // eslint-disable-line
  provider = dataProvider()

  createProduct = async (values) => {
    try {
      await this.provider.fetch.post("/categories", {
        ...values
      }, { noCache: true });
      message.success("create category success");
      this.props.history.push("/admin/categories");
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  render() {
    return (
      <Container>
        <Card style={{ width: 600, textAlign: "center" }}>
          <CategoryForm
            onSubmit={this.createProduct}
          />
        </Card>
      </Container>
    );
  }
}

AdminCategoryCreateScreen.propTypes = {

};

AdminCategoryCreateScreen.defaultProps = {

};
export default compose(
  withRouter,
)(AdminCategoryCreateScreen);
