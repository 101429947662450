/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable implicit-arrow-linebreak */
/**
 *
 * MyOrderListScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import { Icon, Button, Modal } from "antd";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import dataProvider from "utils/dataProvider";
import { STATUS_NAME, STATUS_COLOR, PAYMENT_NAME } from "utils/constant";
import UploadMultiple from "components/UploadMultiple";
// import styles from './MyOrderListScreen.module.css'
// import PropTypes from 'prop-types'
import "dayjs/locale/th";
import { CustomTable } from "components/CustomTable";

const Container = styled.div``;
class MyOrderListScreen extends Component {
  // eslint-disable-line
  state = {
    myOrders: [],
    currentRecord: {},
    showModal: false,
  };

  provider = dataProvider();

  componentDidMount() {
    this.getOrders();
  }

  getOrders = async () => {
    const body = {
      filter: {
        order: "createdAt DESC",
      },
    };
    const myOrders = await this.provider.fetch.get("/myusers/me/orders", body, {
      noCache: true,
    });
    this.setState({ myOrders });
  };

  handleOk = async () => {
    if (this.state.slipImage.length > 0) {
      try {
        const { currentRecord } = this.state;
        const data = {
          slipImage: this.state.slipImage.map((i) => i.url),
        };
        await this.provider.fetch.post(
          `/orders/${currentRecord.id}/payOrder`,
          data,
          { noCache: true }
        );
        await this.getOrders();
        this.setState({
          showModal: false,
          slipImage: [],
        });
      } catch (error) {
        Swal.fire({
          type: "error",
          title: "Oops...Server Error !",
          text: "ลองใหม่ภายหลัง",
        });
      }
    }
  };

  handleCancel = () => {
    this.setState({
      showModal: false,
      currentRecord: {},
      slipImage: [],
    });
  };

  render() {
    const columns = [
      {
        title: "ลำดับ",
        key: "id",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "เลขที่ใบสั่งซื้อ",
        dataIndex: "orderCode",
        key: "orderCode",
        render: (text, record) => {
          if (record.status === "cancel") {
            return <div style={{ color: "red" }}>{record.orderCode}</div>;
          }
          return (
            <Link to={`/my-order/${record.orderCode}`}>{record.orderCode}</Link>
          );
        },
      },
      {
        title: "เวลาสั่ง",
        dataIndex: "createdAt",
        key: "create",
        render: (create) => (
          <span>
            {`${dayjs(create).locale("th").format("DD-MMM-YYYY HH:mm")}`}
          </span>
        ),
      },
      {
        title: "เลขใบกำกับภาษี",
        key: "orderItemsLength",
        render: (text, record) => {
          if (record.status === "cancel") {
            return <span> - </span>;
          }
          return <span>{record.orderItems}</span>;
        },
      },
      {
        title: "ยอดสุทธิ",
        dataIndex: "amount",
        key: "amount",
        render: (text, record) => {
          if (record.status === "cancel") {
            return <span> - </span>;
          }
          return <span>{record.amount}</span>;
        },
      },
      {
        title: "ดาวโหลดใบส่งสินค้า",
        dataIndex: "billing_image",
        key: "billing_image",
        render: (text, record) => {
          if (record.status === "cancel") {
            return <div style={{ color: "red", textAlign: "center" }}> - </div>;
          }
          if (record.billing_image.length === 0) {
            return (
              <div style={{ textAlign: "center" }}>
                <Icon type="loading-3-quarters" />
              </div>
            );
          }
          return record.billing_image.map((i, index) => (
            <div style={{ textAlign: "center" }}>
              <a
                href={i}
                target="_blank"
                rel="noopener noreferrer"
                title={`ใบเสร็จ 0${index + 1}`}
                style={{ margin: 3 }}
                key={i}
              >
                <Icon type="file-text" />
              </a>
            </div>
          ));
        },
      },
      {
        title: "สถานะคำสั่งซื้อ",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <div style={{ color: STATUS_COLOR[record.status] }}>
            {STATUS_NAME[record.status]}
          </div>
        ),
      },
      {
        title: "สถานะชำระเงิน",
        dataIndex: "payment_status",
        key: "payment_status",
        render: (text, record) => {
          if (record.status === "cancel") {
            return <div style={{ color: "red" }}>ยกเลิกรายการ</div>;
          }
          if (record.status === "ordered") {
            return <div>รอการยืนยันออเดอร์</div>;
          }
          if (record.payment_status === "unpaid") {
            return (
              <div>
                <div>{PAYMENT_NAME[record.payment_status]}</div>
                <div>
                  <Button
                    onClick={() =>
                      this.setState({ currentRecord: record, showModal: true })
                    }
                  >
                    <Icon type="upload" /> อัพโหลดสลิปโอนเงิน
                  </Button>
                </div>
              </div>
            );
          }
          if (record.payment_status === "pending") {
            return (
              <div style={{ color: "blue" }}>
                {PAYMENT_NAME[record.payment_status]}
              </div>
            );
          }
          if (record.payment_status === "paid") {
            return <span>{PAYMENT_NAME[record.payment_status]}</span>;
          }
          return null;
        },
      },
    ];
    return (
      <Container>
        <CustomTable
          pagination={{
            pageSize: 20,
          }}
          columns={columns}
          dataSource={this.state.myOrders}
          rowKey={(record) => record.id}
          scroll={{ x: 800 }}
        />
        <Modal
          title={
            <div>
              <div>ใบสั่งซื้อ: {this.state.currentRecord.orderCode}</div>
              <div>ยอดสุทธิ: {this.state.currentRecord.amount} บาท</div>
            </div>
          }
          visible={this.state.showModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          bodyStyle={{ width: "100%", maxWidth: 1200 }}
          destroyOnClose
          okText="ส่งสลิปโอนเงิน"
          cancelText="ยกเลิก"
          okButtonProps={{ disabled: !this.state.slipImage }}
        >
          <div style={{ textAlign: "center" }}>
            <UploadMultiple
              onUpload={(url) => this.setState({ slipImage: url })}
              maxFiles={2}
            />
          </div>
        </Modal>
      </Container>
    );
  }
}

MyOrderListScreen.propTypes = {};

MyOrderListScreen.defaultProps = {};
export default MyOrderListScreen;
