/**
*
* AccountPending
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import dataProvider from "utils/dataProvider";
import { Link } from "react-router-dom";
import { Table, Button, Input, Col, Row } from "antd";
import dayjs from "dayjs";
// import styles from './AccountPending.module.css'
// import PropTypes from 'prop-types'
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "dayjs/locale/th";

dayjs.extend(isSameOrAfter);

const { Search } = Input;

const PAGE_SIZE = 20;

const Container = styled.div`

`;
class AccountPending extends Component { // eslint-disable-line
  state = {
    orders: [],
    orderCount: 0,
    search: null,
    currentPage: 1,
  }

  provider = dataProvider()

  componentDidMount() {
    this.fetchOrder();
  }

  fetchOrder = async () => {
    const body = {
      filter: {
        where: {
          payment_status: "pending"
        },
        include: ["myusers"],
        order: "createdAt ASC",
        limit: PAGE_SIZE,
        skip: (this.state.currentPage - 1) * PAGE_SIZE,
      }
    };
    if (this.state.search) {
      body.filter.where.orderCode = {
        like: `%${this.state.search}%`
      };
    }
    const orders = await this.provider.fetch.get("/orders", body, { noCache: true });
    const orderCount = await this.provider.fetch.get("/orders/count", { where: body.filter.where });
    this.setState({ orders, orderCount: orderCount.count });
  }

  handleChangePage = (page) => {
    this.setState({ currentPage: page }, () => this.fetchOrder());
  }

  handleSearch = (search) => {
    this.setState({ search }, () => this.fetchOrder());
  }


  render() {
    const columns = [
      {
        title: "เลข Order",
        dataIndex: "orderCode",
        key: "orderCode",
      },
      {
        title: "ร้าน",
        dataIndex: "myusers.shop_name",
        key: "myusers.shop_name",
      },
      {
        title: "เบอร์ติดต่อ",
        dataIndex: "myusers.mobile",
        key: "myusers.mobile",
      },
      {
        title: "ยอดรวม",
        key: "amount",
        dataIndex: "amount",
        render: (text, record) => (
          <span>{record.amount} บาท</span>
        )
      },
      {
        title: "วันที่สั่งสินค้า",
        dataIndex: "createdAt",
        key: "create",
        render: (create) => (
          <span>{`${dayjs(create).locale("th").format("YYYY-MMM-DD HH:mm น.")}`}</span>
        )
      },
      {
        title: "วันที่จัดส่ง",
        dataIndex: "delivery_time",
        key: "delivery_time",
        render: (delivery_time) => (
          <span>{`${dayjs(delivery_time).locale("th").format("YYYY-MMM-DD HH:mm")}`}</span>
        )
      },
      {
        title: "กำหนดจ่าย",
        dataIndex: "due_date",
        key: "due_date",
        render: (due_date) => (
          <div style={{ color: "blue" }}>
            {`${dayjs(due_date).locale("th").format("YYYY-MMM-DD HH:mm")}(${dayjs(due_date).fromNow()})`}
          </div>
        )
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Link to={`/admin/orders/payment-status/${record.id}`}>
            ดูข้อมูล
          </Link>
        )
      }
    ];
    return (
      <Container>
        <Row type="flex" style={{ justifyContent: "space-between", marginBottom: 30 }}>
          <Col>
            <Search
              onSearch={this.handleSearch}
            />
          </Col>
          <Col>
            <Button onClick={this.fetchOrder}>Refresh</Button>
          </Col>

        </Row>
        <Table
          columns={columns}
          dataSource={this.state.orders}
          rowKey={record => record.id}
          pagination={{
            position: "bottom",
            total: this.state.orderCount,
            pageSize: PAGE_SIZE,
            onChange: this.handleChangePage,
            current: this.state.currentPage
          }}
          scroll={{ x: 1000 }}
        />
      </Container>
    );
  }
}

AccountPending.propTypes = {

};

AccountPending.defaultProps = {

};
export default AccountPending;
