/**
 *
 * SlickBanner
 *
 */

import React, { Component } from "react";
import Slider from "react-slick";
// import styled from "styled-components";
// import styles from './SlickBanner.module.css'
// import PropTypes from 'prop-types'

// const Container = styled.div``;
class SlickBanner extends Component {
  // eslint-disable-line
  render() {
    const { items = [] } = this.props;
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    if (items.length < 1) {
      return null;
    }
    return (
      <Slider {...settings}>
        {items.map((b) => (
          <div key={b.id}>
            {b.link ? (
              <a href={b.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={b.image}
                  alt=""
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    objectPosition: "center center",
                    borderRadius: 16,
                  }}
                />
              </a>
            ) : (
              <img
                src={b.image}
                alt=""
                style={{
                  objectFit: "contain",
                  width: "100%",
                  objectPosition: "center center",
                  borderRadius: 16,
                }}
              />
            )}
          </div>
        ))}
      </Slider>
    );
  }
}

SlickBanner.propTypes = {};

SlickBanner.defaultProps = {};
export default SlickBanner;
