/**
*
* AdminPreDeliveryOrderScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import dataProvider from "utils/dataProvider";
import { Table, Button, Modal, Typography, Select, Input, Form } from "antd";


// import PropTypes from 'prop-types'

const Container = styled.div`

`;
const { Option } = Select;
const transpotList = [
  {
    name: "NIM express",
    value: "nim"
  },
  {
    name: "Kerry Express",
    value: "kerry"
  },
  {
    name: "อื่นๆ",
    value: "other"
  }
];

const { Title } = Typography;

class AdminPreDeliveryOrderScreen extends Component { // eslint-disable-line
  state = {
    orders: [],
    visible: false,
    currentRecord: {},
  }

  provider = dataProvider()

  componentDidMount() {
    this.fetchOrder();
  }

  fetchOrder = async (option = { noCache: true }) => {
    const filter = {
      include: ["orderItems", "myusers"],
      where: {
        status: "pre_delivery"
      },
    };
    const orders = await this.provider.fetch.get("/orders", { filter }, option);
    this.setState({ orders });
  }

  handleCancel = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const { id } = this.state.currentRecord;
          const body = {
            tracking_number: values.trackingNumber,
            transpot: values.transpot === "other" ? values.otherTranspot : values.transpot
          };
          await this.provider.fetch.post(`/orders/${id}/doneOrder`, body, { noCache: true });
          await this.fetchOrder();
          this.props.form.resetFields();
          this.setState({ visible: false });
        } catch (error) {
          Swal.fire({
            type: "error",
            title: "Oops...Server Error !",
            text: "ลองใหม่ภายหลัง",
          });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const transpot = getFieldValue("transpot");
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
    };
    const columns = [
      {
        title: "ลำดับ",
        key: "id",
        render: (text, record, index) => (
          <span>{index + 1}</span>
        )
      },
      {
        title: "ชื่อลูกค้า",
        dataIndex: "myusers.full_name",
        key: "myusers.full_name",
      },
      {
        title: "ร้าน",
        dataIndex: "myusers.shop_name",
        key: "myusers.shop_name",
      },
      {
        title: "ที่อยู่จัดส่ง",
        dataIndex: "myusers.address",
        key: "myusers.address",
      },
      {
        title: "เบอร์โทร",
        dataIndex: "myusers.mobile",
        key: "myusers.mobile",
      },

      {
        title: "Action",
        key: "action",
        render: ((text, record) => (
          <Button onClick={() => this.setState({ currentRecord: record, visible: true })}>
            ส่งแล้ว
          </Button>
        )),
      },
    ];
    return (
      <Container>
        <Table
          columns={columns}
          dataSource={this.state.orders}
          rowKey={record => record.id}
          pagination={false}
        />
        <Modal
          visible={this.state.visible}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
        >
          <Title level={4}>
            กรุณากรอกรายละเอียดการจัดส่ง
          </Title>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label="ผู้ให้บริการขนส่ง" hasFeedback>
              {getFieldDecorator("transpot", {
                rules: [{ required: true, message: "เลือกขนส่ง!" }],
              })(
                <Select placeholder="Please select a country">
                  {
                  transpotList.map(t => (
                    <Option value={t.value} key={t.name}>{t.name}</Option>
                  ))
                }
                </Select>,
              )}
            </Form.Item>
            {
              transpot === "other" && (
                <Form.Item
                  label="ชื่อของผู้ให้บริการขนส่ง"
                >
                  {getFieldDecorator("otherTranspot", {
                    rules: [{ required: true, message: "พิมชื่อของขนส่ง!" }],
                  })(<Input />)}
                </Form.Item>
              )
            }
            <Form.Item
              label="เลข Tracking"
            >
              {getFieldDecorator("trackingNumber", {
                rules: [{ required: true, message: "กรอกเลข Tracking!" }],
              })(<Input />)}
            </Form.Item>
          </Form>
        </Modal>
      </Container>
    );
  }
}

AdminPreDeliveryOrderScreen.propTypes = {

};

AdminPreDeliveryOrderScreen.defaultProps = {

};
export default Form.create()(AdminPreDeliveryOrderScreen);
