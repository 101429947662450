/**
 *
 * NewsListScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import { FaHandPointDown } from "react-icons/fa";
import { Row, Col, Typography } from "antd";
import DashboardNews from "components/DashboardNews";
import qrCode from "image/qrcode.png";

const Container = styled.div``;
const { Title } = Typography;

class NewsListScreen extends Component {
  // eslint-disable-line
  render() {
    return (
      <Container>
        <Title level={2}>ข่าวสารอัพเดท</Title>
        <Row type="flex">
          <Col xs={24} lg={14}>
            <DashboardNews />
          </Col>
          <Col
            xs={24}
            lg={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              minWidth: 350,
              // marginLeft: 50,
              background: "white",
              padding: 30,
              borderRadius: 20,
            }}
          >
            <Title level={3} style={{ fontWeight: "normal", margin: 0 }}>
              สอบถาม & สั่งซื้อสินค้า
            </Title>
            <Title level={4} style={{ fontWeight: "normal" }}>
              ผ่านช่องทาง <span style={{ color: "#9AC949" }}>Line</span>
            </Title>
            <Title
              level={4}
              style={{ fontWeight: "normal", color: "#B3B3B3", margin: 0 }}
            >
              สแกน QR Code นี้
            </Title>
            <Title level={2} style={{ color: "#B3B3B3", fontSize: 30 }}>
              <FaHandPointDown type="arrow-down" style={{ fontSize: 30 }} />
            </Title>
            <img src={qrCode} alt="" style={{ width: "70%" }} />
          </Col>
        </Row>
      </Container>
    );
  }
}

NewsListScreen.propTypes = {};

NewsListScreen.defaultProps = {};
export default NewsListScreen;
