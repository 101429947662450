import React from "react";
import { compose } from "recompose";
import { Input, Form, Button, Select, Popconfirm } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import UploadImage from "components/UploadWithoutWatermark";

dayjs.extend(isBetween);

const { Option } = Select;

const pages = [
  {
    id: "promotion",
    name: "หน้าโปรโมชั่น",
    positions: [
      {
        id: "top",
        name: "ด้านบน",
      },
      {
        id: "bottom",
        name: "ด้านล่าง",
      },
    ],
  },
];

class BannerForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  onUploadImage = (url, type) => {
    if (type === "image") {
      this.props.form.setFieldsValue({
        image: url,
      });
    }
  };

  render() {
    const {
      form: { getFieldDecorator, getFieldValue, setFields },
      banner = {},
      onDelete,
    } = this.props;
    const { image, page, position, link } = banner;
    const watchPage = getFieldValue("page");
    const positions = pages.find((p) => p.id === watchPage)?.positions || [];
    return (
      <>
        <Form
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          onSubmit={this.handleSubmit}
          labelAlign="left"
        >
          <Form.Item label="รูปสินค้า">
            {getFieldDecorator("image", {
              rules: [{ required: true, message: "กรุณาอัพโหลดรูปแบนเนอร์" }],
              initialValue: image,
            })(<Input style={{ display: "none" }} />)}
            <UploadImage
              onUpload={(url) => this.onUploadImage(url, "image")}
              defaultImage={image}
            />
          </Form.Item>

          <Form.Item label="หน้า">
            {getFieldDecorator("page", {
              rules: [{ required: true, message: "กรุณาเลือก" }],
              initialValue: page,
            })(
              <Select
                showArrow
                onChange={() => setFields({ position: { value: undefined } })}
              >
                {pages.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="ตำแหน่ง">
            {getFieldDecorator("position", {
              rules: [{ required: true, message: "กรุณาเลือก" }],
              initialValue: position,
            })(
              <Select showArrow disabled={positions.length === 0}>
                {positions.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="link">
            {getFieldDecorator("link", {
              rules: [],
              initialValue: link,
            })(<Input />)}
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "20px" }}
            >
              บันทึก
            </Button>
            {onDelete && (
              <Popconfirm
                title="ต้องการซ่อนสินค้านี้้ใช่หรอไม่?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">ซ่อนสินค้าจากหน้าเว็ป</Button>
              </Popconfirm>
            )}
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default compose(Form.create())(BannerForm);
