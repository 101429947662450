/**
*
* AdminEditProductScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Card, message } from "antd";
import dataProvider from "utils/dataProvider";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import ProductForm from "../../../components/ProductForm";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class AdminEditProductScreen extends Component { // eslint-disable-line
  state = {
    product: {},
    categories: [],
  }

  provider = dataProvider()


  componentDidMount() {
    this.getProduct();
    this.fetchCategories();
  }

  fetchCategories = async () => {
    const categories = await this.provider.fetch.get("/categories", null, { noCache: true });
    this.setState({ categories });
  }

  getProduct = async () => {
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/404");
    }
    const product = await this.provider.fetch.get(`/products/${id}`, {
      // filter: { include: ['productDetail', 'categories', 'promotions'] }
      filter: {
        include:
        ["productDetail", "categories",
          {
            relation: "promotions",
            scope: { where: { end_date: { gt: new Date() } } },
            include: "productDetail"
            // scope: { where: { and: [{ active_date: { lt: new Date() } }, { end_date: { gt: new Date() } }] } }
          }
        ]
      }
    }, { noCache: true });
    if (!_isEmpty(product.productDetail)) {
      product.price_main = _get(product, "productDetail[0].price");
      product.unit_price_main = _get(product, "productDetail[0].unit_price");
      product.weight_main = _get(product, "productDetail[0].weight");
      product.fixed_delivery_cost_main = _get(product, "productDetail[0].fixed_delivery_cost");
      product.price_secondary = _get(product, "productDetail[1].price");
      product.unit_price_secondary = _get(product, "productDetail[1].unit_price");
      product.weight_secondary = _get(product, "productDetail[1].weight");
      product.fixed_delivery_cost_secondary = _get(product, "productDetail[1].fixed_delivery_cost");
      product.price_third = _get(product, "productDetail[2].price");
      product.unit_price_third = _get(product, "productDetail[2].unit_price");
      product.weight_third = _get(product, "productDetail[2].weight");
      product.fixed_delivery_cost_third = _get(product, "productDetail[2].fixed_delivery_cost");
    }
    this.setState({ product });
  }

  updateProduct = async (values) => {
    try {
      const { id } = this.props.match.params;
      await this.provider.fetch.patch(`/products/${id}`, values, { noCache: true });
      message.success("update product success");
      this.props.history.goBack();
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  deleteProduct = async () => {
    try {
      const { id } = this.props.match.params;
      await this.provider.fetch.delete(`/products/${id}`, null, { noCache: true });
      message.success("product is already hide");
      this.props.history.goBack();
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  render() {
    return (
      <Container>
        <Card style={{ width: 600, textAlign: "center" }}>
          <ProductForm
            history={this.props.history}
            onSubmit={this.updateProduct}
            product={this.state.product}
            onDelete={this.deleteProduct}
            categories={this.state.categories}
          />
        </Card>
      </Container>
    );
  }
}

AdminEditProductScreen.propTypes = {

};

AdminEditProductScreen.defaultProps = {

};
export default compose(
  withRouter,
)(AdminEditProductScreen);
