/**
*
* AdminEditNewsScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Form, Input, Button, Card, Typography, Popconfirm, message } from "antd";
import dataProvider from "utils/dataProvider";
import Swal from "sweetalert2";
import TextEditor from "components/TextEditor";
// import PropTypes from 'prop-types'

const { Text } = Typography;
const Container = styled.div`

`;
const CardContainer = styled.div`
  display: flex;
  justify-content: center;
`;


class AdminEditNewsScreen extends Component { // eslint-disable-line
  state = {
    fetched: false
  }

  provider = dataProvider()

  componentDidMount () {
    this.getnews();
  }

  getnews = async () => {
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/404");
    }
    const news = await this.provider.fetch.get(`/news/${id}`, null, { noCache: true });
    this.props.form.setFieldsValue({
      title: news.title,
      full_text: news.full_text,
    });
    setTimeout(() => {
      this.setState({ fetched: true });
    }, 100);
  }

  deleteNews = async () => {
    try {
      const { id } = this.props.match.params;
      await this.provider.fetch.delete(`/news/${id}`, null, { noCache: true });
      message.success("remove news");
      this.props.history.goBack();
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/404");
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await this.provider.fetch.patch(`/news/${id}`, values, { noCache: true });
          Swal.fire({
            title: "Success!",
            text: "แก้ไขข่าวเรียบร้อย",
            type: "success",
            confirmButtonText: "OK",
            onAfterClose: () => this.props.history.push("/admin/news"),
          });
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: error.toString(),
            type: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  }

  render() {
    const { getFieldDecorator, setFieldsValue, getFieldValue } = this.props.form;
    getFieldDecorator("full_text");
    return (
      <Container>
        <CardContainer>
          <Card style={{ width: "100%", maxWidth: 1250 }}>
            <Text>สร้างข่าวสารใหม่</Text>

            <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onSubmit={this.handleSubmit}>
              <Form.Item label="หัวข้อ" wrapperCol={{ span: 12 }}>
                {getFieldDecorator("title", {
                  rules: [
                    { required: true, message: "กรุณากรอกหัวข้อข่าว" },
                  ],
                })(<Input />)}
              </Form.Item>
              {
                this.state.fetched && (
                  <TextEditor
                    onChange={(html) => setFieldsValue({ full_text: html })}
                    defaultValue={getFieldValue("full_text")}
                  />
                )
              }


              <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Popconfirm
                  title="ต้องการลบข่าวนี้ใช่หรอไม่?"
                  onConfirm={this.deleteNews}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">ลบ</Button>
                </Popconfirm>
              </Form.Item>
            </Form>
          </Card>
        </CardContainer>
      </Container>
    );
  }
}

AdminEditNewsScreen.propTypes = {

};

AdminEditNewsScreen.defaultProps = {

};

export default compose(
  Form.create(),
  withRouter,
)(AdminEditNewsScreen);
