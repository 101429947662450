/**
 *
 * LoginScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter, Redirect, Link } from "react-router-dom";
import { Input, Form, Button } from "antd";
import { compose } from "recompose";
import { connect } from "react-redux";
import _ from "lodash";
import Swal from "sweetalert2";
import bg from "image/white.png";
import formBg from "image/green.png";
import Text from "antd/lib/typography/Paragraph";
import logo from "image/logo.png";
import qs from "query-string";

// import PropTypes from 'prop-types'
// import SimpleFormLayout from '../../components/SimpleFormLayout/index'
// import Login from '../../containers/Login/index'

const Container = styled.div`
  background-image: url("${bg}");
  background-position: center;
  background-size: cover;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 1050px;
  background: white;
  text-align: center;
  position: relative;
  z-index: 10;
  display: flex;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.75);
  overflow: hidden;
  @media (min-width: 768px) {
    border-radius: 60px;
    margin-bottom: 38px;
  }
`;

const LoginFormContainer = styled.div`
  display: block;
  flex: 1;
  background-image: url("${formBg}");
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: relative;
  padding: 16px;
  @media (min-width: 768px) {
    max-width: 50%;
    padding: 86px 66px;
    height: auto;
  }
`;

const TItleContainer = styled.div`
  padding: 86px 30px;
  position: relative;
  display: none;
  @media (min-width: 768px) {
    display: block;
    flex: 1;
  }
`;

const Title = styled.h1`
  font-size: 55px;
  color: #666666;
  margin: 0;
  line-height: 55px;
`;

const TextTitleMobile = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`;

const SignInText = styled.div`
  font-size: 24px;
  color: white;
  margin-bottom: 60px;
  @media (min-width: 768px) {
    font-size: 36px;
    margin-bottom: 120px;
    margin-top: 20px;
  }
`;

class LoginScreen extends Component {
  // eslint-disable-line
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const { redirect } =
            qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) ||
            {};

          const { email, password } = values;
          const user = await this.props.login({
            email: email.trim(),
            password: password.trim(),
          });
          if (redirect) {
            return this.props.history.push(`/${redirect}`);
          }
          const staffRole = ["admin", "delivery_man", "accountant", "cashier"];
          if (staffRole.includes(user.role.name)) {
            return this.props.history.push("/admin");
            // window.location = '/admin'
            // return
          }
          // window.location = '/'
          return this.props.history.push("/");
        } catch (error) {
          const errData = _.get(error, "response.data.error");
          const isWaitConfirm =
            errData?.code === "LOGIN_FAILED_EMAIL_NOT_VERIFIED";
          if (isWaitConfirm) {
            return Swal.fire({
              type: "info",
              title: "Account Verifying",
              showCloseButton: true,
              showConfirmButton: false,
              // eslint-disable-next-line
              html: 'Account ของคุณอยู่ระหว่างกระบวนการตรวจสอบข้อมูลเบื้องต้น<br />อาจใช้เวลาดำเนินการไม่เกิน 24 ชม. <br />ต้องการข้อมูลเพิ่มเติม<br />สามารถสอบถามได้ทาง LINE <br/> <a href="https://lin.ee/Gd0KQM1"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0"></a>',
            });
          }
          return Swal.fire({
            type: "error",
            title: "Login Failed",
            showCloseButton: true,
            showConfirmButton: false,
            html: `ไม่สามารถทำการ login ได้ <br />
            ต้องการข้อมูลเพิ่มเติม<br />สามารถสอบถามได้ทาง LINE <br />
            <a href="https://lin.ee/Gd0KQM1">
            <img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0">
            </a>`,
          });
        }
      }
      return null;
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { redirect } =
      qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || {};
    if (this.props.isAuth === null) {
      return null;
    }
    if (this.props.isAuth) {
      if (redirect) {
        return <Redirect to={`/${redirect}`} />;
      }
      return <Redirect to="/" />;
    }
    return (
      <Container>
        <FormContainer>
          <TItleContainer>
            <div style={{ position: "absolute", top: 20, left: 30 }}>
              <img src={logo} alt="" width={140} />
            </div>
            <Title
              style={{
                color: "#1D5E44",
                fontStyle: "italic",
                marginBottom: 30,
                fontSize: 60,
                marginTop: 20,
              }}
            >
              Welcome to
            </Title>
            <Title style={{ marginBottom: 30, color: "#1D5E44", fontSize: 40 }}>
              The1drugstore.com
            </Title>
            <br />
            <br />
            <br />
            <Text style={{ fontSize: 20, margin: 0, color: "#6FB4A1" }}>
              สำหรับลูกค้าที่เข้าเว็ปไซต์ครั้งแรก
            </Text>
            <Text style={{ fontSize: 20, color: "#6FB4A1" }}>
              สามารถลงทะเบียนได้ที่นี่
            </Text>
            <Link to="/register">
              <Button
                type="primary"
                style={{
                  width: 150,
                  height: 55,
                  border: "2px solid #9ECB8F",
                  color: "white",
                  backgroundColor: "#1D5E44",
                  fontSize: 22,
                  borderRadius: 60,
                }}
              >
                สมัครสมาชิก
              </Button>
            </Link>
            <div
              style={{
                position: "absolute",
                bottom: 20,
                left: 0,
                right: 0,
                textAlign: "center",
                color: "#6FB4A1",
              }}
            >
              (เว็ปไซต์ขายส่งยา สำหรับร้านยา คลินิก สถานพยาบาล)
            </div>
          </TItleContainer>
          <LoginFormContainer>
            <TextTitleMobile>
              <div style={{ position: "absolute", top: 10, left: 10 }}>
                <img src={logo} alt="" width={140} />
              </div>
              <Title
                style={{
                  color: "white",
                  fontStyle: "italic",
                  marginBottom: 16,
                  fontSize: 32,
                  marginTop: 50,
                  lineHeight: "40px",
                }}
              >
                Welcome to
              </Title>
              <Title
                style={{
                  marginBottom: 16,
                  color: "white",
                  fontSize: 32,
                  lineHeight: "48px",
                }}
              >
                The1drugstore.com
              </Title>
            </TextTitleMobile>
            <SignInText>Sign in / เข้าสู่ระบบ</SignInText>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              onSubmit={this.handleSubmit}
            >
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Please input your username!" },
                ],
              })(<Input style={{ marginBottom: 30 }} placeholder="E-Mail" />)}
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please input your password!" },
                ],
              })(
                <Input
                  type="password"
                  style={{ marginBottom: 60 }}
                  placeholder="Password"
                />
              )}

              <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: 150,
                    height: 55,
                    backgroundColor: "#1D5E44",
                    fontSize: 22,
                    borderRadius: "60px",
                  }}
                >
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>
              <TextTitleMobile>
                <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                  <Link to="/register">
                    <Button
                      type=""
                      style={{
                        width: 150,
                        height: 55,
                        border: "2px solid #9ECB8F",
                        fontSize: 22,
                        borderRadius: 60,
                        marginTop: 40,
                      }}
                    >
                      สมัครสมาชิก
                    </Button>
                  </Link>
                </Form.Item>
              </TextTitleMobile>
            </Form>
          </LoginFormContainer>
        </FormContainer>
        {/*
        <div style={{ textAlign: 'right', width: 650 }}>
          <Link to="/register">
            <Button
              type="primary"
              style={{
                width: 150,
                height: 55,
                border: '2px solid #9ECB8F',
                color: '#9ECB8F',
                backgroundColor: 'white',
                fontSize: 22,
                borderRadius: 20
              }}
            >
              สมัครสมาชิก
            </Button>
          </Link>
        </div> */}
      </Container>
    );
  }
}

LoginScreen.propTypes = {};

LoginScreen.defaultProps = {};

const mapDispatchToProp = ({ auth }) => ({
  login: auth.loginAsync,
});

const mapStateToProps = ({ auth }) => ({
  isAuth: auth.isAuth,
});

const LoginWithHOC = compose(
  connect(mapStateToProps, mapDispatchToProp),
  Form.create(),
  withRouter
)(LoginScreen);

export default LoginWithHOC;
