import React from "react";
import { compose } from "recompose";
import { Input, Form, Button, Popconfirm } from "antd";
// import { Link } from 'react-router-dom'

class EmployerForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      employer = {},
      onDelete,
    } = this.props;
    const { first_name, last_name, employer_code, department, bank_account } = employer;
    return (
      <Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onSubmit={this.handleSubmit}
        labelAlign="left"
      >
        <Form.Item label="ชื่อ">
          {getFieldDecorator("first_name", {
            rules: [{ required: true, message: "กรุณากรอกชื่อ" }],
            initialValue: first_name,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="นามสกุล">
          {getFieldDecorator("last_name", {
            rules: [{ required: true, message: "กรุณากรอกนามสกุล" }],
            initialValue: last_name,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="โค้ดพนักงาน">
          {getFieldDecorator("employer_code", {
            rules: [{ required: true, message: "กรุณากรอกโค้ดพนักงาน" }],
            initialValue: employer_code,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="แผนก">
          {getFieldDecorator("department", {
            rules: [{ required: true, message: "กรุณากรอกชื่อแผนก" }],
            initialValue: department,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="เลขบัญชี">
          {getFieldDecorator("bank_account", {
            rules: [{ required: true, message: "กรุณากรอกเลขบัญชี" }],
            initialValue: bank_account,
          })(<Input />)}
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: "20px" }}
          >
            บันทึก
          </Button>
          {onDelete && (
            <Popconfirm
              title="ต้องการลบสินค้านี้้ใช่หรอไม่?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">ลบ</Button>
            </Popconfirm>
          )}
        </Form.Item>
      </Form>
    );
  }
}

export default compose(Form.create())(EmployerForm);
