/**
*
* AdminNewsListScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
// import PropTypes from 'prop-types'
import dataProvider from "utils/dataProvider";
import { Link } from "react-router-dom";
import _get from "lodash/get";
import { Table, Input, Button, Icon } from "antd";
import Highlighter from "react-highlight-words";


const Container = styled.div`

`;
const RefreshContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Refresh = styled.button`
  margin-right: 10px;
`;

class AdminNewsListScreen extends Component { // eslint-disable-line
  state = {
    newsList: [],
    searchText: "",
  }

  provider = dataProvider()

  componentDidMount() {
    this.fetchNews({ noCache: true });
  }


  fetchNews = async (option = {}) => {
    const newsList = await this.provider.fetch.get("/news", null, option);
    this.setState({ newsList });
  }

  getColumnSearchProps = (dataIndex, render) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => _get(record, dataIndex, "")
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: typeof render === "function" ? render : create => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={`${create}`}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  }

  render() {
    const columns = [
      {
        title: "ไอดี",
        dataIndex: "id",
        key: "id",
        ...this.getColumnSearchProps("id"),
      },
      {
        title: "หัวข้อ",
        dataIndex: "title",
        key: "title",
        ...this.getColumnSearchProps("title"),
      },
      {
        title: "วันที่ประกาศ",
        dataIndex: "createdAt",
        key: "create",
        sorter: (a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateA.getTime() - dateB.getTime();
        },
        ...this.getColumnSearchProps("createdAt", create => (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={`${dayjs(create).format("YYYY-MM-DD")}(${dayjs(create).fromNow()})`}
          />
        )),
      },
      {
        title: "Action",
        key: "action",
        render: ((text, record) => (
          <span>
            <Link to={`/admin/news/${record.id}`}>
              ดูข้อมูล
              {record.name}
            </Link>
          </span>
        )),
      },

    ];
    return (
      <Container>
        <RefreshContainer>
          <Refresh onClick={() => this.fetchNews({ noCache: true })}>Refresh</Refresh>
        </RefreshContainer>
        <Table columns={columns} dataSource={this.state.newsList} rowKey={record => record.id} />
      </Container>
    );
  }
}

AdminNewsListScreen.propTypes = {

};

AdminNewsListScreen.defaultProps = {

};
export default AdminNewsListScreen;
