/**
 *
 * AdminCreateProductScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Card, message } from "antd";
import dataProvider from "utils/dataProvider";

import BannerForm from "components/BannerForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class AdminBannerEditScreen extends Component {
  // eslint-disable-line
  state = {
    banner: {},
  };

  provider = dataProvider();

  componentDidMount() {
    this.getBanner();
  }

  getBanner = async () => {
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/404");
    }
    const banner = await this.provider.fetch.get(
      `/banners/${id}`,
      {
        // filter: { include: ['productDetail', 'categories', 'promotions'] }
      },
      { noCache: true }
    );
    if (banner) {
      this.setState({ banner });
    }
  };

  createBanner = async (values) => {
    const { id } = this.props.match.params;
    try {
      await this.provider.fetch.patch(
        `/banners/${id}`,
        {
          ...values,
        },
        { noCache: true }
      );
      message.success("create banner success");
      this.props.history.push("/admin/banners");
    } catch (ex) {
      const resErr = _.get(ex, "response");
      const msg = _.get(resErr, "data.error.message");
      if (resErr.status === 422) {
        message.error(msg);
      } else {
        message.error("มีข้อผิดพลาดเกิดขึ้น");
      }
    }
  };

  render() {
    return (
      <Container>
        <Card style={{ width: 600, textAlign: "center" }}>
          <BannerForm onSubmit={this.createBanner} banner={this.state.banner} />
        </Card>
      </Container>
    );
  }
}

AdminBannerEditScreen.propTypes = {};

AdminBannerEditScreen.defaultProps = {};
export default compose(withRouter)(AdminBannerEditScreen);
