/**
*
* AdminStaffCreateScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Form, Input, Button, Select, Popconfirm } from "antd";
import dataProvider from "utils/dataProvider";
import Swal from "sweetalert2";
// import PropTypes from 'prop-types'

const { Option } = Select;

const Container = styled.div`

`;


class AdminStaffCreateScreen extends Component { // eslint-disable-line
  state = {
    roleList: [],
    user: {}
  }

  provider = dataProvider()

  componentDidMount() {
    this.getRole();
    this.getUser();
  }

  getUser = async () => {
    try {
      const { id } = this.props.match.params;
      if (!id) {
        this.props.history.push("/404");
      }
      const user = await this.provider.fetch.get(`/myusers/${id}`, null, { noCache: true });
      this.setState({ user });
      this.props.form.setFieldsValue({
        email: user.email,
        roleId: user.roleId,
        full_name: user.full_name,
        mobile: user.mobile,
        persornal_id: user.persornal_id,
      });
    } catch (error) {
      this.props.history.push("/404");
    }
  }

  getRole = async () => {
    const roleList = await this.provider.fetch.get("/roles");
    this.setState({ roleList });
  }

  handleSelectChange = value => {
    this.props.form.setFieldsValue({
      roleId: value,
    });
  }

  handleSubmit = e => {
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/404");
    }
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await this.provider.fetch.patch(`/myusers/${id}`, values, { noCache: true });
          Swal.fire({
            title: "Success!",
            text: "แก้ไขข้อมูลเรียบร้อย",
            type: "success",
            confirmButtonText: "OK",
            onAfterClose: () => this.props.history.push("/admin/staffs"),
          });
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: error.toString(),
            type: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  }

  deleteUser = async () => {
    try {
      await this.provider.fetch.delete(`/myusers/${this.state.user.id}`, null, { noCache: true });
      this.props.history.push("/admin/staffs");
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Container>
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
          <Form.Item label="email">
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Please input your username!" },
                { type: "email", message: "wrong email format" },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ตำแหน่ง">
            {getFieldDecorator("roleId", {
              rules: [{ required: true, message: "Please select your gender!" }],
            })(
              <Select
                onChange={this.handleSelectChange}
                disabled
              >
                {
                  this.state.roleList.map(role => (
                    <Option value={role.id} key={role.id}>{role.name}</Option>
                  ))
                }
              </Select>,
            )}
          </Form.Item>
          <Form.Item label="ชื่อ นามกุล">
            {getFieldDecorator("full_name", {
              rules: [{ required: true, message: "Please input your username!" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="เบอร์ติดต่อ">
            {getFieldDecorator("mobile", {
              rules: [{ required: true, message: "Please input your username!" }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="บัตรประชาขน">
            {getFieldDecorator("persornal_id", {
              rules: [],
            })(<Input />)}
          </Form.Item>
          <div
            style={{ display: "flex", justifyContent: "space-between", padding: "0 15%" }}
          >
            <div style={{ flex: 5 }}>
              <Popconfirm
                title="ต้องการลบยูสเซอร์คนนี้ ?"
                onConfirm={this.deleteUser}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">
                  Remove
                </Button>
              </Popconfirm>
            </div>
            <div style={{ flex: 12, textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
            <div style={{ flex: 7 }} />
          </div>
        </Form>
      </Container>
    );
  }
}

AdminStaffCreateScreen.propTypes = {

};

AdminStaffCreateScreen.defaultProps = {

};

export default compose(
  Form.create(),
  withRouter,
)(AdminStaffCreateScreen);
