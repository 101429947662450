/**
 *
 * AdminCategoryListScreen
 *
 */

import React, { Component } from "react";
import { compose } from "recompose";
import _ from "lodash";
import styled from "styled-components";
// import PropTypes from 'prop-types'
import dataProvider from "utils/dataProvider";
import {
  Table,
  Button,
  Form,
  InputNumber,
  Input,
  message,
  DatePicker,
} from "antd";

const Container = styled.div``;
class CreateSlipsScreen extends Component {
  // eslint-disable-line
  state = {
    employers: [],
  };

  provider = dataProvider();

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    const body = {
      filter: {
        include: [
          {
            relation: "payment_slips",
            scope: {
              order: "id DESC",
              limit: 1,
            },
          },
        ],
      },
    };
    const employers = await this.provider.fetch.get("/employers", body, {
      noCache: true,
    });

    this.setState({ employers });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const body = values.data.map((d) => ({
            ...d,
            payment_date: values.payment_date.toISOString(),
          }));
          await this.provider.fetch.post("/payment_slips", body, {
            noCache: true,
          });
          message.success("create slips success");
          this.props.history.push("/slip/payment");
        } catch (ex) {
          const resErr = _.get(ex, "response");
          const msg = _.get(resErr, "data.error.message");
          if (resErr.status === 422) {
            message.error(msg);
          } else {
            message.error("มีข้อผิดพลาดเกิดขึ้น");
          }
        }
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const columns = [
      {
        title: <p style={{ fontSize: 14 }}>ลำดับ</p>,
        align: "center",
        width: 40,
        key: "ลำดับ",
        render: (text, record, index) => <div>{index + 1}</div>,
      },
      {
        title: <p style={{ fontSize: 14 }}>รหัสพนักงาน</p>,
        align: "center",
        width: 120,
        dataIndex: "employer_code",
        key: "employer_code",
      },
      {
        title: <p style={{ fontSize: 14 }}>ชื่อ-นามสกุล</p>,
        align: "center",
        width: 280,
        key: "name",
        render: (text, record) => (
          <div>
            {record.first_name} {record.last_name}
          </div>
        ),
      },
      {
        title: <p style={{ fontSize: 14 }}>แผนก</p>,
        align: "center",
        width: 120,
        dataIndex: "department",
        key: "department",
      },
      {
        title: <p style={{ fontSize: 14 }}>เลขบัญชี</p>,
        align: "center",
        width: 120,
        dataIndex: "bank_account",
        key: "bank_account",
      },
      {
        title: <p style={{ fontSize: 14 }}>จำนวนวันทำงาน</p>,
        align: "center",
        width: 120,
        key: "work_day",
        render: (text, record, index) => (
          <div>
            {getFieldDecorator(`data[${index}].work_day`, { initialValue: 0 })(
              <InputNumber style={{ width: "100%" }} min={0} max={31} />
            )}
          </div>
        ),
      },
      {
        title: <p style={{ fontSize: 14 }}>เงินได้ (INCOME)</p>,
        align: "center",
        children: [
          {
            title: <p style={{ fontSize: 14 }}>เงินเดือน(บาท)</p>,
            align: "center",
            width: 120,
            key: "เงินเดือน",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].employersId`, {
                  initialValue: record.id,
                })(<Input style={{ display: "none" }} />)}
                {getFieldDecorator(`data[${index}].salary`, {
                  initialValue: record?.payment_slips[0]?.salary || 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
          {
            title: <p style={{ fontSize: 14 }}>ค่าประจำตำแหน่ง(บาท)</p>,
            align: "center",
            width: 120,
            key: "position_value",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].position_value`, {
                  initialValue: record?.payment_slips[0]?.position_value || 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
          {
            title: <p style={{ fontSize: 14 }}>ค่าโทรศัพท์(บาท)</p>,
            align: "center",
            width: 120,
            key: "permanent_extra_value",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].permanent_extra_value`, {
                  initialValue:
                    record?.payment_slips[0]?.permanent_extra_value || 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
          {
            title: <p style={{ fontSize: 14 }}>ค่าตอบแทนพิเศษ(บาท)</p>,
            align: "center",
            width: 120,
            key: "temporary_extra_value",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].temporary_extra_value`, {
                  initialValue:
                    record?.payment_slips[0]?.temporary_extra_value || 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
          {
            title: <p style={{ fontSize: 14 }}>O.T.(ชั่วโมง)</p>,
            align: "center",
            width: 120,
            key: "ot",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].ot`, {
                  initialValue: record?.payment_slips[0]?.ot || 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
          {
            title: <p style={{ fontSize: 14 }}>INCENTIVE(บาท)</p>,
            align: "center",
            width: 120,
            key: "incentive",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].incentive`, {
                  initialValue: record?.payment_slips[0]?.incentive || 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
          {
            title: <p style={{ fontSize: 14 }}>เบี้ยขยัน(บาท)</p>,
            align: "center",
            width: 120,
            key: "attendance_reward",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].attendance_reward`, {
                  initialValue:
                    record?.payment_slips[0]?.attendance_reward || 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
          {
            title: <p style={{ fontSize: 14 }}>เงินได้อื่นๆ(บาท)</p>,
            align: "center",
            width: 120,
            key: "other_income",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].other_income`, {
                  initialValue: record?.payment_slips[0]?.other_income || 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
        ],
      },
      {
        title: <p style={{ fontSize: 14 }}>เงินหัก (DEDUCTION)</p>,
        align: "center",
        children: [
          {
            title: <p style={{ fontSize: 14 }}>ขาด ลา มาสาย(บาท)</p>,
            align: "center",
            width: 120,
            key: "absent_value",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].absent_value`, {
                  initialValue: 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
          {
            title: <p style={{ fontSize: 14 }}>ประกันสังคม(บาท)</p>,
            align: "center",
            width: 120,
            key: "social_fund",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].social_fund`, {
                  initialValue: 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
          {
            title: <p style={{ fontSize: 14 }}>ภาษี ณ ที่จ่าย(บาท)</p>,
            align: "center",
            width: 120,
            key: "tax",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].tax`, {
                  initialValue: record?.payment_slips[0]?.tax || 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
          {
            title: <p style={{ fontSize: 14 }}>ประกันพนักงาน(บาท)</p>,
            align: "center",
            width: 120,
            key: "insurance",
            render: (text, record, index) => (
              <div>
                {getFieldDecorator(`data[${index}].insurance`, {
                  initialValue: record?.payment_slips[0]?.insurance || 0,
                })(<InputNumber style={{ width: "100%" }} min={0} />)}
              </div>
            ),
          },
        ],
      },
    ];
    return (
      <Container>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="วันที่จ่าย">
            {getFieldDecorator("payment_date", {
              rules: [{ required: true, message: "กรุณาเลือกวันที่" }],
            })(<DatePicker />)}
          </Form.Item>
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={this.state.employers}
            rowKey={(record) => record.id}
            pagination={{ pageSize: 200 }}
          />
          <Button htmlType="submit">ยืนยัน</Button>
        </Form>
      </Container>
    );
  }
}

CreateSlipsScreen.propTypes = {};

CreateSlipsScreen.defaultProps = {};
export default compose(Form.create())(CreateSlipsScreen);
