/**
*
* AdminDashboardScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
// import PropTypes from 'prop-types'

const Container = styled.div`

`;
class AdminDashboardScreen extends Component { // eslint-disable-line
  render() {
    return (
      <Container>
        This is AdminDashboardScreen
      </Container>
    );
  }
}

AdminDashboardScreen.propTypes = {

};

AdminDashboardScreen.defaultProps = {

};
export default AdminDashboardScreen;
