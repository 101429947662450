/**
 *
 * AdminCreateBulkProductScreen
 *
 */

import React, { Component } from "react";
import dataProvider from "utils/dataProvider";
import styled from "styled-components";
import { Card, Button, Upload, Icon, message } from "antd";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

const { Dragger } = Upload;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DraggerContainer = styled.div`
  margin: 20px 0px;
`;

class AdminCreateBulkProductScreen extends Component {
  // eslint-disable-line
  state = {};

  render() {
    const current = this;
    const props = {
      name: "file",
      multiple: true,
      // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      onChange(info) {
        const { status } = info.file;
        // if (status !== 'uploading') {
        //   console.log(info.file, info.fileList)
        // }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      beforeUpload(file) {
        const isXLSX = file.name.indexOf(".xlsx") !== -1;
        if (!isXLSX) {
          message.error("You can only upload .XLSX file!");
        }
        return isXLSX;
      },
      async customRequest(info) {
        try {
          const formData = new FormData();
          formData.append("file", info.file);
          const response = await dataProvider().fetch.post("/products/uploadBulkProducts", formData, { noCache: true });
          const { status } = response;
          if (status === "done") {
            message.success(`${info.file.name} file uploaded successfully.`);
            current.props.history.push("/admin/products");
          }
        } catch (e) {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    };

    return (
      <Container>
        <Card style={{ width: 800, textAlign: "center" }}>
          <Button
            type="primary"
            icon="download"
            size="large"
            href="https://morya-bucket.s3-ap-southeast-1.amazonaws.com/morya-bucket/assets/structure-product-category_main.xlsx"
            color="transparent"
            target="_blank"
            download
          >
            Download Template
          </Button>

          <DraggerContainer>
            <Dragger
              {...props}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
            </Dragger>
          </DraggerContainer>
        </Card>
      </Container>
    );
  }
}

AdminCreateBulkProductScreen.propTypes = {};

AdminCreateBulkProductScreen.defaultProps = {};
export default compose(
  withRouter,
)(AdminCreateBulkProductScreen);
