import React from "react";
import dataProvider from "utils/dataProvider";
import qs from "query-string";

const withSearch = (Component) => {
  class WrapperClass extends React.Component {
    state = {
      productList: [],
      countProduct: 0,
      name: "",
      categories: "",
      factory: "",
      status: "all"
    }

    setProductList = (productList, countProduct = 10) => {
      this.setState({
        productList,
        countProduct
      });
    }

    onSearch = async ({ name, categories, factory, limit, skip, status = "all", isDeleted = false }) => {
      const softDeletedOr = { or: [{ isDeleted: true }, { isDeleted: false }] };
      const provider = dataProvider();
      const where = {};
      let res = "";
      let resCount = "";
      if (name || factory || (categories && categories !== "all") || status !== "all") {
        where.and = [];
        if (name) {
          where.and = where.and.concat({
            or: [{ name: { regexp: `/.*${name}.*/i` } }, { drug_type: { regexp: `/.*${name}.*/i` } }, { barcode: name }]
          });
        }
        if (factory) {
          where.and = where.and.concat({ factory: { regexp: `/.*${factory}.*/i` } });
        }
        if (["true", "false"].includes(status)) {
          where.and = where.and.concat({ status: status === "true" });
        }
        if (categories && categories !== "all") {
          res = await provider.fetch.get(
            `/categories/${categories}/products`,
            {
              filter: {
                include: [
                  "productDetail",
                  "categories",
                  {
                    relation: "promotions",
                    scope: {
                      where: { end_date: { gt: new Date() } },
                      include: "productDetail"
                    }
                  }
                ],
                where,
                limit,
                skip,
                isDeleted
              }
            },
            { noCache: true }
          );

          resCount = await provider.fetch.get(
            `/categories/${categories}/products/count`,
            {
              where: isDeleted ? {
                ...where,
                ...softDeletedOr
              } : where
            },
            { noCache: true }
          );
          return this.setState({
            productList: res,
            countProduct: resCount.count
          });
        }
      }
      res = await provider.fetch.get(
        "/products",
        {
          filter: {
            include: [
              "productDetail",
              "categories",
              {
                relation: "promotions",
                scope: {
                  where: { end_date: { gt: new Date() } },
                  include: "productDetail"
                }
              }
            ],
            where,
            limit,
            skip,
            isDeleted
          }
        },
        { noCache: true }
      );
      resCount = await provider.fetch.get(
        "/products/count",
        {
          where: isDeleted ? {
            ...where,
            ...softDeletedOr
          } : where
        },
        { noCache: true }
      );
      return this.setState({
        productList: res,
        countProduct: resCount.count
      });
    }

    search = (value, type) => {
      const { name, categories, factory, skip, status } = this.state;
      const filter = {
        name,
        categories,
        factory,
        skip,
        status
      };
      if (type === "name") {
        filter.skip = 0;
      }
      filter[type] = value;
      this.setState({
        [type]: value
      });
      this.props.history.push(`?${qs.stringify(filter)}`);
    }

    setValue = (value, type) => {
      this.setState({
        [type]: value
      });
    }

    render() {
      const props = {
        setProductList: this.setProductList,
        onSearch: this.onSearch,
        search: this.search,
        productList: this.state.productList,
        countProduct: this.state.countProduct,
        name: this.state.name,
        factory: this.state.factory,
        status: this.state.status,
        categories: this.state.categories,
        setValue: this.setValue
      };
      return (
        <>
          <Component {...this.props} {...props} />
        </>
      );
    }
  }
  return WrapperClass;
};

export default withSearch;
