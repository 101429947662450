import React from "react";
import { Button, Tag } from "antd";
import { FaShoppingBasket } from "react-icons/fa";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import noImage from "image/noimage.png";

const ProductCard = ({
  data,
  setOpenModal,
  findProduct,
  addProductDetail,
  isPromotionScreen,
  wishlist,
  addToWishList,
}) => {
  const product = { ...data };
  const alreadyWish =
    wishlist.find((item) => item.productsId === data.id) || false;
  if (!_isEmpty(data.promotions)) {
    product.product_image = _get(data, "promotions[0].product_image");
    product.name = _get(data, "promotions[0].name");
    product.productDetail = _get(data, "promotions[0].productDetail");
  }
  return (
    <div
      style={{
        backgroundColor: "white",
        marginBottom: "8px",
        border: "1px solid gray",
        padding: "12px",
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "8px" }}>
        <img
          width={80}
          height={80}
          style={{ objectFit: "cover" }}
          src={product.product_image || data.product_image || noImage}
          onError={(evt) => {
            evt.target.src = noImage;
          }}
          alt={data.name}
        />
      </div>
      {_isEmpty(data.promotions) ? (
        ""
      ) : (
        <div style={{ position: "absolute", top: "16px", left: "12px" }}>
          <Tag color="red">โปรโมชั่น</Tag>
        </div>
      )}
      <div>
        <div>
          <div>
            <span className="font-bold">รหัสสินค้า: </span>
            {data.code}
          </div>
          <div>
            <span className="font-bold">ชื่อสินค้า: </span>
            {product.name}
          </div>
          <div>
            <span className="font-bold">บริษัท: </span>
            {product.Factory}
          </div>
          <div>
            <span className="font-bold">หมวดหมู่: </span>
            {product.categories.map((item) => (
              <Tag key={item.id}>{item.name}</Tag>
            ))}
          </div>
          <div style={{ display: "flex" }}>
            <span className="font-bold">ราคา: </span>
            <span style={{ marginLeft: "8px" }}>
              {_get(product, "productDetail", []).map((detail, index) => (
                <div key={index}>{`${detail.unit_price}: ${detail.price}`}</div>
              ))}
            </span>
          </div>
          <div style={{ marginTop: "8px" }}>
            {data.status ? (
              <Button
                block
                type="primary"
                onClick={() => {
                  if (isPromotionScreen) {
                    addProductDetail(data);
                  } else {
                    findProduct(data.id);
                  }
                  setOpenModal(true);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 8,
                  }}
                >
                  <FaShoppingBasket />
                  สั่งซื้อ
                </div>
              </Button>
            ) : (
              <Button
                block
                style={
                  alreadyWish
                    ? {}
                    : { backgroundColor: "#ffbd00", color: "white" }
                }
                onClick={() => addToWishList(data.id)}
                disabled={alreadyWish}
              >
                {alreadyWish ? "รับการแจ้งเตือนแล้ว" : "แจ้งเตือนสินค้าเข้า"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
