/**
*
* AdminCategoryDetailScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Card, message } from "antd";
import dataProvider from "utils/dataProvider";
import CategoryForm from "../../../components/CategoryForm";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class AdminCategoryDetailScreen extends Component { // eslint-disable-line
  state = {
    category: {},
  }

  provider = dataProvider()


  componentDidMount() {
    this.getCategory();
  }

  getCategory = async () => {
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/404");
    }
    const category = await this.provider.fetch.get(`/categories/${id}`, "", { noCache: true });
    this.setState({ category });
  }

  updatecategory = async (values) => {
    try {
      const { id } = this.props.match.params;
      await this.provider.fetch.patch(`/categories/${id}`, {
        ...values
      }, { noCache: true });
      message.success("update category success");
      this.props.history.push("/admin/categories");
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  deleteCategory = async () => {
    try {
      const { id } = this.props.match.params;
      await this.provider.fetch.delete(`/categories/${id}`, null, { noCache: true });
      message.success("delete category success");
      this.props.history.push("/admin/categories");
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }


  render() {
    return (
      <Container>
        <Card style={{ width: 600, textAlign: "center" }}>
          <CategoryForm
            onSubmit={this.updatecategory}
            onDelete={this.deleteCategory}
            category={this.state.category}
          />
        </Card>
      </Container>
    );
  }
}

AdminCategoryDetailScreen.propTypes = {

};

AdminCategoryDetailScreen.defaultProps = {

};
export default compose(
  withRouter,
)(AdminCategoryDetailScreen);
