/**
*
* AdminEditPromotionScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Card, message } from "antd";
import dataProvider from "utils/dataProvider";
// import _isEmpty from 'lodash/isEmpty'
// import _get from 'lodash/get'
import PromotionForm from "../../../components/PromotionForm";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class AdminEditPromotionScreen extends Component { // eslint-disable-line
  state = {
    promotion: {},
  }

  provider = dataProvider()

  componentDidMount() {
    this.getPromotion();
  }

  getPromotion = async () => {
    const { id, promotionId } = this.props.match.params;
    if (!id) {
      this.props.history.push("/404");
    }
    const promotion = await this.provider.fetch.get(`/promotions/${promotionId}`, {
      filter: {
        include: "productDetail",
        where: { productId: id }
      }
    }, { noCache: true });
    // const promotion = await provider.fetch.get(`/products/${id}/promotions/${promotionId}`, {
    //   filter: {
    //     include: ' productDetails'
    //   }
    // }, { noCache: true })
    this.setState({ promotion });
  }

  updatePromotion = async (values) => {
    try {
      const { id, promotionId } = this.props.match.params;
      await this.provider.fetch.put(`/products/${id}/promotions/${promotionId}`, values, { noCache: true });
      message.success("update Promotion success");
      this.props.history.push(`/admin/products/${id}`);
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  deletePromotion = async () => {
    try {
      const { id, promotionId } = this.props.match.params;
      await this.provider.fetch.delete(`/products/${id}/promotions/${promotionId}`, null, { noCache: true });
      message.success("Delete Promotion success");
      this.props.history.push(`/admin/products/${id}`);
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  render() {
    return (
      <Container>
        <Card style={{ width: 600, textAlign: "center" }}>
          <PromotionForm
            onSubmit={this.updatePromotion}
            promotion={this.state.promotion}
            deletePromotion={this.deletePromotion}
          />
        </Card>
      </Container>
    );
  }
}

AdminEditPromotionScreen.propTypes = {

};

AdminEditPromotionScreen.defaultProps = {

};
export default compose(
  withRouter,
)(AdminEditPromotionScreen);
