/**
 *
 * AdminUserListScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import dataProvider from "utils/dataProvider";
import { Link } from "react-router-dom";
import { Table, Icon } from "antd";

dayjs.extend(relativeTime);

const Container = styled.div``;
const RefreshContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Refresh = styled.button`
  margin-right: 10px;
`;

class AdminBannerListScreen extends Component {
  // eslint-disable-line
  state = {
    bannerList: [],
  };

  provider = dataProvider();

  componentDidMount() {
    this.fetchBanner();
  }

  fetchBanner = async () => {
    const bannerList = await this.provider.fetch.get("/banners", null, {
      noCache: true,
    });
    this.setState({ bannerList });
  };

  removeBanner = async (id) => {
    await this.provider.fetch.delete(`/banners/${id}`, null, { noCache: true });
    this.fetchBanner();
  };

  render() {
    const columns = [
      {
        title: "รูป",
        dataIndex: "image",
        key: "image",
        render: (id, record) => (
          <img
            alt=""
            src={record.image}
            width={150}
            height="auto"
            style={{ objectFit: "contain" }}
          />
        ),
      },
      {
        title: "หน้า",
        dataIndex: "page",
        key: "page",
      },
      {
        title: "ตำแหน่ง",
        dataIndex: "position",
        key: "position",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link to={`/admin/banners/${record.id}`}>ดูข้อมูล</Link>
          </span>
        ),
      },
      {
        title: "ลบ",
        key: "deleted",
        render: (text, record) => (
          <div
            onClick={() => this.removeBanner(record.id)}
            style={{ cursor: "pointer" }}
          >
            {console.log(record)}
            <Icon type="delete" />
          </div>
        ),
      },
    ];

    return (
      <Container>
        <RefreshContainer>
          <Refresh onClick={() => this.fetchBanner()}>Refresh</Refresh>
        </RefreshContainer>
        <Table
          columns={columns}
          dataSource={this.state.bannerList}
          rowKey={(record) => record.id}
        />
      </Container>
    );
  }
}

AdminBannerListScreen.propTypes = {};

AdminBannerListScreen.defaultProps = {};
export default AdminBannerListScreen;
