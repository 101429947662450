/**
*
* AdminCreateNewsScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Form, Input, Button, Card, Typography } from "antd";
import dataProvider from "utils/dataProvider";
import TextEditor from "components/TextEditor";
import Swal from "sweetalert2";
// import PropTypes from 'prop-types'

const { Text } = Typography;
const Container = styled.div`

`;
const CardContainer = styled.div`
  display: flex;
  justify-content: center;
`;


class AdminCreateNewsScreen extends Component { // eslint-disable-line
  provider = dataProvider()

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await this.provider.fetch.post("/news", { ...values }, { noCache: true });
          Swal.fire({
            title: "Success!",
            text: "สร้างข่าวเรียบร้อย",
            type: "success",
            confirmButtonText: "OK",
            onAfterClose: () => this.props.history.push("/admin/news"),
          });
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: error.toString(),
            type: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  }

  render() {
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    getFieldDecorator("full_text");
    return (
      <Container>
        <CardContainer>
          <Card style={{ width: "100%", maxWidth: 1250 }}>
            <Text>สร้างข่าวสารใหม่</Text>

            <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onSubmit={this.handleSubmit}>
              <Form.Item label="หัวข้อ" wrapperCol={{ span: 12 }}>
                {getFieldDecorator("title", {
                  rules: [
                    { required: true, message: "กรุณากรอกหัวข้อข่าว" },
                  ],
                })(<Input />)}
              </Form.Item>
              <TextEditor onChange={(html) => setFieldsValue({ full_text: html })} />
              <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </CardContainer>
      </Container>
    );
  }
}

AdminCreateNewsScreen.propTypes = {

};

AdminCreateNewsScreen.defaultProps = {

};

export default compose(
  Form.create(),
  withRouter,
)(AdminCreateNewsScreen);
