import React from "react";
import { compose } from "recompose";
import { Input, Switch, Form, Button, Popconfirm } from "antd";
import _get from "lodash/get";

class CategoryForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  }

  render () {
    const { form: { getFieldDecorator } } = this.props;
    return (
      <>
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
          <Form.Item label="ชื่อหมวดหมู่">
            {getFieldDecorator("name", {
              rules: [
                { required: true, message: "กรุณากรอกชื่อหมวดหมู่" },
              ], })(<Input />)}
          </Form.Item>
          <Form.Item label="คิดcoin">
            {getFieldDecorator("is_calculate_coin", { initialValue: true, valuePropName: "checked" })(<Switch />)}
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
            {
              _get(this, "props.category.id") && (
              <Popconfirm
                title="ต้องการที่จะลบหมวดหมู่นี้ใช่หรอไม่?"
                onConfirm={this.props.onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger" style={{ marginRight: "20px" }}>
                  ลบ
                </Button>
              </Popconfirm>
              )
            }
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default compose(
  Form.create({
    mapPropsToFields ({ category }) {
      if (!category) return {};
      const {
        name,
        is_calculate_coin
      } = category;
      return {
        name: Form.createFormField({
          value: name
        }),
        is_calculate_coin: Form.createFormField({
          value: is_calculate_coin
        }),
      };
    }
  })
)(CategoryForm);
