/**
*
* AdminCreateProductScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Card, message } from "antd";
import dataProvider from "utils/dataProvider";
import ProductForm from "../../../components/ProductForm";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class AdminCreateProductScreen extends Component { // eslint-disable-line
  state = {
    categories: [],
  }

  provider = dataProvider()

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    const categories = await this.provider.fetch.get("/categories", null, { noCache: true });
    this.setState({ categories });
  }

  createProduct = async (values) => {
    try {
      await this.provider.fetch.post("/products", {
        ...values
      }, { noCache: true });
      message.success("create product success");
      this.props.history.push("/admin/products");
    } catch (ex) {
      const resErr = _.get(ex, "response");
      const msg = _.get(resErr, "data.error.message");
      if (resErr.status === 422) {
        message.error(msg);
      } else {
        message.error("มีข้อผิดพลาดเกิดขึ้น");
      }
    }
  }

  render() {
    return (
      <Container>
        <Card style={{ width: 600, textAlign: "center" }}>
          <ProductForm
            onSubmit={this.createProduct}
            categories={this.state.categories}
          />
        </Card>
      </Container>
    );
  }
}

AdminCreateProductScreen.propTypes = {

};

AdminCreateProductScreen.defaultProps = {

};
export default compose(
  withRouter,
)(AdminCreateProductScreen);
