import { Upload, Icon, message, Modal } from "antd";
import React from "react";
import Swal from "sweetalert2";
import dataProvider from "utils/dataProvider";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class UploadImage extends React.Component {
  state = {
    loading: false,
    fileList: [],
    previewVisible: false,
    previewImage: ""
  };

  provider = dataProvider()

  uploadImage = (file) => {
    this.setState({ loading: true }, async () => {
      try {
        const formData = new FormData();
        formData.append("media", file.file);
        const response = await this.provider.fetch.post("/uploadImages/upload", formData, { noCache: true });
        if (response) {
          const { location } = response;
          const fileList = [...this.state.fileList];
          const newFile = {
            uid: location,
            name: location,
            status: "done",
            url: location,
          };
          this.props.onUpload([...fileList, newFile]);
          this.setState({ loading: false, fileList: [...fileList, newFile] });
        }
      } catch (error) {
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        this.setState({ loading: false });
      }
    });
  }

  handleChange = ({ fileList, file }) => {
    if (file.status === "removed") {
      this.props.onUpload(fileList);
    }
    this.setState({ fileList: fileList.filter(i => i.status === "done") });
  }

  handlePreview = file => {
    this.setState({ previewImage: file.url, previewVisible: true });
  }

  handleCancel = () => this.setState({ previewVisible: false, previewImage: "" });

  render() {
    const { fileList = [], previewVisible, previewImage } = this.state;
    const { maxFiles = 5 } = this.props;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
        // showUploadList={false}
          fileList={this.state.fileList}
          customRequest={this.uploadImage}
          beforeUpload={beforeUpload}
          onChange={this.handleChange}
          onPreview={this.handlePreview}
        >
          {fileList.length >= maxFiles ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
          destroyOnClose
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default UploadImage;
