/* eslint-disable no-console */
/**
 *
 * AdminIncomeOrderDetailScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import html2canvas from "html2canvas";
import _get from "lodash/get";
// import Swal from 'sweetalert2'
import {
  Table,
  Typography,
  Icon,
  InputNumber,
  Row,
  Col,
  Button,
  Popconfirm,
  Card,
} from "antd";
import dataProvider from "utils/dataProvider";

// import PropTypes from 'prop-types'
const { Text, Title, Paragraph } = Typography;

const Container = styled.div``;

const AddressTitile = styled(Title)`
  margin-top: 10px !important ;
`;
const SumPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #999;
  padding: 10px 10px 0 10px;
`;
const Amount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #999;
  background-color: #3e3735;
  padding: 10px 10px 0 10px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
`;
const ActionContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
`;

class AdminIncomeOrderDetailScreen extends Component {
  // eslint-disable-line
  state = {
    initItem: [],
    items: [],
    order: {},
    showCanvas: false,
    poImageBase64: null,
    editMode: false,
  };

  provider = dataProvider();

  componentDidMount() {
    this.fetchOrder().then(this.generatePoImage);
  }

  fetchOrder = async () => {
    const orderId = this.props.match.params.id;
    const body = {
      filter: {
        include: [
          "myusers",
          {
            relation: "orderItems",
            scope: {
              include: [
                "promotions",
                {
                  relation: "products",
                  scope: {
                    isDeleted: true,
                  },
                },
              ],
            },
          },
        ],
      },
    };
    const order = await this.provider.fetch.get(`/orders/${orderId}`, body, {
      noCache: true,
    });
    const items = order.orderItems.sort((a, b) => a.productId - b.productId);
    this.setState({ items, initItem: items, order });
  };

  removeItem = async (id) => {
    await this.provider.fetch.delete(`/order_items/${id}`, null, {
      noCache: true,
    });
    this.fetchOrder().then(this.generatePoImage);
  };

  removeOrder = async (id) => {
    await this.provider.fetch.delete(`/orders/${id}/cancelOrder`, null, {
      noCache: true,
    });
    this.props.history.push("/admin/orders/incoming");
  };

  confirmOrder = async (id) => {
    try {
      await this.provider.fetch.post(
        `/orders/${id}/confirmOrder`,
        { poImage: this.state.poImageBase64 },
        { noCache: true }
      );
      this.props.history.push("/admin/orders/incoming");
    } catch (error) {
      console.error(error);
    }
  };

  updateOrder = async () => {
    const { id } = this.state.order;
    const newItems = this.state.items.map((item) => {
      const i = { ...item };
      delete i.id;
      return {
        ...i,
        sum_price: Number(i.product_price) * Number(i.quantity),
      };
    });
    await this.provider.fetch.post(
      `/orders/${id}/updateOrderItems`,
      { orderItems: newItems },
      { noCache: true }
    );
    this.fetchOrder().then(this.generatePoImage);
    this.setState({ editMode: false });
  };

  printPO = () => {
    const content = document.getElementById("printTable");
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  generatePoImage = () => {
    const ele = document.getElementById("printTable");
    this.setState({ showCanvas: true }, () => {
      html2canvas(ele).then((canvas) => {
        const poImageBase64 = canvas.toDataURL();
        this.setState({ showCanvas: false, poImageBase64 });
      });
    });
  };

  changeQuantity = (e, record) => {
    const stateItems = this.state.items;
    const items = [...stateItems];
    const indexItem = items.findIndex((i) => i.id === record.id);
    items.splice(indexItem, 1, { ...record, quantity: Number(e) });
    this.setState({ items });
  };

  render() {
    const { order, items: itemList } = this.state;
    const columns = [
      {
        title: "ลำดับ",
        key: "id",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "รายละเอียดสินค้า",
        key: "product",
        dataIndex: "product_name",
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {_get(record, "promotions.product_image") ? (
              <div style={{ marginRight: 20 }}>
                <img src={record.promotions.product_image} alt="" width={80} />
              </div>
            ) : (
              <div style={{ marginRight: 20 }}>
                <img src={record.products.product_image} alt="" width={80} />
              </div>
            )}
            <Paragraph>
              <Text>{record.product_name}</Text>
            </Paragraph>
          </div>
        ),
      },
      {
        title: "หน่วย",
        dataIndex: "product_unit",
        key: "product_unit",
      },
      {
        title: "ราคาต่อหน่วย(บาท)",
        dataIndex: "product_price",
        key: "productDetail.price",
      },
      {
        title: "จำนวน",
        dataIndex: "quantity",
        key: "quantity",
        render: (text, record) => (
          <InputNumber
            style={{ width: 80 }}
            value={record.quantity}
            onChange={(e) => this.changeQuantity(e, record)}
            disabled={!this.state.editMode}
            min={1}
          />
        ),
      },
      {
        title: "มูลค้าสินค้า",
        key: "amount",
        render: (text, record) => (
          <span>{record.quantity * Number(record.product_price)}</span>
        ),
      },
      {
        title: "",
        key: "action",
        render: (text, record) => this.state.editMode ? (
          <Popconfirm
            title="Are you sure delete this item?"
            onConfirm={() => this.removeItem(record.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
          >
            <span>
              <Icon
                type="delete"
                style={{ fontSize: 24, cursor: "pointer" }}
              />
            </span>
          </Popconfirm>
        ) : null,
      },
    ];
    const sumPrice = itemList.reduce((acc, cur) => {
      acc += cur.quantity * Number(cur.product_price);
      return acc;
    }, 0);
    const user = this.state.order.myusers || {};
    const defaultStylePo = {
      width: "100%",
      maxWidth: 800,
      height: this.state.items.length * 90,
      minHeight: 1000,
      position: "absolute",
      top: 0,
      left: "4000px",
    };
    return (
      <>
        <Container>
          <iframe
            title="print"
            id="ifmcontentstoprint"
            style={{ height: 0, width: 0, position: "absolute" }}
          />
          <div>
            <Card style={{ marginBottom: 10 }}>
              <Row>
                <Col xs={24} md={18}>
                  <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
                    <AddressTitile level={4}>
                      ชื่อร้านค้า : {user.shop_name}
                    </AddressTitile>
                  </Paragraph>
                  <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
                    <AddressTitile level={4}>
                      ชื่อลูกค้า : {user.full_name}
                    </AddressTitile>
                  </Paragraph>
                  <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
                    <AddressTitile level={4}>
                      เบอร์โทรศัพท์ : {user.phone} เบอร์มือถือ : {user.mobile}
                    </AddressTitile>
                  </Paragraph>
                  <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
                    <AddressTitile level={4}>
                      ที่อยู่ : {user.address}
                    </AddressTitile>
                  </Paragraph>
                </Col>
                <Col xs={24} md={6} style={{ textAlign: "right" }}>
                  <div>
                    <Button
                      icon="printer"
                      size="large"
                      style={{ margin: 10 }}
                      onClick={this.printPO}
                    >
                      Print PO
                    </Button>
                  </div>
                  <div>
                    <a
                      href={this.state.poImageBase64}
                      download={`PO-${_get(
                        order,
                        "myusers.shop_name",
                        ""
                      )}-${Date.now()}`}
                    >
                      <Button
                        icon="download"
                        size="large"
                        style={{ margin: 10 }}
                      >
                        Save PO
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Card>
            <ButtonContainer>
              {this.state.editMode ? (
                <>
                  <Button
                    type="danger"
                    onClick={() => {
                      // eslint-disable-next-line
                      this.setState({
                        editMode: false,
                        items: [...this.state.initItem],
                      });
                    }}
                    style={{ marginRight: 30 }}
                  >
                    ยกเลิก
                    <Icon type="sae" />
                  </Button>
                  <Button type="primary" onClick={this.updateOrder}>
                    ยืนยัน
                    <Icon type="save" />
                  </Button>
                </>
              ) : (
                <Button
                  type="primary"
                  onClick={() => this.setState({ editMode: true })}
                >
                  แก้ไขใบ PO
                  <Icon type="shopping" />
                </Button>
              )}
            </ButtonContainer>
            <Table
              columns={columns}
              dataSource={itemList}
              rowKey={(record) => record.id}
              pagination={false}
            />
            <Row>
              <Col
                xs={24}
                lg={{
                  span: 16,
                  offset: 8,
                }}
                style={{ padding: 20 }}
              >
                <SumPrice>
                  <Title level={4}>ราคาสินค้า</Title>
                  <Text level={4}>
                    {`${sumPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </SumPrice>
                <SumPrice>
                  <Title level={4}>ค่าจัดส่ง</Title>
                  <Text level={4}>
                    {`${order?.delivery_cost || 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}
                  </Text>
                </SumPrice>
                <Amount>
                  <Title level={4} style={{ color: "white" }}>
                    รวมทั้งหมด
                  </Title>
                  <Text level={4} style={{ color: "white" }}>
                    {`${order.total_cost || 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}{" "}
                    บาท
                  </Text>
                </Amount>
              </Col>
            </Row>
          </div>
          <ActionContainer>
            <div>
              <Button
                onClick={() => this.props.history.push("/admin/orders/incoming")
                }
              >
                BACK
              </Button>
            </div>
            <div>
              <Popconfirm
                title="ยกเลิกคำสั่งซื้อนี้ทั้งหมด ยืนยัน?"
                onConfirm={() => this.removeOrder(order.id)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
                icon={
                  <Icon type="question-circle-o" style={{ color: "red" }} />
                }
              >
                <Button type="danger" style={{ marginRight: 20 }}>
                  CANCEL ORDER
                </Button>
              </Popconfirm>
              <Button
                type="primary"
                onClick={() => this.confirmOrder(order.id)}
              >
                CONFIRM
              </Button>
            </div>
          </ActionContainer>
        </Container>

        <div
          id="printTable"
          style={
            this.state.showCanvas
              ? {
                ...defaultStylePo,
                padding: 30,
              }
              : {
                ...defaultStylePo,
                display: "none",
              }
          }
        >
          <div style={{ marginBottom: 40 }}>
            <AddressTitile level={4}>ร้าน: {user.shop_name}</AddressTitile>
            <AddressTitile level={4}>ชื่อ: {user.full_name}</AddressTitile>
            <AddressTitile level={4}>ที่อยู่: {user.address}</AddressTitile>
            <AddressTitile level={4}>
              เบอร์โทรศัพท์: {user.mobile}
            </AddressTitile>
          </div>
          <table
            style={{ width: "100%", tableLayout: "fixed" }}
            id="divcontents"
          >
            <colgroup>
              <col width="5%" />
              <col width="10%" />
              <col width="47%" />
              <col width="15%" />
              <col width="10%" />
              <col width="13%" />
            </colgroup>
            <thead style={{ padding: 5, backgroundColor: "#ededed" }}>
              <tr>
                <th style={{ padding: "10px" }}>
                  <h4>#</h4>
                </th>
                <th style={{ padding: "10px" }}>
                  <h4>รหัส</h4>
                </th>
                <th style={{ padding: "10px" }}>
                  <h4>ชื่อสินค้า</h4>
                </th>
                <th style={{ padding: "10px" }}>
                  <h4>ราคาต่อหน่วย</h4>
                </th>
                <th style={{ padding: "10px" }}>
                  <h4>จำนวน</h4>
                </th>
                <th style={{ padding: "10px" }}>
                  <h4>ราคารวม</h4>
                </th>
              </tr>
            </thead>
            <tbody style={{ background: "white" }}>
              {this.state.items.map((i, index) => (
                <tr key={i.id}>
                  <td
                    style={{
                      padding: "10px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <p>{index + 1}</p>
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <p>{i.products.code}</p>
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <p>{i.product_name}</p>
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <p>
                      {i.product_price} / {i.product_unit}
                    </p>
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <p>{i.quantity}</p>
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <p>{i.sum_price}</p>
                  </td>
                </tr>
              ))}
            </tbody>
            <tbody style={{ padding: 5, backgroundColor: "#ededed" }}>
              <tr>
                <td style={{ padding: "10px" }} />
                <td style={{ padding: "10px" }} />
                <td style={{ padding: "10px" }} />
                <td style={{ padding: "10px" }} />
                <td style={{ padding: "10px" }}>
                  <h4>Total</h4>
                </td>
                <td style={{ padding: "10px" }}>
                  <h4>${this.state.order.amount}</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

AdminIncomeOrderDetailScreen.propTypes = {};

AdminIncomeOrderDetailScreen.defaultProps = {};
export default AdminIncomeOrderDetailScreen;
