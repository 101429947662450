/* eslint-disable react/no-access-state-in-setstate */
/**
*
* AdminUserDetailScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Button, Descriptions, Card, InputNumber, Modal, Popconfirm } from "antd";
import dataProvider from "utils/dataProvider";
// import PropTypes from 'prop-types'


// const { Text } = Typography

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;
class AdminUserDetailScreen extends Component { // eslint-disable-line
  state = {
    user: {},
    visible: false,
    image: "",
  }

  provider = dataProvider()

  componentDidMount() {
    this.getUser();
  }

  getUser = async () => {
    try {
      const { id } = this.props.match.params;
      if (!id) {
        this.props.history.push("/404");
      }
      const body = {
        filter: {
          include: "role",
        },
      };
      const user = await this.provider.fetch.get(`/myusers/${id}`, body, { noCache: true });
      if (!user.emailVerified) {
        this.props.history.push(`/admin/users/validate/${user.id}`);
      }
      this.setState({ user });
    } catch (error) {
      this.props.history.push("/404");
    }
  }

  deleteUser = async () => {
    try {
      await this.provider.fetch.delete(`/myusers/${this.state.user.id}`, null, { noCache: true });
      this.props.history.push("/admin/users");
    } catch (error) {
      console.log(error);
    }
  }

  updateDueDate = async () => {
    const body = {
      due_date_limit: this.state.user.due_date_limit,
    };
    try {
      await this.provider.fetch.patch(`/myusers/${this.state.user.id}`, body, { noCache: true });
      this.props.history.push("/admin/users");
    } catch (error) {
      console.log(error);
    }
  }

  handleCancel = () => this.setState({ visible: false, image: "" })

  showModal = (image) => {
    this.setState({ visible: true, image });
  }

  render() {
    const { id } = this.props.match.params;
    const {
      license_image,
      professional_license_image,
      email,
      shop_name,
      license_number,
      address,
      full_name,
      mobile, phone,
      due_date_limit,
    } = this.state.user;
    return (
      <>

        <Container>
          <Card style={{ width: 1200, textAlign: "center" }}>
            <Link to={`/admin/users/${id}/orders`}>
              ดู orders ทั้งหมด ของร้าน {shop_name}
            </Link>
          </Card>
        </Container>
        <br />
        <Container>
          <Card style={{ width: 1200, textAlign: "center" }}>
            <Descriptions title="User Info" bordered>
              <Descriptions.Item label="ใบอนุญาตขายยา" span={3}>
                <div onClick={() => this.showModal(license_image)} style={{ cursor: "pointer" }}>
                  <img src={license_image} height={200} alt="" />
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="ใบประกอบวิชาชีพ" span={3}>
                <div onClick={() => this.showModal(professional_license_image)} style={{ cursor: "pointer" }}>
                  <img src={professional_license_image} height={200} alt="" />
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="อีเมล" span={3}>{email}</Descriptions.Item>
              <Descriptions.Item label="ร้าน" span={3}>{shop_name}</Descriptions.Item>
              <Descriptions.Item label="เลขใบอนุญาตขายยา" span={3}>{license_number}</Descriptions.Item>
              <Descriptions.Item label="ที่อยู่" span={3}>{address}</Descriptions.Item>
              <Descriptions.Item label="ชื่อลูกค้า" span={3}>{full_name}</Descriptions.Item>
              <Descriptions.Item label="เบอร์มือถือ" span={3}>{mobile}</Descriptions.Item>
              <Descriptions.Item label="เบอร์ร้าน" span={3}>{phone}</Descriptions.Item>
              <Descriptions.Item label="เครดิตร้านค้า(วัน)" span={3}>
                <InputNumber
                  min={0}
                  max={100}
                  value={due_date_limit}
                  onChange={e => this.setState({ user: { ...this.state.user, due_date_limit: e } })}
                />
              </Descriptions.Item>
            </Descriptions>
            <ButtonContainer>
              <Button onClick={this.props.history.goBack}>Back</Button>
              <Popconfirm
                title="ต้องการลบยูสเซอร์คนนี้ ?"
                onConfirm={this.deleteUser}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">
                  Remove
                </Button>
              </Popconfirm>
              <Button type="primary" onClick={this.updateDueDate}>Edit</Button>
            </ButtonContainer>
          </Card>
          <Modal
            visible={this.state.visible}
            footer={null}
            onCancel={this.handleCancel}
            destroyOnClose
            width={1000}
          >
            <img
              alt="example"
              style={{ width: "100%", margin: 10 }}
              src={this.state.image}
            />
          </Modal>
        </Container>

      </>
    );
  }
}

AdminUserDetailScreen.propTypes = {

};

AdminUserDetailScreen.defaultProps = {

};
export default compose(
  withRouter,
)(AdminUserDetailScreen);
