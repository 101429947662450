import { Upload, Icon, message } from "antd";
import React from "react";
import Swal from "sweetalert2";
import dataProvider from "utils/dataProvider";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class UploadImage extends React.Component {
  state = {
    loading: false,
    imageUrl: "",
  };

  provider = dataProvider();

  uploadImage = (file) => {
    this.setState({ loading: true }, async () => {
      try {
        const formData = new FormData();
        formData.append("media", file.file);
        const response = await this.provider.fetch.post(
          "/uploadImages/uploadWithoutWatermark",
          formData,
          { noCache: true }
        );
        if (response) {
          const { location } = response;
          this.props.onUpload(location);
          this.setState({ imageUrl: location, loading: false });
        }
      } catch (error) {
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { imageUrl } = this.state;
    const image = imageUrl || this.props.defaultImage;
    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={this.uploadImage}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {image ? (
          <img src={image} alt="avatar" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
}

export default UploadImage;
