import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import 'antd/dist/antd.css'
import "./global.css";

import UserLayout from "screen/UserLayout";
import RegisterScreen from "screen/RegisterScreen";
// import MobileLayout from 'components/MobileLayout'
import PromoteScreen from "screen/PromoteScreen";
import AdminLayout from "../AdminLayout/index";
import LoginScreen from "../LoginScreen/index";
import NotFoundPage from "../NotFoundPage/index";
import SlipLayout from "../SlipLayout";
import style from "./App.less";

class App extends Component {
  // state = {
  //   isMobile: false
  // }

  componentDidMount() {
    // const isMobile = this.mobileCheck()
    // this.setState({ isMobile })
    this.getUser();
  }

  getUser = async () => {
    try {
      await this.props.getUserInfoAsync();
    } catch (error) {
      console.log("get usert info error");
    }
  };

  componentDidCatch(error, info) {
    console.dir("log from App", error);
    console.log("log from App", info);
  }

  render() {
    // if (this.state.isMobile) {
    //   return (
    //     <MobileLayout>
    //       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60vh', flexDirection: 'column' }}>
    //         <h1 style={{ fontSize: 24, textAlign: 'center' }}>กรุณาเปิดใช้งานบน PC <br />หรือ Tablet เท่านั้น <br />ขออภัยในความไม่สะดวก</h1>
    //       </div>
    //     </MobileLayout>
    //   )
    // }
    return (
      <div className={style.App}>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={LoginScreen} />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/promote" component={PromoteScreen} />
            <Route path="/404" component={NotFoundPage} />
            <Route path="/admin" component={AdminLayout} />
            <Route path="/slip" component={SlipLayout} />
            <Route path="/" component={UserLayout} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

App.propTypes = {
  getUserInfoAsync: PropTypes.func.isRequired,
};

const mapDispatchToProps = ({ auth }) => ({
  getUserInfoAsync: auth.getUserInfoAsync,
});

export default connect(null, mapDispatchToProps)(App);
