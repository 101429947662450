// import { createSelector } from 'reselect';
// import _groupBy from 'lodash/groupBy'
import dataProvider from "../utils/dataProvider";


const isJSON = (data) => {
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
};

export const cart = {
  state: {
    items: isJSON(localStorage.items) ? JSON.parse(localStorage.items) : {},
    deliveryCost: null,
  }, // initial state
  reducers: {
    addItem (state, payload) {
      const items = { ...state.items };
      items[payload.productDetail.id] = Number(payload.quantity) > 0
        ? { ...payload, quantity: Math.floor(payload.quantity) } : { ...payload, quantity: 1 };
      localStorage.items = JSON.stringify(items);
      return {
        ...state,
        items
      };
    },
    setItems (state, payload) {
      localStorage.items = JSON.stringify(payload);
      return {
        ...state,
        items: payload
      };
    },
    deleteItem (state, productDetailId) {
      const items = { ...state.items };
      delete items[productDetailId];
      localStorage.items = JSON.stringify(items);
      return {
        ...state,
        items
      };
    },
    clearItems (state) {
      localStorage.items = undefined;
      return {
        ...state,
        items: {}
      };
    },
    setDeliveryCost (state, payload) {
      return {
        ...state,
        deliveryCost: payload
      };
    }
  },
  effects: () => ({
    async checkExpireOrder (payload) {
      const provider = dataProvider();
      try {
        const result = await provider.fetch.post("/orders/checkExpireOrder", { orderItems: payload }, { noCache: true });
        this.setItems(result.items);
        return result.expire || [];
      } catch (error) {
        throw error;
      }
    },
    async getDeliveryCost (payload = {}) {
      const productDeliveryDetails = Object.values(payload).map(p => ({
        product_weight: p.productDetail.weight,
        product_fixed_delivery_cost: p.productDetail.fixed_delivery_cost,
        quantity: p.quantity
      }));
      const provider = dataProvider();
      try {
        const result = await provider.fetch.post("/orders/getDeliveryCost", { orderItems: productDeliveryDetails }, { noCache: true });
        this.setDeliveryCost(result.deliveryCost);
      } catch (error) {
        throw error;
      }
    },
    async createOrderAsync (payload) {
      const provider = dataProvider();
      try {
        const data = {
          mobile: payload.mobile,
          amount: payload.amount,
          items: payload.itemList.map(i => ({
            id: i.id,
            productDetailId: i.productDetail.id,
            promotionId: i.promotionsId || null,
            quantity: i.quantity
          }))
        };
        await provider.fetch.post("/orders", data, { noCache: true });
        this.clearItems();
        return true;
      } catch (error) {
        throw error;
      }
    }
  })
};
