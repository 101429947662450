/**
*
* AdminAccountantScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { Tabs } from "antd";
// import PropTypes from 'prop-types'
import AccountUnPaid from "components/AccountUnPaid";
import AccountPending from "components/AccountPending";
import AccountAllOrder from "components/AccountAllOrder";
import AccountPad from "components/AccountPaid";

const Container = styled.div`

`;
const { TabPane } = Tabs;

class AdminAccountantScreen extends Component { // eslint-disable-line
  render() {
    return (
      <Container>
        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab="ทั้งหมด" key="1">
            <AccountAllOrder />
          </TabPane>
          <TabPane tab="ยังไม่จ่าย" key="2">
            <AccountUnPaid />
          </TabPane>
          <TabPane tab="รอตรวจสอบ" key="3">
            <AccountPending />
          </TabPane>
          <TabPane tab="เรียบร้อยแล้ว" key="4">
            <AccountPad />
          </TabPane>
        </Tabs>
      </Container>
    );
  }
}

AdminAccountantScreen.propTypes = {

};

AdminAccountantScreen.defaultProps = {

};
export default AdminAccountantScreen;
