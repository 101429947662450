import { Table } from "antd";
import styled from "styled-components";

export const CustomTable = styled(Table)`
  .ant-table-column-title {
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  td {
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .ant-table-content {
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    @media (max-width: 767px) {
      padding: 4px 8px;
    }
  }
  .ant-pagination-item {
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  button {
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
`;
