/**
*
* AdminCreatePromotionScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Card, message } from "antd";
import dataProvider from "utils/dataProvider";
// import _isEmpty from 'lodash/isEmpty'
// import _get from 'lodash/get'
import PromotionForm from "../../../components/PromotionForm";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class AdminCreatePromotionScreen extends Component { // eslint-disable-line
  state = {
    promotion: {},
  }

  provider = dataProvider()

  createPromotion = async (values) => {
    try {
      const { id } = this.props.match.params;
      await this.provider.fetch.post(`/products/${id}/promotions`, values, { noCache: true });
      message.success("create Promotion success");
      this.props.history.push(`/admin/products/${id}`);
    } catch (ex) {
      message.error("มีข้อผิดพลาดเกิดขึ้น");
    }
  }

  render() {
    return (
      <Container>
        <Card style={{ width: 600, textAlign: "center" }}>
          <PromotionForm
            onSubmit={this.createPromotion}
            promotion={this.state.promotion}
          />
        </Card>
      </Container>
    );
  }
}

AdminCreatePromotionScreen.propTypes = {

};

AdminCreatePromotionScreen.defaultProps = {

};
export default compose(
  withRouter,
)(AdminCreatePromotionScreen);
