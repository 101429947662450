/* eslint-disable eqeqeq */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { read, utils, writeFileXLSX } from "xlsx";
import { Button } from "antd";
import { saveAs } from "file-saver";

const getExcelFromFileAsync = (file) => new Promise((resolve) => {
  const stockFileReader = new FileReader();
  stockFileReader.readAsBinaryString(file);
  stockFileReader.onload = (event) => {
    const data = event.target.result;
    const workbook = read(data, { type: "binary" });
    const rowObject = utils
      .sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        { header: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "v", "w", "x", "y", "z"] }
      );

    resolve(rowObject);
  };
});

const getCodeFromCell = (cell) => {
  const cellSplit = cell.split(":");
  return cellSplit[0];
};

// function s2ab(s) {
//   const buf = new Array(s.length)
//   for (let i = 0; i != s.length; ++i) buf[i] = s?.charCodeAt(i) & 0xFF
//   return buf
// }

export const StockScreen = () => {
  const [isCalculate, setIsCalculate] = useState(false);
  const [stockFile, setStockFile] = useState(null);
  const [daily, setDaily] = useState([]);
  const onSubmit = async () => {
    setIsCalculate(true);
    const stockExcel = await getExcelFromFileAsync(stockFile);
    const mappedStockExcel = stockExcel.map(i => {
      if (typeof i.a === "number") {
        return {
          ...i,
          i: Number(i.i.replaceAll(",", ""))
        };
      }
      return i;
    });
    for (const file of daily) {
      const dailyFile = await getExcelFromFileAsync(file);
      const filterDailyRow = dailyFile
        .filter(i => i.a && i.h && !isNaN(i.a) && !isNaN(i.h) && getCodeFromCell(i.b));
      filterDailyRow.forEach(i => {
        const code = getCodeFromCell(i.b);
        const findIndex = mappedStockExcel.findIndex(d => d.b === code);
        const newData = {
          ...mappedStockExcel[findIndex],
          i: mappedStockExcel[findIndex].i - Number(i.h)
        };
        mappedStockExcel[findIndex] = newData;
      });
    }

    const dataSheet = utils.json_to_sheet(mappedStockExcel);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, dataSheet, "Summary");
    const bookBuffer = writeFileXLSX(workbook, "summary.xlsx");
    saveAs(bookBuffer, "test");
    setIsCalculate(false);
  };
  const onChangeStock = (e) => {
    setStockFile(e.target.files[0]);
  };
  const onChangeDaily = (e) => {
    setDaily([...e.target.files]);
  };
  return (
    <div>
      <div>
        <label htmlFor="stock" style={{ marginRight: 10 }}>
          ไฟล์ Stock:
        </label>
        <input type="file" multiple onChange={onChangeStock} name="stock" />
      </div>
      <div>
        <label htmlFor="daily" style={{ marginRight: 10 }}>
          ไฟล์ รายวัน:
        </label>
        <input type="file" onChange={onChangeDaily} name="daily" directory="" webkitdirectory="" accept=".xlsx" />
      </div>
      <div>
        <Button onClick={onSubmit} loading={isCalculate} type="primary">
          คำนวน
        </Button>
      </div>
    </div>
  );
};

StockScreen.displayName = "StockScreen";

export default StockScreen;
