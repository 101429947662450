import React from "react";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import "antd/lib/breadcrumb/style/index.less";
import { Icon, Menu } from "antd";

import PrivateRoute from "components/PrivateRoute";
import { routes } from "./routes";
// import ErrorPage from 'containers/ErrorPage/Loadable'
// import { ERR_NOT_FOUND } from 'containers/ErrorPage/constants'
/* eslint-disable object-property-newline */


// const NotFoundPage = (props) => (
//   <ErrorPage error={ERR_NOT_FOUND} {...props} />
// )

function joinPath(base, path) {
  let result = `${base}/${path}`;
  if (!(path && path.length > 0)) {
    result = `${base}`;
  }
  if (base.slice(-1) === "/") {
    result = `${base}${path}`;
  }
  return result;
}

function AdminBreadcrumbRecursive(basePath, r = routes) {
  return (
    <Switch>
      {r.map((route) => (
        <Route
          strict
          exact={!(route.children && route.children.length > 0)}
          key={joinPath(basePath, route.path)}
          path={joinPath(basePath, route.path)}
        >
          <span>
            {route.title && <span className="ant-breadcrumb-separator">/</span>}
            <span
              className="ant-breadcrumb-link"
            >
              {route.title}
            </span>
            {route.children && route.children.length > 0
              && AdminBreadcrumbRecursive(joinPath(basePath, route.path), route.children)}
          </span>
        </Route>
      ))}
    </Switch>
  );
}
function AdminMenuRecursive(basePath, r = routes, userRole = {}) {
  return r.map((route) => {
    const { role = [] } = route;
    if (role.length > 0 && !role.includes(userRole.name)) {
      return null;
    }
    let view = null;
    if (route.menu) {
      view = (
        <Menu.Item key={joinPath(basePath, route.path)}>
          <Link to={joinPath(basePath, route.path)}>
            <Icon type={route.icon} theme="outlined" />
            <span>{route.menu}</span>
          </Link>
        </Menu.Item>
      );
    }
    if (route.children && route.children.length > 0) {
      view = (
        <Menu.SubMenu
          title={(
            <span>
              <Icon type={route.icon} />
              <span>{route.menu}</span>
            </span>
          )}
          key={joinPath(basePath, route.path)}
        >
          {AdminMenuRecursive(joinPath(basePath, route.path), route.children, userRole)}
        </Menu.SubMenu>
      );
    }
    return view;
  });
}

export function AdminBreadcrumb(basePath, r = routes) {
  return (
    <div style={{ margin: "16px 0" }}>
      <Link className="ant-breadcrumb-link" to={`${basePath}`}>
        <Icon type="home" style={{ fontSize: 16 }} />
      </Link>
      {AdminBreadcrumbRecursive(basePath, r)}
    </div>
  );
}
export function AdminRouter(basePath, r = routes) {
  return (
    <Switch>
      <Route
        exact
        path="/slip"
      >
        <Redirect to={{ pathname: "/slip/employer" }} />
      </Route>
      {r.map((route) => (
        <PrivateRoute
          strict
          exact={!(route.children && route.children.length > 0)}
          key={joinPath(basePath, route.path)}
          path={joinPath(basePath, route.path)}
          component={route.component}
          role={route.role}
        >
          {route.children && route.children.length > 0
            && AdminRouter(joinPath(basePath, route.path), route.children)}
        </PrivateRoute>
      ))}
      <Route
        render={() => (
          <Redirect
            to={{
              pathname: "/404"
            }}
          />
        )
      }
      />
    </Switch>
  );
}
export function AdminMenu(basePath, userRole, r = routes) {
  return AdminMenuRecursive(basePath, r, userRole);
}
export default routes;
