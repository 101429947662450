/* eslint-disable implicit-arrow-linebreak */
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { FaCartPlus } from "react-icons/fa";
import { Modal, Col, Row, InputNumber, Button, Divider, Tag } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import noImage from "image/noimage.png";

const TextRed = styled.span`
  color: red;
`;

const CustomText = styled.div`
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const CustomModal = styled(Modal)`
  top: 20px !important;
  @media (min-width: 768px) {
    top: 100px !important;
    width: 90% !important;
    min-width: 90% !important;
  }
`;
const withProductModal = (Component) => {
  class WrapperClass extends React.Component {
    state = {
      openModal: false,
      productDetail: {},
      qty1: 1,
      qty2: 1,
      qty3: 1,
    };

    setQty = (type, value) => {
      this.setState({
        [type]: value,
      });
    };

    setOpenModal = (value) => {
      this.setState({
        openModal: value,
      });
    };

    findProduct = (productId) => {
      const productDetail = this.props.productList.find(
        (item) => item.id === productId
      );
      this.setState({ productDetail });
    };

    addProductDetail = (productDetail) => {
      this.setState({ productDetail });
    };

    checkPromotion = (data) => {
      const result = { ...data };
      if (!_isEmpty(data.promotions)) {
        result.productDetail = _get(data, "promotions[0].productDetail");
        result.name = _get(data, "promotions[0].name");
        result.product_image =
          _get(data, "promotions[0].product_image") ||
          _get(data, "product_image");
      }
      return result;
    };

    addItemCart = (type, index) => {
      const { productDetail } = this.state;
      const data = { ...productDetail };
      data.quantity = this.state[type];
      data.productDetail = _get(
        productDetail,
        `promotions[0].productDetail[${index}]`,
        _get(productDetail, `productDetail[${index}]`)
      );
      data.promotionsId = _get(productDetail, "promotions[0].id", null);
      data.name = _get(
        productDetail,
        "promotions[0].name",
        _get(productDetail, "name")
      );
      data.product_image = _get(
        productDetail,
        "promotions[0].product_image",
        _get(productDetail, "product_image")
      );
      this.props.addItem(data);
      this.setOpenModal(false);
    };

    render() {
      const props = {
        setOpenModal: this.setOpenModal,
        isShow: this.state.openModal,
        findProduct: this.findProduct,
        addProductDetail: this.addProductDetail,
      };

      const product = this.checkPromotion(this.state.productDetail);
      return (
        <>
          <Component {...this.props} productModal={props} />
          <CustomModal
            width="100%"
            visible={this.state.openModal}
            onOk={() => this.setOpenModal(false)}
            onCancel={() => this.setOpenModal(false)}
            style={{ maxWidth: 1400 }}
            footer={null}
            destroyOnClose
          >
            <div>
              <Row gutter={16}>
                <Col md={8}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "3px solid #9AC949",
                    }}
                  >
                    <img
                      style={{ maxHeight: "250px", objectFit: "scale-down" }}
                      width="100%"
                      height="100%"
                      src={_get(product, "product_image") || noImage}
                      alt={_get(product, "name")}
                      onError={(evt) => {
                        evt.target.src = noImage;
                      }}
                    />
                  </div>
                </Col>
                <Col md={16}>
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 20,
                      }}
                    >
                      <span className="" style={{ fontSize: 20 }}>
                        <CustomText>รหัสสินค้า</CustomText>{" "}
                      </span>
                      <CustomText>
                        {_get(product, "code")}
                        {_get(product, "promotions[0].active_date") && (
                          <Tag color="red">Promotion</Tag>
                        )}
                      </CustomText>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h1>{_get(product, "name")}</h1>
                      {_get(product, "promotions[0].active_date") && (
                        <h4>
                          <TextRed>ราคาพิเศษ</TextRed>
                          ตั้งแต่วันที่
                          <span>
                            {moment(
                              _get(product, "promotions[0].active_date")
                            ).format("DD/MM/YYYY HH:mm")}
                          </span>
                          &nbsp; ถึง
                          <span>
                            {" "}
                            {moment(
                              _get(product, "promotions[0].end_date")
                            ).format("DD/MM/YYYY HH:mm")}
                          </span>
                        </h4>
                      )}
                    </Col>
                  </Row>
                  {!_isEmpty(product.productDetail) &&
                    product.productDetail.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 0",
                          background: "#FAFFF2",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ marginBottom: 16 }}>จำนวนสั่ง</div>
                        <div style={{ marginBottom: 16 }}>
                          <InputNumber
                            onChange={(value) =>
                              this.setQty(`qty${index + 1}`, value)
                            }
                            defaultValue={1}
                            min={0}
                          />{" "}
                          x {_get(item, "unit_price")}
                        </div>
                        <div style={{ marginBottom: 16 }}>
                          <span style={{ fontWeight: "bold" }}>
                            {+_get(item, "price") *
                              this.state[`qty${index + 1}`]}{" "}
                            บาท
                          </span>
                        </div>
                        <div>
                          <Button
                            type="primary"
                            onClick={() =>
                              this.addItemCart(`qty${index + 1}`, index)
                            }
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              ใส่ตะกร้า
                              <FaCartPlus style={{ marginLeft: 10 }} />
                            </div>
                          </Button>
                        </div>
                      </div>
                    ))}
                  <Divider />
                  <Row style={{ margin: "30px 0" }}>
                    <Col style={{ fontSize: 20 }} md={4}>
                      <CustomText>บริษัท</CustomText>
                    </Col>
                    <Col className="" style={{ fontSize: 20 }} md={18}>
                      <CustomText>{_get(product, "factory")}</CustomText>
                    </Col>
                  </Row>

                  {/* <Row style={{ margin: '30px 0' }}>
                    <Col style={{ fontSize: 20 }} md={4}>หมวดหมู่</Col>
                    <Col className="" style={{ fontSize: 20 }} md={18}>{_get(product, 'categories', []).map(item => item.name).join(', ')}</Col>
                  </Row> */}

                  {/* <Row style={{ margin: '30px 0' }}>
                    <Col style={{ fontSize: 20 }} md={4}>รายละเอียด</Col>
                    <Col className="" style={{ fontSize: 20 }} md={18}>{_get(product, 'description')}</Col>
                  </Row> */}

                  <Row style={{ margin: "30px 0" }}>
                    <Col style={{ fontSize: 20 }} md={4}>
                      <CustomText>ตัวยา</CustomText>
                    </Col>
                    <Col className="" style={{ fontSize: 20 }} md={18}>
                      <CustomText>{_get(product, "drug_type")}</CustomText>
                    </Col>
                  </Row>

                  <Row style={{ margin: "30px 0" }}>
                    <Col style={{ fontSize: 20 }} md={4}>
                      <CustomText>รูปร่าง</CustomText>
                    </Col>
                    <Col className="" style={{ fontSize: 20 }} md={18}>
                      <CustomText>{_get(product, "shape")}</CustomText>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </CustomModal>
        </>
      );
    }
  }

  WrapperClass.propTypes = {};

  WrapperClass.defaultProps = {};
  return WrapperClass;
};

export default withProductModal;
