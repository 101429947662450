/**
 *
 * AdminCategoryListScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
// import PropTypes from 'prop-types'
import dataProvider from "utils/dataProvider";
import { Link } from "react-router-dom";
import { Table, Button } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/th";


const Container = styled.div``;
class SlipListScreen extends Component {
  // eslint-disable-line
  state = {
    employers: [],
  };

  provider = dataProvider();

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    const employers = await this.provider.fetch.get("/payment_slips/getSlipByPaymentDate", { filter: { limit: 200 } }, {
      noCache: true,
    });
    this.setState({ employers });
  };

  render() {
    const columns = [
      {
        title: "วันที่ออก",
        key: "payment_date",
        render: (text, record) => (
          <div>
            {dayjs(record.payment_date).locale("th").format("DD MMM YYYY")}
          </div>
        )
      },
      {
        title: "จำนวน",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "ดูรายละเอียด",
        key: "action",
        render: (text, record) => {
          const paymentDate = new Date(record.payment_date).toISOString();
          const dateBase64 = window.btoa(paymentDate);
          return (
            <span>
              <Link to={`/slip/payment/${dateBase64}`}>ดูข้อมูล</Link>
            </span>
          );
        },
      },
    ];
    return (
      <Container>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link to="/slip/payment/create">
            <Button>สร้างสลิปใหม่</Button>
          </Link>
        </div>
        <Table
          columns={columns}
          dataSource={this.state.employers}
          rowKey={(record) => record.id}
        />
      </Container>
    );
  }
}

SlipListScreen.propTypes = {};

SlipListScreen.defaultProps = {};
export default SlipListScreen;
