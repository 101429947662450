/**
*
* ProductScreen
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import { FaShoppingBasket } from "react-icons/fa";
import { Table, Pagination, Button, Row, Col } from "antd";
import qs from "query-string";
import { connect } from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import dataProvider from "utils/dataProvider";
import noImage from "image/noimage.png";
import withSearch from "../../../hoc/withSearch";
import withProductModal from "../../../hoc/withProductModal";
import NavSearch from "../../../components/NavSearch";
import ProductList from "../../../components/ProductList";

const Container = styled.div`

`;
class ProductScreen extends Component { // eslint-disable-line
  state = {
    // quantity: [],
    limit: 30
  }

  provider = dataProvider()

  componentDidMount () {
    const { limit } = this.state;
    const { name, categories, factory, skip = 0 } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    this.props.setValue(name, "name");
    this.props.setValue(categories, "categories");
    this.props.setValue(factory, "factory");
    this.props.onSearch({ name, categories, factory, limit, skip });
  }

  componentDidUpdate (prevProps) {
    const { limit } = this.state;
    if (prevProps.location.search !== this.props.location.search) {
      const { name, categories, factory, skip = 0 } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      this.props.onSearch({ name, categories, factory, limit, skip });
    }
  }

  addToWishList = async (productsId) => {
    await this.provider.fetch.post("/myusers/me/wishlists", { productsId, status: true }, { noCache: true });
    await this.props.getUserInfoAsync();
  }

  openProductModal = (record) => {
    this.props.productModal.findProduct(record.id);
    this.props.productModal.setOpenModal(true);
  }

  render () {
    const { skip = 0 } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { limit } = this.state;
    const columns = [
      {
        title: "รูปสินค้า",
        dataIndex: "promotions",
        key: "product_image",
        width: 100,
        align: "center",
        render: (data, record) => {
          if (_isEmpty(data)) {
            return (
              <div style={{ width: "100px", height: "100px" }}>
                <img
                  style={{ objectFit: "contain", maxWidth: 100, maxHeight: 100 }}
                  width="100%"
                  src={_get(record, "product_image") || noImage}
                  alt="product"
                  onError={(evt) => {
                    evt.target.src = noImage;
                  }}
                />
              </div>
            );
          }
          return (
            <div style={{ width: "100px", height: "100px" }}>
              <img
                style={{ objectFit: "contain", maxWidth: 100, maxHeight: 100 }}
                width="100%"
                src={_get(data, "[0].product_image") || _get(record, "product_image") || noImage}
                alt="product"
                onError={(evt) => {
                  evt.target.src = noImage;
                }}
              />
            </div>
          );
        }
      },
      {
        title: "ชื่อสินค้า",
        dataIndex: "promotions",
        key: "name",
        align: "center",
        render: (data, record) => {
          if (_isEmpty(data)) {
            return _get(record, "name");
          }
          return _get(data, "[0].name");
        }
      },
      {
        title: "ชื่อตัวยาสำคัญ",
        dataIndex: "drug_type",
        key: "drug_type",
        align: "center",
      },
      {
        title: "ราคา",
        dataIndex: "promotions",
        key: "price",
        align: "center",
        width: 200,
        render: (data, record) => {
          if (_isEmpty(data)) {
            return (
              <>
                {
                  !_isEmpty(record.productDetail) && record.productDetail.map((item) => (
                    <div>
                      {_get(item, "unit_price")}
                      {": "}
                      {_get(item, "price")}
                    </div>
                  ))
              }
              </>
            );
          }
          return (
            <>
              {
              data[0].productDetail.map((item) => (
                <div>
                  {_get(item, "unit_price")}
                  {": "}
                  {_get(item, "price")}
                </div>
              ))
            }
            </>
          );
        }
      },
      {
        title: "",
        key: "action",
        render: (text, record) => {
          const { status } = record;
          const alreadyWish = _get(this, "props.wishlist", []).find(item => (item.productsId === record.id && item.status)) || false;
          return (status ? (
            <Button
              disabled={!status}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.openProductModal(record);
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                สั่งซื้อ
                <FaShoppingBasket style={{ marginLeft: 10 }} />
              </div>
            </Button>
          ) : (
            <Button
              style={alreadyWish ? {} : { backgroundColor: "#ffbd00", color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.addToWishList(record.id);
              }}
              disabled={alreadyWish}
            >
              {
                alreadyWish ? "รับการแจ้งเตือนแล้ว" : "แจ้งเตือนสินค้าเข้า"
              }
            </Button>
          )
          );
        }
      }
    ];
    return (
      <Container>
        <NavSearch
          name={this.props.name}
          factory={this.props.factory}
          category={this.props.categories}
          search={this.props.search}
          setValue={this.props.setValue}
        />
        <Row>
          <Col xs={0} md={24}>
            <Table
              pagination={false}
              columns={columns}
              dataSource={this.props.productList}
              rowKey={record => record.id}
              // onRow={(record) => ({
              //   onClick: () => {
              //     const alreadyWish = _get(this, 'props.wishlist', [])
              //       .find(item => (item.productsId === record.id && item.status)) || false
              //     if (alreadyWish) {
              //       return null
              //     }
              //     return this.openProductModal(record)
              //   }
              // })}
            />
          </Col>
          <Col xs={24} md={0}>
            <ProductList
              productList={this.props.productList}
              findProduct={this.props.productModal.findProduct}
              setOpenModal={this.props.productModal.setOpenModal}
              wishlist={_get(this, "props.wishlist", [])}
              addToWishList={this.addToWishList}
            />
          </Col>
        </Row>
        <div>
          <Pagination
            total={this.props.countProduct}
            defaultCurrent={(Number(skip) + Number(limit)) / Number(limit)}
            defaultPageSize={limit}
            onChange={(page) => {
              this.props.search((page - 1) * limit, "skip");
              const layoutContent = document.getElementById("userLayoutContent");
              layoutContent.scrollTop = 0;
            }}
          />
        </div>
      </Container>
    );
  }
}

ProductScreen.propTypes = {

};

ProductScreen.defaultProps = {

};

const mapDispatch = ({ cart, auth }) => ({
  addItem: cart.addItem,
  deleteItem: cart.deleteItem,
  getUserInfoAsync: auth.getUserInfoAsync,

});

const mapState = ({ auth }) => ({
  wishlist: auth.userInfo.wishlists
});
// eslint-disable-next-line
ProductScreen = withProductModal(ProductScreen)
// eslint-disable-next-line
ProductScreen = withSearch(ProductScreen)

export default connect(mapState, mapDispatch)(ProductScreen);
