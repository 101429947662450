import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import DashboardNews from "components/DashboardNews";
import DashBoardSendOrder from "components/DashBoardSendOrder";
import DashboardUnPaid from "components/DashboardUnPaid";
// import PropTypes from 'prop-types'
class Home extends Component {
  render() {
    return (
      <div>
        <Row style={{ padding: 6 }}>
          <DashboardUnPaid />
        </Row>
        {/* <Row>
          <Col xl={8} style={{ padding: 6 }}>
            <Card
              title="ยอดค้าง"
            />
          </Col>
          <Col xl={8} style={{ padding: 6 }}>
            <Card
              title="ยอดค้าง"
            />
          </Col>
          <Col xl={8} style={{ padding: 6 }}>
            <Card
              title="ยอดค้าง"
            />
          </Col>
        </Row> */}
        <Row>
          <Col xl={14} style={{ padding: 6 }}>
            <DashboardNews />
          </Col>
          <Col xl={10} style={{ padding: 6 }}>
            <DashBoardSendOrder />
          </Col>
        </Row>
      </div>
    );
  }
}

Home.propTypes = {};
Home.defaultProps = {};
const mapStateToProps = () => ({
  // eslint-disable-line
});
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
