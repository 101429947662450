/**
 *
 * AdminCategoryListScreen
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
// import PropTypes from 'prop-types'
import dataProvider from "utils/dataProvider";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";
import { Table, Button, Modal } from "antd";
import "dayjs/locale/th";

const Container = styled.div``;

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { data } = props;
  return (
    <div
      ref={ref}
      style={{
        margin: "auto",
        padding: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: 750,
      }}
    >
      <div style={{ width: "100%" }}>
        <p style={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }}>
          บริษัท บ้านยาเป็นหนึ่ง จำกัด
        </p>
        <p>ใบแจ้งจ่ายเงินเดือน/ค่าจ้าง ประจำเดือน : {dayjs(data?.payment_date).subtract(1, "month").locale("th").format("MMMM YYYY")}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <p style={{ width: "50%" }}>รหัสพนักงาน : {data?.employers?.employer_code}</p>
        <p style={{ width: "50%" }}>ชื่อ : {data?.employers?.first_name} {data?.employers?.last_name}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <p style={{ width: "50%" }}>แผนก : {data?.employers?.department}</p>
        <p style={{ width: "50%" }}>รวมวันทำงาน {data?.work_day} วัน รวม OT. {data?.ot} ชั่วโมง</p>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <p style={{ width: "50%" }}>เข้าบัญชีเลขที่ (ACCOUNT NO.) : {data?.employers?.bank_account}</p>
        <p style={{ width: "50%" }}>วันที่จ่าย: {dayjs(data?.payment_date).locale("th").format("DD MMM YYYY")}</p>
      </div>
      <div className="slip-wrapper">
        <div className="slip-box slip-1">
          <p>เงินได้</p>
          <p>Income</p>
        </div>
        <div className="slip-box">เงินเดือน/ค่าจ้าง</div>
        <div className="slip-box">ค่าตำแหน่ง</div>
        <div className="slip-box">ค่าโทรศัพท์</div>
        <div className="slip-box">ค่าตอบแทนพิเศษ</div>
        <div className="slip-box">จำนวนชั่วโมง O.T.</div>
        <div className="slip-box">ค่าล่วงเวลา O.T.</div>
        <div className="slip-box" />

        <div className="slip-box">{data.salary}</div>
        <div className="slip-box">{data.position_value}</div>
        <div className="slip-box">{data.permanent_extra_value}</div>
        <div className="slip-box">{data.temporary_extra_value}</div>
        <div className="slip-box">{data.ot}</div>
        <div className="slip-box">{data.ot_value}</div>
        <div className="slip-box" />

        <div className="slip-box">INCENTIVE</div>
        <div className="slip-box">เบี้ยขยัน</div>
        <div className="slip-box">เงินได้อื่นๆ</div>
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box">รวมเงินได้</div>

        <div className="slip-box">{data.incentive}</div>
        <div className="slip-box">{data.attendance_reward}</div>
        <div className="slip-box">{data.other_income}</div>
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box">{data.summary_income}</div>

        <div className="slip-box slip-12">
          <p>เงินหัก</p>
          <p>Deduction</p>
        </div>
        <div className="slip-box">สาย/ขาด/ลา</div>
        <div className="slip-box">ประกันสังคม</div>
        <div className="slip-box">ภาษี ณ ที่จ่าย</div>
        <div className="slip-box">ประกันพนักงาน</div>
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />

        <div className="slip-box">{data.absent_value}</div>
        <div className="slip-box">{data.social_fund}</div>
        <div className="slip-box">{data.tax}</div>
        <div className="slip-box">{data.insurance}</div>
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />

        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box">รวมเงินหัก</div>

        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box">{data.summary_deduction}</div>

        <div className="slip-box slip-23">
          <p>เงินสะสม</p>
        </div>
        <div className="slip-box">รายได้สะสม</div>
        <div className="slip-box">ประกันสังคม</div>
        <div className="slip-box">ภาษีสะสม</div>
        <div className="slip-box">ประกันพนักงาน</div>
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box">รายได้สุทธิ</div>

        <div className="slip-box">{data.YTD_income}</div>
        <div className="slip-box">{data.YTD_social_fund}</div>
        <div className="slip-box">{data.YTD_tax}</div>
        <div className="slip-box">{data.YTD_insurance}</div>
        <div className="slip-box" />
        <div className="slip-box" />
        <div className="slip-box">{data.summary_value}</div>

        <div className="slip-31" />
      </div>
      <div style={{ width: "100%", marginTop: 20 }}>
        <p style={{ margin: 0 }}>***บริษัทฯ ถือว่าข้อมูลเงินเดือนของท่านเป็นข้อมูลลับของทางบริษัทฯ </p>
        <p>ซึ่งหากท่านเปิดเผยจะก่อให้เกิดความเสียหายแก่บริษัทฯ และถือว่าท่านทำผิดวินัยร้ายแรง</p>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <p>ลงชื่อ _______________________ พนักงาน</p>
          <p>(____________________________)</p>
        </div>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <p>ลงชื่อ _______________________ นายจ้าง</p>
          <p>(____________________________)</p>
        </div>
      </div>
    </div>
  );
});

class SlipListByDateScreen extends Component {
  // eslint-disable-line
  state = {
    slips: [],
    currentSlip: {},
    isModalOpen: false,
  };

  provider = dataProvider();

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    const { date } = this.props.match.params;
    const decodeDate = window.atob(date);
    const paymentDate = new Date(decodeDate);
    const body = {
      filter: {
        where: {
          payment_date: paymentDate,
        },
        include: [{
          relation: "employers",
          scope: {
            isDeleted: true
          }
        }],
        order: "payment_date ASC",
        limit: 200,
      },
    };
    const slips = await this.provider.fetch.get("/payment_slips", body, {
      noCache: true,
    });
    this.setState({ slips });
  };

  openModal = (record) => {
    this.setState({ currentSlip: record, isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ currentSlip: {}, isModalOpen: false });
  };

  render() {
    const columns = [
      {
        title: "ชื่อ",
        dataIndex: "employers.first_name",
        key: "employers.first_name",
      },
      {
        title: "นามสกุล",
        dataIndex: "employers.last_name",
        key: "employers.last_name",
      },
      {
        title: "ดูรายละเอียด",
        key: "action",
        render: (text, record) => (
          <Button onClick={() => this.openModal(record)}>ดูข้อมูล</Button>
        ),
      },
    ];
    return (
      <Container>
        <Table
          columns={columns}
          dataSource={this.state.slips}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: 200,
            size: "small"
          }}
        />
        <Modal visible={this.state.isModalOpen} onOk={this.closeModal} width={900} destroyOnClose onCancel={this.closeModal}>
          <ComponentToPrint
            // eslint-disable-next-line
            ref={(el) => (this.printRef = el)}
            data={this.state.currentSlip}
          />
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a href="#">Print this out!</a>}
            content={() => this.printRef}
          />
        </Modal>
      </Container>
    );
  }
}

SlipListByDateScreen.propTypes = {};

SlipListByDateScreen.defaultProps = {};
export default SlipListByDateScreen;
