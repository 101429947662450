/**
*
* DashBoardSendOrder
*
*/

import React, { Component } from "react";
import dataProvider from "utils/dataProvider";
import styled from "styled-components";
import { List, Typography, Card } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { Link } from "react-router-dom";
import { TRANSPOT_NAME } from "utils/constant";
// import styles from './DashBoardSendOrder.module.css'
// import PropTypes from 'prop-types'

const Container = styled.div`

`;
const { Paragraph } = Typography;
class DashBoardSendOrder extends Component { // eslint-disable-line
  state = {
    myOrders: []
  }

  provider = dataProvider()

  componentDidMount () {
    this.getnews();
  }

  getnews = async () => {
    const body = {
      filter: {
        where: {
          status: "sended"
        }
      }
    };
    const myOrders = await this.provider.fetch.get("/myusers/me/orders", body, { noCache: true });
    this.setState({ myOrders });
  }

  render() {
    return (
      <Card
        title="ข้อมูลการส่งสินค้า"
      >
        <Container>
          <List
            pagination={{
              pageSize: 5,
              size: "small"
            }}
            itemLayout="horizontal"
            dataSource={this.state.myOrders}
            renderItem={item => (
              <Link to={`/my-order/${item.orderCode}`}>
                <List.Item>
                  <List.Item.Meta
                    title={(
                      <Paragraph ellipsis>
                        {`${dayjs(item.delivery_time).locale("th").format("DD MMM YYYY")}(${dayjs(item.delivery_time).fromNow()})`}
                      </Paragraph>
                  )}
                  />
                  <Paragraph ellipsis style={{ color: "#999" }}>
                    {item.tracking_number} ({TRANSPOT_NAME[item.transpot] || item.transpot})
                  </Paragraph>
                </List.Item>
              </Link>
            )}
          />
        </Container>
      </Card>
    );
  }
}

DashBoardSendOrder.propTypes = {

};

DashBoardSendOrder.defaultProps = {

};
export default DashBoardSendOrder;
