/**
 *
 * RegisterLayout
 *
 */

import React, { Component } from "react";
import styled from "styled-components";
import { Layout, Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import QrcodeImage from "image/qrcode.png";
import bg from "image/bg.jpeg";
import logo from "image/logo.png";

// import styles from './RegisterLayout.module.css'
// import PropTypes from 'prop-types'
const { Text, Title } = Typography;
const { Content, Footer, Header } = Layout;

const Logo = styled("div")`
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;

const CustomHeader = styled(Header)`
  height: auto !important;
  padding: 8px !important;
  @media (min-width: 767px) {
    padding: 0 50px !important;
  }
`;
class RegisterLayout extends Component {
  // eslint-disable-line
  render() {
    return (
      <Layout
        style={{
          minHeight: "100vh",
          backgroundImage: `url("${bg}")`,
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
        }}
      >
        <CustomHeader>
          <Row
            type="flex"
            justify="space-between"
            style={{ alignItems: "center" }}
          >
            <Col
              style={{ display: "flex", alignItems: "center" }}
              xs={{
                span: 12,
                order: 1,
              }}
              sm={{
                span: 12,
                order: 1,
              }}
              md={{
                span: 8,
                order: 1,
              }}
              lg={{
                span: 3,
                order: 1,
              }}
            >
              <Link to="/">
                <Logo style={{ color: "#998675" }}>
                  <img src={logo} alt="logo" width={160} />
                </Logo>
              </Link>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              xs={{
                span: 12,
                order: 2,
              }}
              sm={{
                span: 12,
                order: 2,
              }}
              md={{
                span: 8,
                order: 3,
              }}
              lg={{
                span: 12,
                order: 3,
              }}
            >
              <Link to="/login">
                <Logo>
                  <Text
                    style={{ fontSize: 20, marginRight: 30, color: "#998675" }}
                  >
                    Login
                  </Text>
                </Logo>
              </Link>
            </Col>
          </Row>
        </CustomHeader>
        <Layout style={{ background: "transparent" }}>
          <Layout style={{ background: "transparent" }}>
            <Content style={{ margin: "16px" }}>{this.props.children}</Content>
            <Footer
              style={{
                textAlign: "center",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: 30 }}>
                <Title level={3} style={{ fontWeight: "normal" }}>
                  <span style={{ color: "#9AC949" }}>เป็นเพื่อนกับเรา</span>
                  <span style={{ textShadow: "0 0 3px #777" }}>ได้ง่ายๆ</span>
                </Title>
                <Title
                  level={3}
                  style={{ textShadow: "0 0 3px #777", fontWeight: "normal" }}
                >
                  เพียงสแกน QR Code นี้
                </Title>
              </div>
              <img
                width={140}
                src={QrcodeImage}
                alt=""
                style={{ border: "3px solid #9AC949" }}
              />
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

RegisterLayout.propTypes = {};

RegisterLayout.defaultProps = {};
export default RegisterLayout;
