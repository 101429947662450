/**
*
* AccountUnPaid
*
*/

import React, { Component } from "react";
import styled from "styled-components";
import dataProvider from "utils/dataProvider";
import { Table, Button, Col, Row, DatePicker, Input } from "antd";
import dayjs from "dayjs";
// import styles from './AccountUnPaid.module.css'
// import PropTypes from 'prop-types'
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "dayjs/locale/th";

dayjs.extend(isSameOrAfter);

const { RangePicker } = DatePicker;
const { Search } = Input;

const PAGE_SIZE = 20;

const Container = styled.div`

`;
class AccountUnPaid extends Component { // eslint-disable-line
  state = {
    orders: [],
    orderCount: 0,
    startTime: null,
    endTime: null,
    search: null,
    currentPage: 1,
  }

  provider = dataProvider()

  componentDidMount() {
    this.fetchOrder();
  }

  fetchOrder = async () => {
    const body = {
      filter: {
        where: {
          payment_status: "paid",
        },
        include: ["myusers"],
        order: "payment_date DESC",
        limit: PAGE_SIZE,
        skip: (this.state.currentPage - 1) * PAGE_SIZE,
      }
    };
    if (this.state.startTime && this.state.endTime) {
      body.filter.where.payment_date = {
        between: [this.state.startTime, this.state.endTime]
      };
    }
    if (this.state.search) {
      body.filter.where.orderCode = {
        like: `%${this.state.search}%`
      };
    }
    const orders = await this.provider.fetch.get("/orders", body, { noCache: true });
    const orderCount = await this.provider.fetch.get("/orders/count", { where: body.filter.where });
    this.setState({ orders, orderCount: orderCount.count });
  }

  onChangeDateFilter = (time) => {
    const [time1, time2] = time;
    const startTime = dayjs(time1).startOf("day");
    const endTime = dayjs(time2).endOf("day");
    this.setState({ startTime: new Date(startTime), endTime: new Date(endTime) }, () => this.fetchOrder());
  }

  handleChangePage = (page) => {
    this.setState({ currentPage: page }, () => this.fetchOrder());
  }

  handleSearch = (search) => {
    this.setState({ search }, () => this.fetchOrder());
  }


  render() {
    const columns = [
      {
        title: "เลข Order",
        dataIndex: "orderCode",
        key: "orderCode",
      },
      {
        title: "ร้าน",
        dataIndex: "myusers.shop_name",
        key: "myusers.shop_name",
      },
      {
        title: "เบอร์ติดต่อ",
        dataIndex: "myusers.mobile",
        key: "myusers.mobile",
      },
      {
        title: "ยอดรวม",
        key: "amount",
        dataIndex: "amount",
        render: (text, record) => (
          <span>{record.amount} บาท</span>
        )
      },
      {
        title: "วันที่สั่งสินค้า",
        dataIndex: "createdAt",
        key: "create",
        render: (create) => (
          <span>{`${dayjs(create).locale("th").format("YYYY-MMM-DD HH:mm น.")}`}</span>
        )
      },
      {
        title: "วันที่จัดส่ง",
        dataIndex: "delivery_time",
        key: "delivery_time",
        render: (delivery_time) => (
          <span>{`${dayjs(delivery_time).locale("th").format("YYYY-MMM-DD HH:mm")}`}</span>
        )
      },
      {
        title: "เวลาตามสลิป",
        dataIndex: "payment_date",
        key: "payment_date",
        render: (payment_date) => (
          <div style={{ color: "green" }}>
            {`${dayjs(payment_date).locale("th").format("YYYY-MMM-DD HH:mm")}(${dayjs(payment_date).fromNow()})`}
          </div>
        )
      }
    ];
    return (
      <Container>
        <Row type="flex" style={{ justifyContent: "space-between", marginBottom: 30 }}>
          <Col>
            <Search
              onSearch={this.handleSearch}
            />
          </Col>
          <Col>
            <RangePicker onChange={this.onChangeDateFilter} />
            <Button onClick={() => this.fetchOrder(dayjs().startOf("day"), dayjs().endOf("day"))}>ชำระเงินวันนี้</Button>
            <Button onClick={() => this.fetchOrder()}>ดูทั้งหมด</Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={this.state.orders}
          rowKey={record => record.id}
          pagination={{
            position: "bottom",
            total: this.state.orderCount,
            pageSize: PAGE_SIZE,
            onChange: this.handleChangePage,
            current: this.state.currentPage
          }}
          scroll={{ x: 1000 }}
        />
      </Container>
    );
  }
}

AccountUnPaid.propTypes = {

};

AccountUnPaid.defaultProps = {

};
export default AccountUnPaid;
