/**
 *
 * AdminLayout
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import _get from "lodash/get";
// import styled from 'styled-components'
import { Layout, Menu, Icon } from "antd";
import { withRouter } from "react-router";

import { AdminRouter, AdminBreadcrumb, AdminMenu } from "./Helper";
// import messages from './messages';
// const Logo = styled('div')`
//   font-size: 16px;
//   color: white;
// `
const { Content, Footer, Sider } = Layout;
// const SubMenu = Menu.SubMenu;
export class AdminLayout extends React.Component {
  state = {
    collapsed: false,
  }

  handleLogout = () => {
    this.props.logoutAsync();
  }

  handleCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  render() {
    const currentPath = this.props.location.pathname;
    return (
      <Layout style={{ minHeight: "100vh" }}>
        {/* <Header>
          <Logo>Admin page</Logo>
        </Header> */}
        <Layout>
          <Sider
            breakpoint="sm"
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.handleCollapse}
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[currentPath]}
              defaultOpenKeys={[currentPath]}
            >
              {AdminMenu(this.props.match.url, this.props.userRole)}
              <Menu.Item>
                <span onClick={this.handleLogout}>
                  <Icon type="logout" />
                  <span>ออกจากระบบ</span>
                </span>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout>
            <Content style={{ margin: "0 16px", position: "relative" }}>
              {AdminBreadcrumb(this.props.match.url)}
              {AdminRouter(this.props.match.url)}
            </Content>
            <Footer style={{ textAlign: "center" }}>
              the1drugstore.com ©2019
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

AdminLayout.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({ // eslint-disable-line
  userRole: _get(state, "auth.userInfo.role"),
});

const mapDispatchToProps = ({ auth }) => ({
  logoutAsync: auth.logoutAsync
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withRouter,
)(AdminLayout);
