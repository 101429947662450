// import Home from '../Home/Home'
// import MembersPage from '../../containers/MembersPage'
// import AdminForm from '../../components/AdminForm'
// import MusicForm from '../../components/MusicForm'
// import AddMusicPage from '../../containers/AddMusicPage'
import React from "react";
import AdminDashboard from "screen/AdminPage/AdminDashboardScreen/index";
import AdminUserList from "screen/AdminPage/AdminUserListScreen/index";
import AdminUserDetail from "screen/AdminPage/AdminUserDetailScreen/index";
import AdminStaffList from "screen/AdminPage/AdminStaffListScreen";
import AdminStaffCreate from "screen/AdminPage/AdminStaffCreateScreen";
import AdminStaffEdit from "screen/AdminPage/AdminStaffEditScreen";
import AdminValidateUserList from "screen/AdminPage/AdminValidateUserListScreen";
import AdminValidateUserScreen from "screen/AdminPage/AdminValidateUserScreen";
import AdminProductListScreen from "screen/AdminPage/AdminProductListScreen";
import AdminEditProductScreen from "screen/AdminPage/AdminEditProductScreen";

import AdminCreateProductScreen from "screen/AdminPage/AdminCreateProductScreen";
import AdminCreateBulkProductScreen from "screen/AdminPage/AdminCreateBulkProductScreen";

import AdminCategoryDetailScreen from "screen/AdminPage/AdminCategoryDetailScreen";
import AdminCategoryCreateScreen from "screen/AdminPage/AdminCategoryCreateScreen";
import AdminCategoryListScreen from "screen/AdminPage/AdminCategoryListScreen";
import AdminNewsListScreen from "screen/AdminPage/AdminNewsListScreen";
import AdminCreateNewsScreen from "screen/AdminPage/AdminCreateNewsScreen";
import AdminEditNewsScreen from "screen/AdminPage/AdminEditNewsScreen";
import AdminCreatePromotionScreen from "screen/AdminPage/AdminCreatePromotionScreen";
import AdminEditPromotionScreen from "screen/AdminPage/AdminEditPromotionScreen";
import AdminInComeOrderScreen from "screen/AdminPage/AdminInComeOrderScreen";
import AdminIncomeOrderDetail from "screen/AdminPage/AdminIncomeOrderDetailScreen";
import AdminConfirmOrderListScreen from "screen/AdminPage/AdminConfirmOrderListScreen";
import AdminConfirmOrderDetailScreen from "screen/AdminPage/AdminConfirmOrderDetailScreen";
import AdminPreDeliveryOrderScreen from "screen/AdminPage/AdminPreDeliveryOrderScreen";
import AdminAccountantScreen from "screen/AdminPage/AdminAccountantScreen";
import AdminOrderDetailScreen from "screen/AdminPage/AdminOrderDetailScreen";
import AdminConfirmPaymentScreen from "screen/AdminPage/AdminConfirmPaymentScreen";
import AdminUserOrderScreen from "screen/AdminPage/AdminUserOrderScreen";
import AdminUnpaidpaymentScreen from "screen/AdminPage/AdminUnpaidpaymentScreen";
import AdminBannerListScreen from "screen/AdminPage/AdminBannerListScreen";
import AdminBannerCreateScreen from "screen/AdminPage/AdminBannerCreateScreen";
import AdminBannerEditScreen from "screen/AdminPage/AdminBannerEditScreen";

const defaultAdminRole = ["admin", "accountant", "cashier", "delivery_man"];

export const routes = [
  {
    path: "dashboard",
    title: "Dashboard",
    menu: "Dashboard",
    icon: "dashboard",
    component: AdminDashboard,
    role: defaultAdminRole,
  },
  {
    path: "banners",
    title: "banner",
    menu: "banner",
    icon: "file-jpg",
    role: ["admin"],
    children: [
      {
        path: "",
        menu: "banner",
        icon: "bars",
        component: AdminBannerListScreen,
        role: ["admin"],
      },
      {
        path: "create",
        title: "เพิ่ม banner",
        menu: "เพิ่ม banner",
        icon: "plus",
        component: AdminBannerCreateScreen,
        role: ["admin"],
      },
      {
        path: ":id",
        title: "banner",
        component: AdminBannerEditScreen,
        role: ["admin"],
      },
    ],
  },
  {
    path: "users",
    title: "ลูกค้า",
    menu: "ลูกค้า",
    icon: "usergroup-add",
    role: ["admin"],
    children: [
      {
        path: "",
        menu: "ลูกค้าในระบบ",
        icon: "bars",
        component: AdminUserList,
        role: ["admin"],
      },
      {
        path: "validate",
        menu: "ลูกค้าที่รอการตรวจสอบ",
        title: "ลูกค้าที่รอการตรวจสอบ",
        icon: "plus",
        component: AdminValidateUserList,
        role: ["admin"],
      },
      {
        path: ":id/orders",
        component: AdminUserOrderScreen,
        role: ["admin"],
      },
      {
        path: ":id",
        title: "ข้อมูลยูสเซอร์",
        component: AdminUserDetail,
        role: ["admin"],
      },
      {
        path: "validate/:id",
        title: "ข้อมูลยูสเซอร์",
        component: AdminValidateUserScreen,
        role: ["admin"],
      },
    ],
  },
  {
    path: "staffs",
    title: "พนักงาน",
    menu: "พนักงาน",
    icon: "usergroup-add",
    role: ["admin"],
    children: [
      {
        path: "",
        menu: "พนักงานทั้งหมด",
        icon: "bars",
        component: AdminStaffList,
        role: ["admin"],
      },
      {
        path: "create",
        menu: "สร้างไอดีพนักงาน",
        title: "สร้างไอดีพนักงาน",
        icon: "plus",
        component: AdminStaffCreate,
        role: ["admin"],
      },
      {
        path: ":id",
        title: "ข้อมูลพนักงาน",
        component: AdminStaffEdit,
        role: ["admin"],
      },
    ],
  },
  {
    path: "orders",
    title: "order",
    menu: "ออเดอร์",
    icon: "dashboard",
    role: defaultAdminRole,
    children: [
      {
        path: "",
        component: (props) => (
          <div>{props.history.push("/admin/orders/incoming")}</div>
        ),
        role: ["admin"],
      },
      {
        path: "incoming",
        menu: "รอการตรวจสอบ",
        title: "รอการตรวจสอบ",
        icon: "plus",
        component: AdminInComeOrderScreen,
        role: ["admin", "cashier"],
      },
      {
        path: "incoming/:id", // view and edit user's order and print po image
        component: AdminIncomeOrderDetail,
        role: ["admin", "cashier"],
      },
      {
        path: "confirm",
        menu: "รอการคิดเงิน",
        title: "รอการคิดเงิน",
        icon: "plus",
        component: AdminConfirmOrderListScreen,
        role: ["admin", "cashier"],
      },
      {
        path: "confirm/:id", // view and upload bill image
        component: AdminConfirmOrderDetailScreen,
        role: ["admin", "cashier"],
      },
      {
        path: "pre-delivery", // list checkbox change status of order to sended
        menu: "รอการจัดส่ง",
        title: "รอการจัดส่ง",
        icon: "plus",
        component: AdminPreDeliveryOrderScreen,
        role: ["admin", "delivery_man"],
      },
      {
        path: "payment-status",
        menu: "บัญชี",
        title: "บัญชี",
        icon: "plus",
        component: AdminAccountantScreen,
        role: ["admin", "accountant"],
      },
      {
        path: "payment-status/unpaid/:id",
        component: AdminUnpaidpaymentScreen,
        role: ["admin", "accountant"],
      },
      {
        path: "payment-status/:id",
        component: AdminConfirmPaymentScreen,
        role: ["admin", "accountant"],
      },
      {
        path: ":id", // view sended order (read-only)
        component: AdminOrderDetailScreen,
        role: ["admin", "cashier"],
      },
    ],
  },
  {
    path: "products",
    title: "สินค้า",
    menu: "สินค้า",
    icon: "dashboard",
    role: ["admin"],
    children: [
      {
        path: "",
        title: "รายการสินค้า",
        menu: "รายการสินค้า",
        icon: "dashboard",
        component: AdminProductListScreen,
        role: ["admin"],
      },
      {
        path: "create",
        title: "เพิ่มสินค้า",
        menu: "เพิ่มสินค้า",
        icon: "plus",
        component: AdminCreateProductScreen,
        role: ["admin"],
      },
      {
        path: "create-excel",
        title: "เพิ่มสินค้า เอ็กเซล",
        menu: "เพิ่มสินค้า เอ็กเซล",
        icon: "plus",
        component: AdminCreateBulkProductScreen,
        role: ["admin"],
      },
      {
        path: ":id",
        icon: "plus",
        component: AdminEditProductScreen,
        role: ["admin"],
      },
      {
        path: ":id/promotion/create",
        icon: "plus",
        component: AdminCreatePromotionScreen,
        role: ["admin"],
      },
      {
        path: ":id/promotion/:promotionId",
        icon: "plus",
        component: AdminEditPromotionScreen,
        role: ["admin"],
      },
    ],
  },
  {
    path: "categories",
    title: "หมวดหมู่",
    menu: "หมวดหมู่",
    icon: "dashboard",
    role: ["admin"],
    children: [
      {
        path: "",
        title: "รายการหมวดหมู่",
        menu: "รายการหมวดหมู่",
        icon: "dashboard",
        component: AdminCategoryListScreen,
        role: ["admin"],
      },
      {
        path: "create",
        title: "เพิ่มหมวดหมู่",
        menu: "เพิ่มหมวดหมู่",
        icon: "plus",
        component: AdminCategoryCreateScreen,
        role: ["admin"],
      },
      {
        path: ":id",
        icon: "plus",
        component: AdminCategoryDetailScreen,
        role: ["admin"],
      },
    ],
  },
  {
    path: "news",
    title: "ข่าวสาร",
    menu: "ข่าวสาร",
    icon: "dashboard",
    role: ["admin"],
    children: [
      {
        path: "",
        title: "ข่าวสารทั้งหมด",
        menu: "ข่าวสารทั้งหมด",
        icon: "dashboard",
        component: AdminNewsListScreen,
        role: ["admin"],
      },
      {
        path: "create",
        title: "เขียนข่าวใหม่",
        menu: "เขียนข่าวใหม่",
        icon: "plus",
        component: AdminCreateNewsScreen,
        role: ["admin"],
      },
      {
        path: ":id",
        icon: "plus",
        component: AdminEditNewsScreen,
        role: ["admin"],
      },
    ],
  },
  {
    path: "gifts",
    title: "ของรางวัล",
    menu: "ของรางวัล",
    icon: "dashboard",
    role: ["admin"],
    children: [
      {
        path: "",
        title: "ของรางวัลทั้งหมด",
        menu: "ของรางวัลทั้งหมด",
        icon: "dashboard",
        component: AdminDashboard,
        role: ["admin"],
      },
      {
        path: "create",
        title: "เพิ่มของรางวัล",
        menu: "เพิ่มของรางวัล",
        icon: "plus",
        component: AdminDashboard,
        role: ["admin"],
      },
      {
        path: ":id",
        icon: "plus",
        component: AdminDashboard,
        role: ["admin"],
      },
    ],
  },
];
