/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Input, Select, Row, Col } from "antd";
import dataProvider from "utils/dataProvider";
// import useSearch from '../../hook/useSearch'

const { Search } = Input;
const { Option } = Select;

const NavSearch = ({ search, name, category, factory, setValue, status, isShowStatusFilter = false }) => {
  const provider = dataProvider();
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    const categoriesList = await provider.fetch.get("/categories", null, { noCache: true });
    setCategories(categoriesList);
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <Row type="flex" justify="space-between" style={{ padding: "16px 0" }}>
      <Col xs={24} md={10}>
        <div style={{ padding: "8px" }}>
          <Search
            placeholder="ค้นชื่อสินค้า หรือชื่อตัวยาที่นี่"
            onSearch={value => search(value, "name")}
            enterButton
            value={name}
            onChange={(e) => setValue(e.target.value, "name")}
          />
        </div>
      </Col>
      {/* <Col xs={24} md={6}>
        <div style={{ padding: '8px' }}>
          <Select
            placeholder="หมวดหมู่ยา"
            style={{ width: '100%' }}
            onChange={value => search(value, 'categories')}
            value={category}
          >
            <Option value="all">ทั้งหมด</Option>
            {
              categories.map(item => (<Option value={item.id} key={item.id}>{item.name}</Option>))
            }

          </Select>
        </div>
      </Col> */}
      <Col xs={24} md={6}>
        <div style={{ padding: "8px" }}>
          {/* <span style={{ marginRight: '10px' }}>ใน</span> */}
          <Search
            placeholder="ชื่อบริษัท"
            onSearch={value => search(value, "factory")}
            enterButton
            value={factory}
            onChange={(e) => setValue(e.target.value, "factory")}
            // style={{ width: '100%' }}
          />
        </div>
      </Col>
      {
        isShowStatusFilter && (
        <Col xs={24} md={6}>
          <div style={{ padding: "8px" }}>
            {/* <span style={{ marginRight: '10px' }}>ใน</span> */}
            <Select
              placeholder="สถานะสินค้า"
              style={{ width: "100%" }}
              onChange={value => search(value, "status")}
              value={status}
            >
              <Option value="all">ทั้งหมด</Option>
              <Option value>เปิดใช้</Option>
              <Option value={false}>ปิดชั่วคราว</Option>
            </Select>
          </div>
        </Col>
        )
      }

    </Row>
  );
};

export default NavSearch;
